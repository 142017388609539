import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

// Import Action

import {
  showNotificatonCount,
  isNotificationModalOpen,
  onSidebarMenuSelected,
  onSearchSubMenuSelected,
  onEnrichSubMenuSelected,
} from '@sidebarActions';
import {isEmpty, classNames} from '@commonFunction';
// Import pages
import DashboardMain from '@maindashBoard';
import MainSearch from '@mainSearch';
import Mycontacts from '@myContacts';
import MyListHome from '@mainMyList';
import EnrichHome from '@mainEnrich';

import toast, {Toaster} from 'react-hot-toast';
import '../sidebar.css';
import IconCompanySearch from './icons/IconCompanySearch';
import IconPeopleSearch from './icons/IconPeopleSearch';
import IconFav from './icons/IconFav';
import {closeList} from '../../mylist/actions';
import RevealedContactsHome from '../../revealedcontacts';
import IconSavedFilter from './icons/IconSavedFilter';
import {toggleFiltersSidePopup} from '../../search/actions';
import IconMyList from './icons/IconMyList';
import IconReveal from './icons/IconReveal';
import IconSearch from './icons/IconSearch';
// import Subsidebar from './subsidebar';

const searchSubMenus = [
  {
    name: 'People Search',
    href: 'people',
    icon: 'user',
    current: true,
    position: 1,
  },
  {
    name: 'Company Search',
    href: 'company',
    icon: 'briefcase',
    current: false,
    position: 2,
  },
  {
    name: 'Search by Linkedin',
    href: 'linkedinsearch',
    icon: 'search',
    current: false,
    position: 3,
  },
  {
    name: 'Revealed Contacts',
    href: 'revealedcontacts',
    icon: 'mycontacts',
    current: false,
    position: 4,
  },
  {
    name: 'My Lists',
    href: 'mylists',
    icon: 'mylists',
    current: false,
    position: 5,
  },
  {
    name: 'Favourites',
    href: 'favs',
    icon: 'favorite',
    current: false,
    position: 6,
  },
];

const enrichSubMenus = [
  {
    name: 'CSV Enrichment',
    href: 'csvenrichment',
    icon: '',
    current: true,
    position: 1,
  },
  {
    name: 'API Enrichment',
    href: 'apienrichment',
    icon: '',
    current: false,
    position: 2,
  },
];

class SideBar extends Component {
  constructor(props) {
    super(props);
  }

  // Component Did mount
  componentDidMount() {
    let {session} = this.props;
    let {pathname} = this.props.router.location;

    // console.log('SIDEBAR PATHNAME => ', pathname);
    const paths = pathname.split('/').slice(1);
    pathname = paths[0].replaceAll('/', '');
    // pathname = pathname.replaceAll('/', '');
    this.props.onSidebarMenuSelected(pathname);

    //splitting pathname to updated the selected menu
    // var parts = this.props.router.location.pathname;
    // if (parts.length > 1) {
    //   var answer = parts[0];
    //   // var singleNavigationRow = _.find(this.props.navigation, {href: answer});
    //   // this.props.onSidebarMenuSelected(singleNavigationRow.href);
    //   // this.props.onSearchSubMenuSelected('company');
    //   console.log('ANSWER => ', answer);
    // } else {
    //   var main = parts[0];
    //   var sub = parts[0];

    //   console.log('ANSWER => ', main, ' SUB => ', sub);
    //   // var singleNavigationRow = _.find(this.props.navigation, {href: main});
    //   // this.props.onSidebarMenuSelected(singleNavigationRow.href);
    //   // this.props.onSearchSubMenuSelected(sub);
    // }
  }

  // Menu Select (Route change)
  onMenuSelect = item => {
    this.props.onSidebarMenuSelected(item.href);
    // if (item.href === 'revealedcontacts') {
    //   this.props.onSearchSubMenuSelected('people');
    // } else {
    //   this.props.onSearchSubMenuSelected('company');
    // }

    this.props.router.navigate(item.href);

    // if (item.href === 'search')
    //   this.props.router.navigate('/dashboard/main/' + item.href + '/company');
    // else this.props.router.navigate('/dashboard/main/' + item.href);
  };

  onSubMenuSelect = item => {
    this.props.onSearchSubMenuSelected(item.href);
    this.props.closeList();
    // this.props.router.navigate('/dashboard/main/search/' + item.href);
  };

  onEnrichSubMenuSelect = item => {
    this.props.onEnrichSubMenuSelected(item.href);
  };

  renderIcon = ({icon, selected}) => {
    if (icon === 'briefcase') {
      return <IconCompanySearch stroke={selected ? '#5E2CED' : '#A3ABBC'} />;
    } else if (icon === 'user') {
      return <IconPeopleSearch stroke={selected ? '#5E2CED' : '#A3ABBC'} />;
    } else if (icon === 'mylists') {
      return <IconMyList stroke={selected ? '#5E2CED' : '#A3ABBC'} />;
    } else if (icon === 'mycontacts') {
      return <IconReveal stroke={selected ? '#5E2CED' : '#A3ABBC'} />;
    } else if (icon === 'search') {
      return <IconSearch stroke={selected ? '#5E2CED' : '#A3ABBC'} />;
    }

    return <IconFav stroke={selected ? '#5E2CED' : '#A3ABBC'} />;
  };

  render() {
    return (
      <div className="absolute top-[64px] w-full h-[40px]">
        {this.props.sidebarSelectedMenu === 'search' ? (
          <div className="shadow border-b border-gray-200 pl-12 flex justify-between">
            <nav
              className="-mb-px flex space-x-5 min-h-[40px] max-h-[40px]"
              aria-label="Tabs">
              {searchSubMenus.map(tab => (
                <div
                  key={tab.name}
                  className={classNames(
                    this.props.searchSelectedSubMenu === tab.href
                      ? 'border-primary text-primary'
                      : 'border-transparent text-slate-400 hover:text-primary',
                    'group inline-flex items-center border-b-2 py-4 px-1 text-[14px] cursor-pointer',
                  )}
                  onClick={() => {
                    this.onSubMenuSelect(tab);
                  }}>
                  <div className="mr-1">
                    {this.renderIcon({
                      icon: tab.icon,
                      selected: this.props.searchSelectedSubMenu === tab.href,
                    })}
                  </div>
                  <span>{tab.name}</span>
                </div>
              ))}
            </nav>

            {this.props.availableFilters.length > 0 ? (
              <div
                className="text-gray-500 text-[14px] flex items-center hover:cursor-pointer mr-6"
                onClick={() => {
                  this.props.toggleFiltersSidePopup(true);
                }}>
                <span>
                  <IconSavedFilter />
                </span>
                &nbsp;Saved Filters
              </div>
            ) : null}
          </div>
        ) : null}
        {this.props.sidebarSelectedMenu === 'enrich' ? (
          <div className="shadow border-b border-gray-200 pl-12">
            <nav
              className="-mb-px flex space-x-5 min-h-[40px] max-h-[40px]"
              aria-label="Tabs">
              {enrichSubMenus.map(tab => (
                <div
                  key={tab.name}
                  className={classNames(
                    this.props.enrichSelectedSubMenu === tab.href
                      ? 'border-primary text-primary'
                      : 'border-transparent text-slate-400 hover:text-primary',
                    'group inline-flex items-center border-b-2 py-4 px-1 text-[14px] cursor-pointer',
                  )}
                  onClick={() => {
                    this.onEnrichSubMenuSelect(tab);
                  }}>
                  <span>{tab.name}</span>
                </div>
              ))}
            </nav>
          </div>
        ) : null}

        <Toaster />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {session, user} = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    sidebarSelectedMenu:
      state.sidebarSelectedMenu === ''
        ? 'dashboard'
        : state.sidebarSelectedMenu,
    searchSelectedSubMenu: state.searchSelectedSubMenu,
    enrichSelectedSubMenu: state.enrichSelectedSubMenu,
    language: state.language,
    navigation: state.sideBarMenus,
    deviceSize: state.deviceSize,
    availableFilters: state.availableFilters,
  };
}

export default connect(mapStateToProps, {
  onSidebarMenuSelected,
  showNotificatonCount,
  isNotificationModalOpen,
  onSearchSubMenuSelected,
  onEnrichSubMenuSelected,
  closeList,
  toggleFiltersSidePopup,
})(SideBar);
