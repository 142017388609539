import {connect} from 'react-redux';
import {PlusIcon, CheckIcon} from '@heroicons/react/solid';

function SubAddToList(props) {
  return (
    <div class="flex-col w-full max-h-40 overflow-auto scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent scrollbar-thumb-rounded-full">
      {props.availableLists.map(list => {
        if (list.id === 'create') {
          return (
            <div
              onClick={() => {
                props.onCreateNewList();
              }}
              key={list.id}
              className="mx-1 mt-1 rounded-sm py-1 bg-slate-100 hover:cursor-pointer justify-center items-center flex">
              <span className="text-xs text-primary">{list.listname}</span>
              <span>
                <PlusIcon className="h-2 w-2 text-primary" aria-hidden="true" />
              </span>
            </div>
          );
        }
        return (
          <div
            onClick={() => {
              props.onChangeList(list);
            }}
            key={list.id}
            className="group mx-1 mt-1 rounded-sm py-1 hover:bg-slate-100 items-center justify-center hover:cursor-pointer flex font-sm w-36 relative">
            <span className="text-xs truncate w-[120px] text-center">
              {list.listname}
            </span>
            {props.addedListId === list.id ? (
              <span className="absolute right-3 w-2">
                <CheckIcon
                  className="h-3 w-3 text-primary font-semibold"
                  aria-hidden="true"
                />
              </span>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    availableLists: [
      {listname: 'Create a List', id: 'create'},
      ...state.availableLists,
    ],
  };
}

export default connect(mapStateToProps, null)(SubAddToList);
