import React, {Component, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import SocialLinks from '../../results/cells/components/sociallinks';
import {arrCompanyInfo} from '../../constants';
import {classNames} from '@commonFunction';
import {
  convertToInternationalCurrencySystem,
  toTitleCase,
  ENTERPRISE_MONTHLY,
  ENTERPRISE_YEARLY,
} from '@common/constants';
import {
  FILTER_TYPE_COMPANY,
  toogleCompanyFav,
  updateCompany,
  updateFavCompany,
  addCompanyTolist,
} from '../../actions';
import AddToList from '../cells/components/addtolist';
import IconUpgradeWhite from './icons/upgrade';
import {encode} from 'js-base64';

function SubComponent(props) {
  const {icon, type} = props;

  let title = props.title;

  if (type === 'revenue') {
    title = convertToInternationalCurrencySystem(title);
  } else if (type === 'industry') {
    title = toTitleCase(title);
  } else if (type === 'headcount') {
    title =
      title === '-1' ? 'Self Employed' : title === '0' ? 'Unknown' : title;
  }

  const renderIcon = () => {
    if (icon) {
      return (
        <div class="relative flex">
          <img className="mr-2 text-gray-500" src={icon} width={18} />
          {/* <div className="text-gray-500">{toTitleCase(type)}</div> */}
        </div>
      );
    } else if (type === 'naicscodes') {
      return <span className="text-xs mr-2 text-gray-900 h-4">NAICS</span>;
    } else if (type === 'siccodes') {
      return <span className="text-xs mr-2 text-gray-900 h-4">SIC</span>;
    }
  };

  return (
    <div class="justify-center flex flex-row gap-2 items-center">
      {renderIcon()}
      <div className={classNames('pr-2 text-xs text-slate-500')}>{title}</div>
      <hr />
    </div>
  );
}

function OverView(props) {
  const {company, credits, onOpenCreateList, filterType} = props;
  const [readMore, setReadMore] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const navigate = useNavigate();

  const {linkedin, twitter, facebook} = company;

  const {isfav, addedtolist, listid} = company;

  let arrComponents = arrCompanyInfo;
  return (
    <>
      <div className="my-2 flex flex-row mr-4 justify-between border-b items-center">
        <div className="relative flex px-4 pt-4 justify-start">
          {company.logo ? (
            <img
              className="mr-6 h-8 w-8"
              src={company.logo}
              onError={e => {
                e.target.src = require('../../../../../assets/images/empty_company.png');
              }}
            />
          ) : null}
          <div className="flex flex-row space-x-4">
            <div className="flex flex-col">
              <div className="text-sm font-medium truncate">
                {company.title}
              </div>
              <div className="text-xs text-gray-400">{company.website}</div>
            </div>
            {!linkedin && !twitter && !facebook ? null : (
              <div className="mt-2 group hover:cursor-pointer">
                <SocialLinks
                  linkedin={linkedin}
                  twitter={twitter}
                  facebook={facebook}
                />
              </div>
            )}
          </div>
        </div>
        <div className="justify-end">
          <div className="flex flex-row gap-2 relative">
            {filterType === FILTER_TYPE_COMPANY ? (
              <button
                type="button"
                className={classNames(
                  'btn-primary-white items-center',
                  isfav ? '!border-primary !text-primary' : 'border-gray-400',
                )}
                style={{width: 'auto', height: 40}}
                onClick={() => {
                  const newcompany = {...props.company, isfav: !isfav};
                  const index = props.filteredCompany.data.findIndex(
                    t => t.companyid === props.company.companyid,
                  );
                  props.filteredCompany.data[index] = newcompany;
                  props.updateCompany(props.filteredCompany);

                  const params = {
                    action: isfav ? 'removecompanyfav' : 'addcompanyfav',
                    companyid: newcompany.companyid,
                  };
                  props.toogleCompanyFav(props.session, params, res => {
                    console.log('Fav => ', res);
                  });
                }}>
                {isfav ? 'Added to Favourites' : 'Add to Favourites'}
              </button>
            ) : null}
            {props.mylist ? (
              <button
                type="button"
                className={classNames(
                  'btn-primary-white items-center',
                  addedtolist
                    ? '!border-primary !text-primary'
                    : 'border-gray-400',
                )}
                style={{width: 'auto', height: 40}}
                onClick={e => {
                  props.onDeleteFromList(props.company);
                }}>
                {`Remove from ${props.selectedList}`}
              </button>
            ) : (
              <button
                type="button"
                className={classNames(
                  'btn-primary-white items-center',
                  addedtolist
                    ? '!border-primary !text-primary'
                    : 'border-gray-400',
                )}
                style={{width: 'auto', height: 40}}
                onClick={e => {
                  setShowInfo(!showInfo);
                }}>
                {addedtolist
                  ? `Added to ${props.selectedList}`
                  : 'Add to My List'}
                {showInfo ? (
                  <div className="absolute top-10 z-10">
                    <AddToList
                      listtype="company"
                      addedListId={listid}
                      onCreateNewList={() => {
                        setShowInfo(false);
                        onOpenCreateList(props.company);
                      }}
                      onChangeList={list => {
                        props.addCompanyTolist(
                          props.session,
                          {
                            listid: list.id,
                            companyids: `${props.company.companyid}`,
                          },
                          _ => {},
                        );

                        const newcompany = {
                          ...props.company,
                          addedtolist: true,
                          listid: list.id,
                        };
                        const index = props.filteredCompany.data.findIndex(
                          t => t.companyid === props.company.companyid,
                        );
                        props.filteredCompany.data[index] = newcompany;
                        if (filterType === FILTER_TYPE_COMPANY) {
                          props.updateCompany(props.filteredCompany);
                        } else {
                          props.updateFavCompany(props.filteredCompany);
                        }
                        setShowInfo(false);
                      }}
                    />
                  </div>
                ) : null}
              </button>
            )}

            {/* {credits.role === 'client' &&
            (credits.planid === ENTERPRISE_YEARLY ||
              credits.planid === ENTERPRISE_MONTHLY) ? ( */}
            <button
              type="button"
              className="btn-primary items-center"
              style={{width: 'auto', height: 40}}
              onClick={e => {
                const filter = {
                  title: company.title,
                  value: company.title,
                  subtitle: company.website,
                };
                const encoded1 = encode(JSON.stringify(filter)).toString();
                const final = encode(encoded1).toString();
                window.open(`/search/view-${final}`, '_blank');
              }}>
              {'View Employees'}
              <div className="absolute top-1 right-1">
                <IconUpgradeWhite />
              </div>
            </button>
            {/* ) : null} */}
          </div>
        </div>
      </div>
      <div className="flex mx-4 my-8 flex-row justify-between">
        <div className="basis-8/12">
          <div className="text-sm text-gray-800">About</div>
          <div
            className={classNames(
              'text-gray-400 text-xs font-normal w-full pr-4 h-full max-h-40 h-auto scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent scrollbar-thumb-rounded-full',
              readMore
                ? 'line-clamp-none overflow-y-scroll'
                : 'line-clamp-10 overflow-y-hidden',
            )}>
            {company.desc}
          </div>
          <span
            onClick={() => {
              setReadMore(!readMore);
            }}
            className="flex text-xs text-primary justify-end mr-4 hover:cursor-pointer">
            {readMore ? 'Read Less' : 'Read More'}
          </span>
        </div>
        <div
          className={classNames(
            'flex flex-col justify-start items-start gap-1 pt-2 mx-4 basis-auto',
          )}>
          {arrComponents.map(info => {
            if (company[info.type]) {
              return (
                <SubComponent
                  title={company[info.type]}
                  icon={info.icon}
                  key={info.key}
                  {...info}
                />
              );
            }
          })}
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  var {session, user, savedFilters, appuserData} = state;
  let selectedList = 'My List';
  if (state.availableCompanyLists && state.availableCompanyLists.length > 0) {
    const filtered = state.availableCompanyLists.filter(
      obj => obj.id === ownProps.company.listid,
    );
    if (filtered.length > 0) {
      selectedList = filtered[0].listname;
    }
  }
  return {
    session,
    user,
    selectedList,
    filteredCompany:
      ownProps.filterType === FILTER_TYPE_COMPANY
        ? state.filteredCompany
        : state.filteredFavCompany,
  };
}

export default connect(mapStateToProps, {
  addCompanyTolist,
  toogleCompanyFav,
  updateCompany,
  updateFavCompany,
})(OverView);
