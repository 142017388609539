import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {connect} from 'react-redux';
import {classNames} from '@commonFunction';
import PeopleCell from './cells/PeopleCell';
import IconArrowLeft from './cells/icons/arrowleft';
import IconArrowRight from './cells/icons/arrowright';
import IconCardView from './cells/icons/cardview';
import IconListView from './cells/icons/listview';
import IconExport from './cells/icons/export';
import IconGroupAddToList from './cells/icons/groupaddtolist';
import IconGroupEnrich from './cells/icons/groupenrich';
import IconGroupFav from './cells/icons/groupfav';
import {people} from '../constants';
import ListTable from './ListTable';
import CardTable from './CardTable';
import {Fragment} from 'react';
import {ChevronDownIcon} from '@heroicons/react/solid';
import {FilterIcon} from '@heroicons/react/solid';
import AddToList from './cells/components/addtolist';
import CreateListPopup from '../components/createlistpopup';
import PaginationBar from './cells/components/paginationbar';
import {
  FILTER_TYPE_FAV_PEOPLE,
  filterPeople,
  tooglePeopleFav,
  updateFavPeople,
} from '../actions';
import SkeletonLoader from '../../../../common/skeletonloader';
import NoResult from '../../../../common/noresult';
import LocaleStrings from '../../../languages';

const items = [
  {name: 'List 1', href: '#'},
  {name: 'List 2', href: '#'},
];

const maxRowPerPage = 25;

function PeopleFavsTable(props) {
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPersons, setSelectedPersons] = useState([]);

  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  const [cardView, setCardView] = useState(false);

  const [showList, setShowList] = useState(false);
  const [openCreateListPopup, setOpenCreateListPopup] = useState(false);

  const [curPage, setCurPage] = useState(1);
  const [rowsPerPage] = useState(maxRowPerPage);

  const [isLoading, setIsLoading] = useState(false);
  const [isPaginating, setIsPaginating] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const [isRefreshing, setIsRefreshing] = useState(false);

  const [totalCount, setTotalCount] = useState(props.totalCount);

  const applyFilter = resetPage => {
    const page = resetPage ? 1 : curPage;
    props.filterPeople(
      props.session,
      page,
      rowsPerPage,
      sortBy,
      sortDirection,
      props.savedFilters,
      FILTER_TYPE_FAV_PEOPLE,
      props.appuserData,
      resp => {
        if (resp.success === 1) {
        }
        if (resetPage) {
          setCurPage(1);
          setTimeout(() => {
            setIsLoading(false);
            setIsPaginating(false);
            setFirstLoad(false);
            setIsRefreshing(false);
          }, 200);
        } else {
          setIsLoading(false);
          setIsPaginating(false);
          setFirstLoad(false);
          setIsRefreshing(false);
        }
      },
    );
  };

  useEffect(() => {
    console.log('Updating props.totalcount => ', props.totalcount);
    if (props.totalCount !== totalCount) {
      console.log('Updating count => ', totalCount);

      setTotalCount(props.totalCount);
    }
  }, [props.totalcount]);

  useEffect(() => {
    if (isPaginating) {
      applyFilter(false);
    }
  }, [curPage]);

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      setFirstLoad(true);
      applyFilter(true);
    }
  }, [props.savedFilters]);

  useEffect(() => {
    if (isRefreshing) {
      applyFilter();
    }
  }, [isRefreshing]);

  useLayoutEffect(() => {
    if (props.filteredPeople && props.filteredPeople.length > 0) {
      const isIndeterminate =
        selectedPersons.length > 0 &&
        selectedPersons.length < props.filteredPeople.length;
      setChecked(selectedPersons.length === props.filteredPeople.length);
      setIndeterminate(isIndeterminate);
      if (checkbox.current) checkbox.current.indeterminate = isIndeterminate;
    } else {
      setChecked(false);
    }
  }, [selectedPersons, props.filteredPeople]);

  function toggleAll() {
    if (props.showTechnologyBlock) return;
    setSelectedPersons(checked || indeterminate ? [] : props.filteredPeople);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const renderResultText = () => {
    const startIndex = (curPage - 1) * maxRowPerPage;
    let endIndex = (curPage - 1) * maxRowPerPage + maxRowPerPage;

    if (props.totalcount < maxRowPerPage) {
      endIndex = props.totalcount;
    }

    if (endIndex > props.totalcount) {
      endIndex = props.totalcount;
    }

    if (props.totalcount > 0 && !props.showTechnologyBlock)
      return `${startIndex + 1} - ${endIndex} of ${props.totalcount} contacts`;

    return '0 contacts';
  };

  const removeFav = newpeople => {
    const params = {
      action: 'removefav',
      peopleid: newpeople.peopleid,
    };
    props.tooglePeopleFav(props.session, params, res => {
      setIsRefreshing(true);
    });

    const filteredUsers = props.orignialFavData.data.filter(
      people => people.peopleid !== newpeople.peopleid,
    );

    const updatedFavData = {
      ...props.orignialFavData,
      data: filteredUsers,
      totalcount: filteredUsers.length,
    };

    props.updateFavPeople(updatedFavData);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {props.totalcount > 0 && !isLoading ? (
        <div className="mt-8 flow-root">
          <div className="flex flex-grow">
            <div className="flex -mx-4 -my-2 mb-4 relative items-center grow shrink basis-0 self-stretch">
              {/* <div className="group">
                <input
                  type="checkbox"
                  className="rounded border-gray-500 text-primary group-hover:cursor-pointer"
                  ref={checkbox}
                  checked={checked}
                  onChange={toggleAll}
                />
              </div> */}
              <div className="ml-2 mt-1 text-sm font-semibold">
                {renderResultText()}
              </div>
              <div
                className={classNames(
                  'transition ease-in duration-300 absolute left-5 -top-3 flex h-12 items-center space-x-3 bg-white sm:left-5',
                  selectedPersons.length > 0 ? 'opacity-100' : 'opacity-0',
                )}>
                <div className="ml-2 mt-1 text-sm font-semibold">
                  {`${selectedPersons.length} selected`}
                </div>
                {/* <button
                  type="button"
                  className="inline-flex items-center rounded-lg bg-primary px-3.5 py-2 text-xs text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:opacity-80 hover:cursor-pointer">
                  {LocaleStrings.revealSelected}
                </button> */}

                {/* <div className="z-10">
                  <span className="isolate inline-flex rounded-md shadow-sm">
                   
                    <div className="relative">
                      
                    </div>
                    <button
                      type="button"
                      className="relative -ml-px rounded-l-md inline-flex items-center bg-white px-3 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
                      <IconGroupEnrich />
                      &nbsp;Enrich&nbsp;
                      <ChevronDownIcon
                        className="h-6 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                    </button>
                    <button
                      type="button"
                      className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
                      <IconExport />
                    </button>
                  </span>
                </div> */}
              </div>
            </div>
            <div className="flex -mx-4 -my-5 mb-2 relative gap-6 items-center">
              <div
                className={classNames(
                  'w-22 h-8 rounded-lg shadow border border-gray-300 justify-start items-start inline-flex overflow-hidden',
                  props.showTechnologyBlock ? 'opacity-50' : 'opacity-100',
                )}>
                <div
                  class="px-3 py-2 bg-white border-r border-gray-300 justify-center items-center gap-2 flex hover:bg-gray-100 hover:cursor-pointer"
                  onClick={() => {
                    if (curPage > 1 && !props.showTechnologyBlock) {
                      setCurPage(curPage - 1);
                      setIsPaginating(true);
                    }
                  }}>
                  <div class="w-5 h-4 relative rounded-lg">
                    <IconArrowLeft />
                  </div>
                </div>
                <div
                  class="px-3 py-2 bg-white justify-center items-center gap-2 flex hover:bg-gray-100 hover:cursor-pointer"
                  onClick={() => {
                    if (
                      curPage < props.numPages &&
                      !props.showTechnologyBlock
                    ) {
                      setCurPage(curPage + 1);
                      setIsPaginating(true);
                    }
                  }}>
                  <div class="w-5 h-4 relative rounded-lg">
                    <IconArrowRight />
                  </div>
                </div>
              </div>
              <div className="flex gap-2">
                <div
                  class={classNames(
                    'w-10 h-10 p-2.5 rounded-lg justify-center items-center gap-2 inline-flex hover:cursor-pointer',
                    cardView ? 'bg-slate-100' : 'bg-white',
                  )}
                  onClick={() => {
                    setCardView(true);
                  }}>
                  <div class="w-5 h-5 relative">
                    <IconCardView />
                  </div>
                </div>
                <div
                  class={classNames(
                    'w-10 h-10 p-2.5 rounded-lg justify-center items-center gap-2 inline-flex hover:cursor-pointer',
                    !cardView ? 'bg-slate-100' : 'bg-white',
                  )}
                  onClick={() => {
                    setCardView(false);
                  }}>
                  <div class="w-5 h-5 relative">
                    <IconListView />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {props.filteredPeople ? (
            <div
              className={classNames(
                '-my-2 overflow-x-auto -ml-16',
                cardView ? '-mr-16' : '-mr-20',
              )}>
              <div
                className="inline-block min-w-full py-2 align-middle pl-8 pr-4"
                style={{height: 'calc(100vh / var(--zoom) - 300px)'}}>
                {isPaginating ? (
                  <div className="px-5">
                    <SkeletonLoader pagination />
                  </div>
                ) : cardView ? (
                  <CardTable
                    calculatedHeight={props.calculatedHeight}
                    filterType={FILTER_TYPE_FAV_PEOPLE}
                    selectedPersons={selectedPersons}
                    onSelectPerson={(person, checked) => {
                      setSelectedPersons(
                        checked
                          ? [...selectedPersons, person]
                          : selectedPersons.filter(p => p !== person),
                      );
                    }}
                    onRefresh={() => {
                      setIsRefreshing(true);
                    }}
                    onRemoveFav={removeFav}
                  />
                ) : (
                  <ListTable
                    calculatedHeight={props.calculatedHeight}
                    filterType={FILTER_TYPE_FAV_PEOPLE}
                    selectedPersons={selectedPersons}
                    onSelectPerson={(person, checked) => {
                      setSelectedPersons(
                        checked
                          ? [...selectedPersons, person]
                          : selectedPersons.filter(p => p !== person),
                      );
                    }}
                    onRefresh={() => {
                      setIsRefreshing(true);
                    }}
                    onRemoveFav={removeFav}
                  />
                )}
              </div>
            </div>
          ) : null}
          {props.filteredPeople && (
            <div className="min-h-[60px] max-h-[60px]">
              <PaginationBar
                disabled={props.showTechnologyBlock}
                curPage={curPage}
                numPages={props.numPages}
                onPageChange={curPage => {
                  if (!firstLoad) {
                    setIsPaginating(true);
                  }
                  setCurPage(curPage);
                }}
              />
            </div>
          )}
        </div>
      ) : isLoading && !isPaginating ? (
        <SkeletonLoader />
      ) : !isPaginating ? (
        <div
          className="flex justify-center items-center"
          style={{height: 'calc(100vh - 200px)'}}>
          <NoResult hasFilters />
        </div>
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters, technologySearch, appuserData} = state;
  const {totalcount, pages, data} = state.filteredFavPeople;
  return {
    session,
    user,
    savedFilters,
    showTechnologyBlock: technologySearch.showTechnologyBlock,
    searchSelectedSubMenu: state.searchSelectedSubMenu,
    totalcount,
    numPages: pages,
    filteredPeople: data,
    appuserData,
    orignialFavData: state.filteredFavPeople,
  };
}

export default connect(mapStateToProps, {
  filterPeople,
  tooglePeopleFav,
  updateFavPeople,
})(PeopleFavsTable);
