import React, {
  Component,
  Fragment,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {connect} from 'react-redux';
import {classNames} from '@commonFunction';

import IconPhone from '../../results/cells/icons/phone';
import IconEmail from '../../results/cells/icons/email';
import IconReveal from '../../results/cells/icons/reveal';
// import IconFav from '../../results/cells/icons/fav';
import IconSaveList from '../../results/cells/icons/save';
import IconCopy from '../../results/cells/icons/copy';
// import CompanyInfo from './components/companyinfo';
// import ContactInfo from './components/contactinfo';
import IconUpdate from '../../results/cells/icons/update';
// import SubContact from './components/subcontact';
import SubCompany from '../../results/cells/components/subcompany';
// import SocialLinks from './components/sociallinks';
import AddToList from './components/addtolist';
import IconFaved from '../../results/cells/icons/faved';

import {
  FILTER_TYPE_COMPANY,
  FILTER_TYPE_FAV_COMPANY,
  addCompanyTolist,
  toogleCompanyFav,
  updateFavCompany,
  updateCompany,
} from '../../actions';
import IconAddedToList from '../../results/cells/icons/addedtolist';
import IconFav from '../../results/cells/icons/fav';
import SocialLinks from '../../results/cells/components/sociallinks';
import {
  ENTERPRISE_MONTHLY,
  ENTERPRISE_YEARLY,
  toTitleCase,
} from '../../../../../common/constants';
import {useNavigate} from 'react-router-dom';
import IconLock from '../../results/cells/icons/lock';
import LocaleStrings from '../../../../languages';
import AddCompanyToListBlock from './components/addtolistblock';
import Details from '../details';
import IconChevronDown from './icons/chevrondown';
import IconDelete from '../../../mylist/icons/delete';

function CompanyCardCell(props) {
  const {selected, onCheckUnCheck, onOpenCreateList, filterType} = props;
  let {
    companyname,
    industry,
    location,
    company,
    hqnumbers,
    technologies,
    isfav,
    logo,
    addedtolist,
    listid,
    new_contact_data_available,
    new_company_data_available,
  } = props.company;

  const {headcount, revenue, website} = company;

  const revealed = true;

  const techsToShow = technologies.length > 0 ? technologies[0] : '';
  const theCompany = props.company;

  const [showList, setShowList] = useState(false);
  const [expand, setExpand] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const navigate = useNavigate();

  const {linkedin, twitter, facebook} = company;

  return (
    <Fragment>
      <tr key={companyname} className={selected ? 'bg-gray-50' : undefined}>
        {/* {filterType === FILTER_TYPE_FAV_COMPANY ? (
          <td className="group relative px-7 py-10">
            {selected && (
              <div className="absolute inset-y-0 left-0 w-0.5 bg-primary" />
            )}
            <input
              type="checkbox"
              className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-500 text-primary focus:ring-primary group-hover:cursor-pointer"
              value={companyname}
              checked={selected}
              onChange={e => onCheckUnCheck(e.target.checked)}
            />
          </td>
        ) : null} */}
        <td
          className={classNames(
            'group py-4 pr-3 hover:cursor-pointer relative px-7',
          )}
          onClick={e => {
            if (e.target.tagName !== 'svg' || e.target.tagName !== 'path') {
              if (expand === expanded) {
                //Call click only both are same to avoid multiple clicks
                if (!expand) {
                  setExpand(true);
                  setTimeout(() => {
                    setExpanded(true);
                  }, 10);
                } else {
                  setExpanded(false);
                  setTimeout(() => {
                    setExpand(false);
                  }, 400);
                }
              }
            }
          }}>
          <div className="relative flex flex-col space-y-2">
            <div className="flex flex-row items-center ">
              {company.logo ? (
                <div class="justify-center items-center flex">
                  <img
                    className="mr-1 h-6"
                    src={company.logo}
                    onError={e => {
                      e.target.src = require('../../../../../assets/images/empty_company.png');
                    }}
                  />
                </div>
              ) : null}
              <div
                className={classNames(
                  'whitespace-nowrap text-xs font-medium w-fit truncate',
                  selected ? 'text-primary' : 'text-gray-900',
                )}>
                {companyname}
              </div>
            </div>
            <div className="text-xs text-gray-500">{website}</div>
            {!linkedin && !twitter && !facebook ? null : (
              <div>
                <SocialLinks
                  linkedin={linkedin}
                  twitter={twitter}
                  facebook={facebook}
                />
              </div>
            )}
          </div>

          {/* <div
          className="absolute right-2 top-1/2 group-hover:opacity-100 opacity-0"
          onClick={() => {
            navigator.clipboard.writeText(companyname);
            props.onCopySuccess();
          }}>
          <IconCopy />
        </div> */}

          {/* <div className="absolute w-2 h-2 right-2 top-1/2 hover:cursor-pointer">
            {new_contact_data_available ? <IconUpdate /> : null}
          </div> */}
        </td>
        <td
          className="whitespace-nowrap px-3 py-4 text-xs text-gray-500 justify-items-center"
          onClick={() => {
            if (expand === expanded) {
              //Call click only both are same to avoid multiple clicks
              if (!expand) {
                setExpand(true);
                setTimeout(() => {
                  setExpanded(true);
                }, 10);
              } else {
                setExpanded(false);
                setTimeout(() => {
                  setExpand(false);
                }, 400);
              }
            }
          }}>
          <div className="text-xs mb-2 hover:font-medium truncate">
            {toTitleCase(industry)}
          </div>
          <div>
            <div className="truncate">
              <span className="text-xs text-gray-500 h-4">NAICS</span>:{' '}
              <span className="text-xs text-gray-500 h-4">
                {company.naicscodes}
              </span>{' '}
            </div>

            <div className="truncate">
              <span className="text-xs text-gray-500 h-4">SIC</span>:{' '}
              <span className="text-xs text-gray-500 h-4">
                {company.siccodes}
              </span>
            </div>
          </div>
        </td>
        <td
          className="group px-3 py-2 hover:cursor-pointer relative"
          onClick={() => {
            if (expand === expanded) {
              //Call click only both are same to avoid multiple clicks
              if (!expand) {
                setExpand(true);
                setTimeout(() => {
                  setExpanded(true);
                }, 10);
              } else {
                setExpanded(false);
                setTimeout(() => {
                  setExpand(false);
                }, 400);
              }
            }
          }}>
          <div className="group/company relative flex">
            <div className="text-xs text-primary mb-2 hover:font-medium w-fit">
              <SubCompany {...company} cardViewCompany />
            </div>
            {/* {new_company_data_available ? (
              <div className="w-2 h-2 ml-2 mt-1 hover:cursor-pointer tooltip">
                <IconUpdate />
                <span className="tooltiptext tooltip-right">
                  {LocaleStrings.revealedContacts.updateavailable}
                </span>
              </div>
            ) : null} */}
          </div>
          {/* <div
          className="absolute right-2 top-6 group-hover:opacity-100 opacity-0"
          onClick={() => {
            navigator.clipboard.writeText(company.title);
            props.onCopySuccess();
          }}>
          <IconCopy />
        </div> */}
        </td>
        <td
          className="group whitespace-nowrap px-3 py-2 text-xs text-gray-500 justify-items-center hover:cursor-pointer relative"
          onClick={() => {
            if (expand === expanded) {
              //Call click only both are same to avoid multiple clicks
              if (!expand) {
                setExpand(true);
                setTimeout(() => {
                  setExpanded(true);
                }, 10);
              } else {
                setExpanded(false);
                setTimeout(() => {
                  setExpand(false);
                }, 400);
              }
            }
          }}>
          <div
            className={classNames(
              'text-xs mb-2',
              props.credits.role === 'client' && !props.onEnterprisePlan
                ? 'blur-sm hover:font-medium'
                : '',
            )}>
            {techsToShow}
            {props.credits.role === 'client' && props.onEnterprisePlan ? (
              <div
                className="text-primary hover:cursor-pointer"
                onClick={e => {
                  if (e.currentTarget === e.target) {
                    props.onShowTechs(props.company);
                  }
                }}>
                View All
              </div>
            ) : null}
          </div>
          {props.credits.role === 'client' && !props.onEnterprisePlan ? (
            <div
              className="absolute top-4 left-0 flex bg-primaryHover justify-center items-center px-10 py-6 rounded-lg transition-opacity ease-in group-hover:opacity-60 opacity-0"
              onClick={() => {
                navigate('/upgradeplan');
              }}>
              <IconLock size={18} />{' '}
              <span className="text-primary ml-2">{LocaleStrings.upgrade}</span>
            </div>
          ) : null}
        </td>

        <td className={classNames('whitespace-nowrap py-2 pl-3 pr-8')}>
          <div className="group relative flex gap-2 justify-end">
            <AddCompanyToListBlock
              revealed={revealed}
              filterType={filterType}
              addedtolist={addedtolist}
              listid={listid}
              company={company}
              availableLists={props.availableLists}
              onOpenCreateList={company => {
                onOpenCreateList(props.company);
              }}
              onChangeList={list => {
                props.addCompanyTolist(
                  props.session,
                  {
                    listid: list.id,
                    companyids: `${props.company.companyid}`,
                  },
                  _ => {},
                );

                const newcompany = {
                  ...props.company,
                  addedtolist: true,
                  listid: list.id,
                };
                const index = props.filteredCompany.data.findIndex(
                  t => t.companyid === props.company.companyid,
                );
                props.filteredCompany.data[index] = newcompany;
                if (filterType === FILTER_TYPE_COMPANY) {
                  props.updateCompany(props.filteredCompany);
                } else {
                  props.updateFavCompany(props.filteredCompany);
                }
              }}
            />

            <div
              onClick={() => {
                const newcompany = {...props.company, isfav: !isfav};
                if (filterType === FILTER_TYPE_COMPANY) {
                  const index = props.filteredCompany.data.findIndex(
                    t => t.companyid === props.company.companyid,
                  );
                  props.filteredCompany.data[index] = newcompany;
                  props.updateCompany(props.filteredCompany);

                  const params = {
                    action: isfav ? 'removecompanyfav' : 'addcompanyfav',
                    companyid: newcompany.companyid,
                  };
                  props.toogleCompanyFav(props.session, params, res => {
                    console.log('Fav => ', res);
                  });
                } else {
                  props.onRemoveFav(newcompany);
                }
              }}>
              {isfav ? (
                filterType === FILTER_TYPE_FAV_COMPANY ? (
                  <IconDelete />
                ) : (
                  <IconFaved />
                )
              ) : (
                <IconFav />
              )}
            </div>
            <div
              className="ml-4 flex items-center hover:cursor-pointer "
              onClick={() => {
                if (expand === expanded) {
                  //Call click only both are same to avoid multiple clicks
                  if (!expand) {
                    setExpand(true);
                    setTimeout(() => {
                      setExpanded(true);
                    }, 10);
                  } else {
                    setExpanded(false);
                    setTimeout(() => {
                      setExpand(false);
                    }, 400);
                  }
                }
              }}>
              <div
                className={classNames(
                  'transition-all transform',
                  expand ? 'rotate-180' : 'rotate-0',
                )}>
                <IconChevronDown />
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr
        className={classNames(
          expand ? 'bg-primaryHover' : '',
          !expand ? 'hidden' : '',
        )}>
        <td colSpan={5}>
          <div
            className={classNames(
              ' transition-all duration-500 h-0 overflow-hidden',
              expanded ? 'h-[380px]' : 'h-0',
            )}>
            <div className="px-7 py-4 flex">
              <Details
                {...props}
                company={{...company, ...props.company}}
                credits={props.credits}
              />
            </div>
          </div>
        </td>
      </tr>
    </Fragment>
  );
}

function mapStateToProps(state, ownProps) {
  const {session, user, savedFilters, appuserData} = state;
  let onEnterprisePlan = false;
  if (appuserData.credits) {
    onEnterprisePlan =
      appuserData.credits.planid === ENTERPRISE_YEARLY ||
      appuserData.credits.planid === ENTERPRISE_MONTHLY;
  }
  return {
    session,
    user,
    credits: !appuserData.credits ? {role: ''} : appuserData.credits,
    onEnterprisePlan,
    savedFilters,
    availableLists: state.availableCompanyLists,
    searchSelectedSubMenu: state.searchSelectedSubMenu,
    filteredCompany:
      ownProps.filterType === FILTER_TYPE_COMPANY
        ? state.filteredCompany
        : state.filteredFavCompany,
  };
}

export default connect(mapStateToProps, {
  addCompanyTolist,
  toogleCompanyFav,
  updateFavCompany,
  updateCompany,
})(CompanyCardCell);
