import React, {Component, useState} from 'react';
import {connect} from 'react-redux';
import {classNames} from '@commonFunction';
import IconSearch from './icons/search';
import MyListTable from './MyListTable';
import LocaleStrings from '../../languages';
import CreateListPopup from '../search/components/createlistpopup';
import {PlusIcon} from '@heroicons/react/solid';
import {XIcon} from '@heroicons/react/outline';

const TAB_PEOPLE = 'people';
const TAB_COMPANY = 'company';

function MyList(props) {
  const [selectedTab, setSelectedTab] = useState(TAB_PEOPLE);
  const [searchText, setSearchText] = useState('');
  const [openCreateListPopup, setOpenCreateListPopup] = useState(false);
  return (
    <div className="mx-4">
      {/* <div className="text-2xl font-semibold">
        {LocaleStrings.mylist.heading}
      </div> */}
      <div className="mt-2 flow-root flex mb-14">
        <div className="float-left fixed">
          <div className="w-56 h-10 rounded-lg shadow border border-gray-200 justify-start items-start inline-flex">
            <div
              className={classNames(
                'hover:bg-gray-100 hover:cursor-pointer w-28 self-stretch px-4 py-2.5 border-r rounded-l-md border-gray-300 justify-center items-center gap-2 flex',
                selectedTab === TAB_PEOPLE ? 'bg-gray-100' : 'bg-white',
              )}
              onClick={() => {
                setSelectedTab(TAB_PEOPLE);
              }}>
              <div className="text-slate-800 text-xs leading-tight">
                {LocaleStrings.mylist.tabcontacts}
              </div>
            </div>
            <div
              className={classNames(
                'hover:bg-gray-100 hover:cursor-pointer w-28 self-stretch px-4 py-2.5  border-r rounded-r-md border-gray-300 justify-center items-center gap-2 flex',
                selectedTab === TAB_COMPANY ? 'bg-gray-100' : 'bg-white',
              )}
              onClick={() => {
                setSelectedTab(TAB_COMPANY);
              }}>
              <div className="text-slate-700 text-xs leading-tight">
                {LocaleStrings.mylist.tabcompanies}
              </div>
            </div>
          </div>
          <div className="ml-4 w-96 h-9 justify-start items-start gap-2.5 inline-flex align-top">
            <div className="justify-start items-center gap-3 flex">
              <div className="w-96 flex-col justify-start items-start gap-2 inline-flex">
                <div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex">
                  <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-200 justify-start items-center inline-flex">
                    <div className="grow shrink basis-0 h-[18px] justify-start items-center gap-2 flex">
                      <div className="w-5 h-5 relative">
                        <IconSearch />
                      </div>
                      <input
                        type="search"
                        autocomplete="off"
                        className="cust-transparent-input-field bg-transparent w-full no-clear"
                        placeholder={LocaleStrings.mylist.searchplaceholder}
                        value={searchText}
                        onClick={() => {
                          // setToggled(!toggled);
                        }}
                        onChange={e => {
                          setSearchText(e.target.value);
                        }}
                      />
                      {searchText !== '' && (
                        <div
                          className="absolute top-2.4 right-2 z-99 content-center p-1 bg-primaryHover cursor-pointer"
                          style={{borderRadius: 40, height: 20, width: 20}}
                          onClick={() => {
                            setSearchText('');
                          }}>
                          <XIcon
                            className="h-3 w-3 text-primary"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="float-right">
          <button
            type="button"
            className="inline-flex h-11 items-center gap-x-1.5 rounded-md bg-primary px-3 py-2.5 text-xs font-semibold text-white shadow-sm hover:opacity-90"
            onClick={() => {
              setOpenCreateListPopup(true);
            }}>
            <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            {LocaleStrings.mylist.newlist}
          </button>
        </div> */}
      </div>
      <div
        className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg my-4 bg-white"
        style={{height: 'calc(100vh - 185px)'}}>
        <MyListTable tab={selectedTab} searchText={searchText} />
      </div>
      <CreateListPopup
        open={openCreateListPopup}
        toggleOpen={open => {
          setOpenCreateListPopup(open);
        }}
      />
    </div>
  );
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
});

export default connect(mapStateToProps, {})(MyList);
