import LocaleStrings from '../../../../languages';
import IconPeople from '../icons/people';
import {connect} from 'react-redux';
import {
  toggleCompanySelectStandardFields,
  togglePeopleSelectStandardFields,
} from '../../actions';
import IconCompany from '../icons/company';

function SelectionField(props) {
  const {dataKey, title, checked} = props;

  return (
    <div
      className="border border-gray-200 shadow-sm rounded-md px-2 py-1"
      style={{height: 42}}>
      <div key={dataKey} className="group relative flex items-start py-1">
        <div className="ml-2 mr-2 flex h-6 items-center">
          <input
            id={`field-${dataKey}`}
            name={`field-${dataKey}`}
            disabled={props.mandatory}
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-primary focus:primary cursor-pointer"
            onChange={e => {
              const settings = {...props.standardFieldSettings};
              settings[dataKey].selection = e.target.checked;
              if (props.section === 'people') {
                props.togglePeopleSelectStandardFields(settings);
              } else {
                props.toggleCompanySelectStandardFields(settings);
              }
            }}
            checked={checked}
          />
        </div>
        <div className="min-w-0 flex-1 text-xs leading-6">
          <label
            htmlFor={`field-${dataKey}`}
            className="select-none text-sm text-gray-900 group-hover:text-primary cursor-pointer">
            {title}
          </label>
        </div>
      </div>
    </div>
  );
}

function StandardFields(props) {
  const allKeys = Object.keys(props.standardFieldSettings);
  const allSelected =
    allKeys.filter(key => props.standardFieldSettings[key].selection).length ===
    allKeys.length;
  return (
    <div className="flex gap-4 flex-col content-center p-4">
      <div className="flex flex-row gap-2 items-center">
        <div className="w-10 h-10 p-2 bg-secondary rounded-full justify-center items-center flex">
          <div class="justify-center items-center flex">
            {props.section === 'people' ? <IconPeople /> : <IconCompany />}
          </div>
        </div>
        <div>
          <div className="text-lg font-semibold">
            {props.section === 'people'
              ? LocaleStrings.enrichment.people.settingsTitle
              : LocaleStrings.enrichment.company.settingsTitle}
          </div>
          <div className="text-gray-600 text-xs">
            {LocaleStrings.enrichment.settingsSubTitle}
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex justify-between py-4">
          <div className="text-sm font-semibold">
            {LocaleStrings.enrichment.selectStandardFields}
          </div>
          <div>
            <button
              className="text-sm"
              onClick={() => {
                const settings = {...props.standardFieldSettings};
                if (allSelected) {
                  allKeys.map(key => {
                    if (!settings[key].mandatory)
                      settings[key].selection = false;
                  });
                } else {
                  allKeys.map(key => {
                    settings[key].selection = true;
                  });
                }
                if (props.section === 'people') {
                  props.togglePeopleSelectStandardFields(settings);
                } else {
                  props.toggleCompanySelectStandardFields(settings);
                }
              }}>
              {allSelected
                ? LocaleStrings.enrichment.deselectAll
                : LocaleStrings.enrichment.selectAll}
            </button>{' '}
          </div>
        </div>
        <div
          className="overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent scrollbar-thumb-rounded-full"
          style={{height: 'calc(100vh - 250px)'}}>
          <div className="gap-4 grid grid-cols-2 ">
            {allKeys.map(key => (
              <SelectionField
                key={key}
                {...props}
                {...props.standardFieldSettings[key]}
                dataKey={key}
                checked={props.standardFieldSettings[key].selection}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  appuserData: state.appuserData,
  standardFieldSettings:
    state.csvSelectionPopupData.section === 'people'
      ? state.peopleStandardFieldSettings
      : state.companyStandardFieldSettings,
  section: state.csvSelectionPopupData.section,
});

export default connect(mapStateToProps, {
  togglePeopleSelectStandardFields,
  toggleCompanySelectStandardFields,
})(StandardFields);
