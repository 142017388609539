import React, {Component} from 'react';
import {connect} from 'react-redux';
import LocaleStrings from '@language';
import {getContact, searchUrl} from '../actions';
import toast, {Toaster} from 'react-hot-toast';
import {classNames} from '@commonFunction';
import NoContact from './no-contact';
import {contactmetaAddupdate} from '@myContactsActions';
import {appConstant} from '../../../../../common/common-utils';
import IconSearch from '../../../mylist/icons/search';
import SkeletonLoader from '../../../../../common/skeletonloader';
import Compositesubcontact from '../../results/cells/components/compositesubcontact';
import Createlistpopup from '../../components/createlistpopup';
import Reportcontactpopup from '../../components/reportcontactpopup';
import {XIcon} from '@heroicons/react/outline';

const kProfileRegex =
  /^(?:https?:\/\/)?(?:www\.)?linkedin\.com\/in\/([a-zA-Z0-9-]+)(\/[a-zA-Z0-9-]+)?\/?$/;

class LinkedinSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      searchText: '',
      searchedData: null,
      isFetching: false,
      expired: false,
      openFlagPopup: false,
      selectedContact: undefined,
      searchWasInitiated: false,
    };
  }

  validated = () => {
    const {searchText} = this.state;
    const profileurl = searchText.replace(/\/$|$/, '/');
    let matched = profileurl.match(kProfileRegex);
    if (matched && matched.length > 0) {
      return true;
    }
    toast.error(LocaleStrings.wrongSearchUrl);
    return false;
  };

  handleSearch = () => {
    if (this.validated()) {
      this.setState({isFetching: true, searchWasInitiated: true});
      this.props.searchUrl(
        this.props.session,
        {
          linkedinurl: this.state.searchText.trim(),
        },
        response => {
          if (response.success === 1) {
            if (response.result) {
              let person = response.result;

              let contactdetails = person.contactdetails;

              const personalEmails = contactdetails.filter(
                contact => contact.type === 'personalemail',
              );
              const businessEmails = contactdetails.filter(
                contact => contact.type === 'businessemail',
              );
              const directDials = contactdetails.filter(
                contact => contact.type === 'directdial',
              );

              const emptyContactDetails = [];

              if (personalEmails.length > 0) {
                emptyContactDetails.push(personalEmails[0]);
              } else {
                emptyContactDetails.push({
                  values: ['*******@*****.***'],
                  validation: 'unknown',
                  encrypted: true,
                  type: 'personalemail',
                  available: false,
                });
              }

              if (businessEmails.length > 0) {
                emptyContactDetails.push(businessEmails[0]);
              } else {
                emptyContactDetails.push({
                  values: ['*******@*****.***'],
                  validation: 'unknown',
                  encrypted: true,
                  type: 'businessemail',
                  available: false,
                });
              }

              if (directDials.length > 0) {
                emptyContactDetails.push(directDials[0]);
              } else {
                emptyContactDetails.push({
                  values: ['+**********'],
                  validation: 'unknown',
                  encrypted: true,
                  type: 'directdial',
                  available: false,
                });
              }
              contactdetails = emptyContactDetails;

              const updatedPerson = {...person, contactdetails};

              this.setState({
                isFetching: false,
                searchedData: updatedPerson,
              });
            } else {
              this.setState({
                isFetching: false,
                searchedData: null,
              });
            }
          } else {
            this.setState({isFetching: false});
          }
        },
      );
    }
  };

  updateContactMeta = (val, action, type, downvotedata) => {
    let obj = {};
    obj.linkedin_url = this.state.searchText;
    obj.info = val;
    obj.action = action;
    obj.type = type;

    if (action === 'downvote') {
      obj.downvotereason = downvotedata.downvotereason;
      obj.reasonnote = downvotedata.reasonnote;
    }

    this.props.contactmetaAddupdate(this.props.session, obj, callback => {
      toast.success('Thanks! Your feedback has been submitted.');
    });
  };

  onBuyAddon = () => {
    this.props.router.navigate('/creditaddons');
  };

  onUpgradePlan = () => {
    this.props.router.navigate('/upgradeplan');
  };

  render() {
    const {searchedData, searchText, searchWasInitiated} = this.state;

    let contactname = '';
    let contactdetails;

    if (searchedData) {
      contactname = searchedData.contactname;
      contactdetails = searchedData.contactdetails;
    }

    return (
      <div className="px-4 h-full py-8">
        <div className="flex flex-row justify-start">
          <div className="w-1/3">
            <div className="py-5 gap-8 items-center bg-white p-4 rounded-lg border-2 border-gray-100">
              <div className="text-primary text-sm">
                {LocaleStrings.searchbylinkedin.title}
              </div>
              <div className="flex flex-col gap-4 py-2">
                <div className="w-96 h-9 justify-start items-start gap-2.5 inline-flex align-top">
                  <div className="justify-start items-center gap-3 flex">
                    <div className="w-96 flex-col justify-start items-start gap-2 inline-flex relative">
                      <div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex">
                        <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-100 justify-start items-center inline-flex">
                          <div className="grow shrink basis-0 h-[18px] justify-start items-center gap-2 flex">
                            <div className="w-5 h-5 relative">
                              <IconSearch />
                            </div>
                            <input
                              type="search"
                              autocomplete="off"
                              className="cust-transparent-input-field bg-transparent w-full no-clear"
                              placeholder={
                                LocaleStrings.searchbylinkedin.placeholder
                              }
                              value={searchText}
                              onClick={() => {
                                // setToggled(!toggled);
                              }}
                              onChange={e => {
                                this.setState({searchText: e.target.value});
                                if (e.target.value.length === 0) {
                                  this.setState({
                                    searchedData: null,
                                    searchWasInitiated: false,
                                  });
                                }
                              }}
                            />
                            {searchText !== '' && (
                              <div
                                className="absolute top-2.4 right-2 z-99 content-center p-1 bg-primaryHover cursor-pointer"
                                style={{
                                  borderRadius: 40,
                                  height: 20,
                                  width: 20,
                                }}
                                onClick={() => {
                                  this.setState({
                                    searchedData: null,
                                    searchWasInitiated: false,
                                    searchText: '',
                                  });
                                }}>
                                <XIcon
                                  className="h-3 w-3 text-primary"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <fieldset className="space-y-2 flex flex-col py-2 mt-2">
                <span>
                  <input
                    id="phonenumber"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary mr-2"
                    onChange={e => {
                      this.setState({phoneChecked: e.target.checked});
                      if (this.state.searchedData) {
                        this.handleSearch(
                          this.state.personalChecked,
                          this.state.businessChecked,
                          e.target.checked,
                        );
                      }
                    }}
                    checked={this.state.phoneChecked}
                  />
                  <label htmlFor="phonenumber">
                    {LocaleStrings.enrich.phone_no}
                  </label>
                </span>
                <span>
                  <input
                    id="businessemail"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary mr-2"
                    onChange={e => {
                      this.setState({businessChecked: e.target.checked});
                      if (this.state.searchedData) {
                        this.handleSearch(
                          this.state.personalChecked,
                          e.target.checked,
                          this.state.phoneChecked,
                        );
                      }
                    }}
                    checked={this.state.businessChecked}
                  />
                  <label htmlFor="businessemail">
                    {LocaleStrings.enrich.work_email}
                  </label>
                </span>
                <span>
                  <input
                    id="personalemail"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary mr-2"
                    onChange={e => {
                      this.setState({personalChecked: e.target.checked});
                      if (this.state.searchedData) {
                        this.handleSearch(
                          e.target.checked,
                          this.state.businessChecked,
                          this.state.phoneChecked,
                        );
                      }
                    }}
                    checked={this.state.personalChecked}
                  />
                  <label htmlFor="personalemail">
                    {LocaleStrings.enrich.secondary_email}
                  </label>
                </span>
              </fieldset> */}
              <div className="flex mt-4 items-center justify-center px-10">
                <button
                  type="button"
                  className={classNames(
                    'items-center',
                    searchText !== ''
                      ? 'btn-primary hover:opacity-90'
                      : 'btn-disabled ',
                  )}
                  disabled={searchText === ''}
                  onClick={e => {
                    this.handleSearch();
                  }}>
                  {'Search'}
                </button>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              'justify-center bg-white rounded-lg border-2 border-gray-100 w-2/3 ml-4',
            )}>
            {this.state.isFetching ? (
              <SkeletonLoader small />
            ) : searchedData ? (
              <div className="p-10 flex flex-col">
                <div className="py-2 text-sm">{LocaleStrings.contactEmail}</div>
                {contactdetails
                  .filter(
                    contact =>
                      contact.type === 'personalemail' ||
                      contact.type === 'businessemail',
                  )
                  .map(contact => (
                    <div key={`${contactname}-${contact.type}`}>
                      <Compositesubcontact
                        {...searchedData}
                        {...contact}
                        searchlinkedin
                        contactname={contactname}
                        contacttype="email"
                        onUpgradePlan={this.onUpgradePlan}
                        onBuyAddon={this.onBuyAddon}
                        onClickFlag={contactItem => {
                          if (contactItem.action === 'downvote') {
                            this.setState({
                              openFlagPopup: true,
                              selectedContact: {...contact, ...searchedData},
                            });
                          } else {
                            toast.success(
                              LocaleStrings.mycontacts_upvoted_success,
                            );
                          }
                        }}
                        onRevealCompletion={updatedPerson => {
                          this.setState({searchedData: updatedPerson});
                        }}
                      />
                    </div>
                  ))}
                <div>
                  <div className="py-2 text-sm">
                    {LocaleStrings.contactPhone}
                  </div>
                  {contactdetails
                    .filter(contact => contact.type === 'directdial')
                    .map(contact => (
                      <div key={`${contactname}-${contact.type}`}>
                        <Compositesubcontact
                          {...searchedData}
                          {...contact}
                          searchlinkedin
                          contactname={contactname}
                          contacttype="phone"
                          onUpgradePlan={this.onUpgradePlan}
                          onBuyAddon={this.onBuyAddon}
                          onClickFlag={contactItem => {
                            if (contactItem.action === 'downvote') {
                              this.setState({
                                openFlagPopup: true,
                                selectedContact: {
                                  ...searchedData,
                                  ...contact,
                                  contacttype: contact.type,
                                },
                              });
                            } else {
                              toast.success(
                                LocaleStrings.mycontacts_upvoted_success,
                              );
                            }
                          }}
                          onRevealCompletion={updatedPerson => {
                            this.setState({searchedData: updatedPerson});
                          }}
                        />
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <NoContact validProfileUrl={searchText && searchWasInitiated} />
            )}
          </div>
        </div>
        <Toaster />

        <Reportcontactpopup
          open={this.state.openFlagPopup}
          {...this.state.selectedContact}
          onClose={() => {
            this.setState({openFlagPopup: false});
          }}
          onSubmit={data => {
            this.updateContactMeta(
              this.selectedContact.contact,
              'downvote',
              this.selectedContact.contacttype,
              this.selectedContact.linkedin,
              data,
            );
            this.setState({openFlagPopup: false, selectedContact: null});
          }}
        />
        <table className="w-full table-fixed" />
      </div>
    );
  }
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  currentUser: {...state.user, session_token: state.session},
});

export default connect(mapStateToProps, {
  searchUrl,
  getContact,
  contactmetaAddupdate,
})(LinkedinSearch);
