import React, {
  Component,
  createRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {connect} from 'react-redux';
import {classNames} from '@commonFunction';
import IconSearch from './icons/search';

import LocaleStrings from '../../languages';
import {ArrowNarrowLeftIcon} from '@heroicons/react/solid';
import {ChevronDownIcon} from '@heroicons/react/solid';

import {closeList, saveCompanyListFilter} from './actions';
import TimeDropdown from './components/timedropdown';
import {timeFilters} from './constants';
import _, {debounce} from 'lodash';
import ListCompanyTable from './ListCompanyTable';
import DateRangePopup from './components/daterange';

function ListCompany(props) {
  const [searchText, setSearchText] = useState('');
  const [toggleOpenTimeFilter, setToggleOpenTimeFilter] = useState(false);
  const [timeFilterCoords, setTimeFilterCoords] = useState(undefined);
  const [toggleOpenDateRangePopup, setToggleOpenDateRangePopup] =
    useState(false);
  const timeFilterRef = createRef();

  const onSearch = val => {
    const filters = props.savedFilters;
    filters.searchtext = val;
    props.saveCompanyListFilter(filters);
  };

  useEffect(() => {
    if (!timeFilterCoords) {
      const rect = timeFilterRef.current.getBoundingClientRect();
      setTimeFilterCoords({
        left: rect.x,
        top: rect.y - 62,
      });
    }
  }, [timeFilterRef, toggleOpenTimeFilter]);

  const debounceFunc = useCallback(debounce(onSearch, 1000), []);

  return (
    <div className="mt-2">
      <div className="flex mb-[55px]">
        <div className="fixed">
          <div className="flex items-center ml-2">
            <ArrowNarrowLeftIcon
              className="h-4 w-10 text-gray-400 hover:cursor-pointer"
              aria-hidden="true"
              onClick={() => {
                props.closeList();
              }}
            />
            <div className="text-base font-semibold max-w-40 truncate">
              {props.activeList.listname}
            </div>
            <div className="flow-root flex">
              <div className="ml-4 w-96 h-10 justify-start items-start gap-2.5 inline-flex">
                <div className="justify-start items-center gap-3 flex">
                  <div className="w-96 flex-col justify-start items-start gap-2 inline-flex">
                    <div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex">
                      <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-200 justify-start items-center inline-flex">
                        <div className="grow shrink basis-0 h-5 justify-start items-center gap-2 flex">
                          <div className="w-5 h-5 relative">
                            <IconSearch />
                          </div>
                          <input
                            type="search"
                            autocomplete="off"
                            className="cust-transparent-input-field bg-transparent w-full"
                            placeholder={
                              LocaleStrings.listcompany.searchplaceholder
                            }
                            value={searchText}
                            onClick={() => {
                              // setToggled(!toggled);
                            }}
                            onChange={e => {
                              setSearchText(e.target.value);
                              onSearch(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-56 h-10 flex-col justify-start items-start gap-2 inline-flex ml-4 align-top">
                <div className="self-stretch h-10 flex-col justify-start items-start gap-1.5 flex relative">
                  <div
                    ref={timeFilterRef}
                    className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-200 justify-start items-center gap-2 inline-flex hover:cursor-pointer hover:bg-gray-50"
                    onClick={() => {
                      setToggleOpenTimeFilter(!toggleOpenTimeFilter);
                    }}>
                    <div className="grow shrink basis-0 h-5 justify-start items-center gap-2 flex">
                      <div className="text-gray-500 text-sm leading-normal">
                        {
                          timeFilters.filter(
                            filter =>
                              filter.type === props.savedFilters.time.type,
                          )[0].title
                        }
                      </div>
                    </div>
                    <div className="w-5 h-5 relative">
                      <ChevronDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="w-56 h-11 flex-col justify-start items-start gap-2 inline-flex ml-4 align-top">
                <div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex relative">
                  <div
                    className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex hover:cursor-pointer hover:bg-gray-50"
                    onClick={() => {
                      setToggleOpenLabelFilter(!toggleOpenLabelFilter);
                    }}>
                    <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                      <div
                        className={classNames(
                          "text-gray-500 text-base font-normal font-['Inter'] leading-normal",
                          props.savedFilters.tags !== '' ? 'text-primary' : '',
                        )}>
                        {props.savedFilters.tags !== ''
                          ? 'Multiple labels'
                          : 'Filter by labels'}
                      </div>
                    </div>
                    <div className="w-5 h-5 relative">
                      <ChevronDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <div
                    className={classNames(
                      'absolute left-0 top-12 transition duration-300 ease-in z-50',
                      toggleOpenLabelFilter ? 'visible' : 'invisible',
                    )}>
                    <Filterlabeldropdown
                      onClose={() => {
                        setToggleOpenLabelFilter(false);
                      }}
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div
        className="overflow-hidden shadow-md rounded-md border border-gray-200 border-solid my-4 mx-4 bg-white justify-center items-center flex"
        style={{height: 'calc(100vh - 185px)'}}>
        <ListCompanyTable />
      </div>
      <div
        className={classNames(
          'absolute transition duration-300 ease-in',
          toggleOpenTimeFilter ? 'visible' : 'invisible',
        )}
        style={{
          zIndex: 9999,
          left: timeFilterCoords ? timeFilterCoords.left : 0,
          top: timeFilterCoords ? timeFilterCoords.top : 0,
        }}>
        <TimeDropdown
          times={timeFilters}
          type="companyfilter"
          onSelectTimeFilter={filter => {
            if (filter.type !== 'custom') {
              const filters = props.savedFilters;
              const time = {type: filter.type, value: null};
              filters.time = time;
              props.saveCompanyListFilter(filters);
            } else {
              setToggleOpenDateRangePopup(true);
            }
            setToggleOpenTimeFilter(false);
          }}
        />
      </div>
      <DateRangePopup
        open={toggleOpenDateRangePopup}
        toggleOpen={() => {
          setToggleOpenDateRangePopup(false);
        }}
        onApplyCustom={range => {
          setToggleOpenDateRangePopup(false);
          const filters = props.savedFilters;
          const time = {type: 'custom', value: range};
          filters.time = time;
          props.saveCompanyListFilter(filters);
        }}
      />
    </div>
  );
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  activeList: state.activeList,
  savedFilters: state.mylistCompanyFilters,
});

export default connect(mapStateToProps, {closeList, saveCompanyListFilter})(
  ListCompany,
);
