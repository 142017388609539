import {useEffect, useState} from 'react';
import LocaleStrings from '@language';
import {connect} from 'react-redux';
import {saveFilter} from '../../../../actions';

function Seniority(props) {
  const [selectedValues, setSelectedValues] = useState([]);

  const updateFilterReducer = vals => {
    let filters = props.savedFilters;
    let filterObject = filters;
    filterObject['seniority'] = vals;
    filters = {...filters, ...filterObject};
    props.saveFilter(props.session, filters, () => {});
  };

  useEffect(() => {
    updateFilterReducer(selectedValues.map(val => ({title: val, value: val})));
  }, [selectedValues]);

  useEffect(() => {
    let filters = props.savedFilters;
    let filterObject = filters;

    if (filterObject['seniority'].length === 0 && selectedValues.length !== 0) {
      setSelectedValues([]);
    }
  }, [props.savedFilters]);

  return (
    <div>
      <fieldset>
        <legend className="text-xs leading-6 text-gray-900 pt-2">
          {LocaleStrings.search.peoplesearch.seniority_title}
        </legend>
        <div>
          {props.seniorities &&
            props.seniorities.map(({title, value}, index) => (
              <div key={value} className="group relative flex items-start py-1">
                <div className="mr-2 flex h-6 items-center">
                  <input
                    id={`seniority-${value}`}
                    name={`seniority-${value}`}
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-primary focus:primary"
                    onChange={e =>
                      setSelectedValues(
                        e.target.checked
                          ? [...selectedValues, value]
                          : selectedValues.filter(p => p !== value),
                      )
                    }
                    checked={selectedValues.includes(value)}
                  />
                </div>
                <div className="min-w-0 flex-1 text-sm leading-6">
                  <label
                    htmlFor={`seniority-${value}`}
                    className="select-none text-xs text-gray-900 group-hover:text-primary">
                    {title}
                  </label>
                </div>
              </div>
            ))}
        </div>
      </fieldset>
    </div>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  return {
    session,
    user,
    savedFilters,
    seniorities: state.preloadedPeopleFilterSupportData.seniorities,
  };
}

export default connect(mapStateToProps, {saveFilter})(Seniority);
