import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import {SearchIcon} from '@heroicons/react/solid';
import BaseLoader from '@baseLoader';
import LocaleStrings from '@language';
import ApiusageItem from './apiusage-itemlist';
import {fetchApiusage} from '@settingsUsageActions';
import {classNames} from '@commonFunction';
import SkeletonLoader from '../../../../../../../common/skeletonloader';

var d = new Date();
var nowx = d.setMonth(d.getMonth() - -0);
let month = moment(nowx).format('YYYY-MM-DD');

let startDate = month.substring(0, 8) + '01';
// Check added for the month of february
let endDate = '';
let checkfebruary = month.substring(0, 7);
let febArr = checkfebruary.split('-');
let checkfeb = String(febArr[1]);
if (checkfeb == '02') {
  endDate = month.substring(0, 8) + '28';
} else {
  endDate = month.substring(0, 8) + '30';
}

class UsageApi extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_usage_platform,
      dateIndex: 0,
      dateNameToShow: '',
      dateNameToHidden: '',
      dailyCurrentSelection: '',
      yearMonthName: '',
      startdate: startDate,
      enddate: endDate,
      search: '',
    };

    // All Binded Functions
  }

  componentDidMount() {
    let {session, user} = this.props;
    let {startdate, enddate, search} = this.state;

    let values = {};
    values.startdate = startdate + ' ' + '00:00:00';
    values.enddate = enddate + ' ' + '23:59:59';
    values.clientid = user.clientid;
    values.search = '';
    this.props.fetchApiusage(session, values, callback => {
      this.setState({loading: false});
    });
  }

  /**** Date change With Arrows *****/
  _handleNameChangeAndindex = (e, leftorright) => {
    let {session, user} = this.props;
    let {startdate, enddate, search} = this.state;

    if (leftorright == 'minus') {
      this.setState({loading: true});
      // Minus
      var stateVal = this.state.dateIndex;
      var currVal = --stateVal;

      this.setState({dateIndex: currVal});

      var d = new Date();
      var nowx = d.setMonth(d.getMonth() - -currVal);
      let month = moment(nowx).format('YYYY-MM-DD');

      let startdate1 = month.substring(0, 8) + '01';
      // Check added for the month of february
      let enddate1 = '';
      let checkfebruary = month.substring(0, 7);
      let febArr = checkfebruary.split('-');
      let checkfeb = String(febArr[1]);

      if (checkfeb == '02') {
        enddate1 = month.substring(0, 8) + '28';
      } else {
        enddate1 = month.substring(0, 8) + '30';
      }
      // enddate1 = month.substring(0, 8) + "30";
      this.setState({startdate: startdate1});
      this.setState({enddate: enddate1});
      let values = {};
      values.startdate = startdate1 + ' ' + '00:00:00';
      values.enddate = enddate1 + ' ' + '23:59:59';
      values.clientid = user.clientid;
      this.props.fetchApiusage(session, values, callback => {
        this.setState({loading: false});
      });
    } else {
      this.setState({loading: true});
      // Plus
      var stateVal = this.state.dateIndex;
      var currVal = ++stateVal;

      this.setState({dateIndex: currVal});

      var d = new Date();
      var nowx = d.setMonth(d.getMonth() - -currVal);
      let month = moment(nowx).format('YYYY-MM-DD');

      let startdate1 = month.substring(0, 8) + '01';
      // Check added for the month of february
      let enddate1 = '';
      let checkfebruary = month.substring(0, 7);
      let febArr = checkfebruary.split('-');
      let checkfeb = String(febArr[1]);

      if (checkfeb == '02') {
        enddate1 = month.substring(0, 8) + '28';
      } else {
        enddate1 = month.substring(0, 8) + '30';
      }
      // let enddate1 = month.substring(0, 8) + "30";
      this.setState({startdate: startdate1});
      this.setState({enddate: enddate1});
      let values = {};
      values.startdate = startdate1 + ' ' + '00:00:00';
      values.enddate = enddate1 + ' ' + '23:59:59';
      values.clientid = user.clientid;
      this.props.fetchApiusage(session, values, callback => {
        this.setState({loading: false});
      });
    }
  };

  showYearMonth = () => {
    var d = new Date();
    var nowx = d.setMonth(d.getMonth() - -this.state.dateIndex);
    let month = moment(nowx).format('YYYY-MM-DD');
    // console.log('month',month)
    return moment(nowx).format('MMMM  YYYY');
  };

  renderapiusageList() {
    var items = _.map(this.props.apiusagedataList.data, (values, index) => (
      <ApiusageItem
        keyindex={index}
        values={values}
        // pagination={this.paginationCallback}
        // pageDataLength={pageDataLength}
        // currentPage={pageNumber}
      />
    ));
    return items;
  }

  handleSearch = event => {
    let {session, user} = this.props;
    let {startdate, enddate, search} = this.state;
    var searchKey = event.target.value;
    this.setState({search: searchKey});
    let values = {};
    values.startdate = startdate + ' ' + '00:00:00';
    values.enddate = enddate + ' ' + '23:59:59';
    values.clientid = user.clientid;

    if (searchKey !== '') {
      values.search = searchKey;
    } else {
      values.search = '';
    }
    this.setState({loading: true});
    this.props.fetchApiusage(session, values, res => {
      this.setState({loading: false});
    });
  };

  render() {
    let {apiusagedataList} = this.props;
    return (
      <>
        <div class="w-full  mx-auto bg-white  rounded-md border border-gray-200">
          <header class="px-5 py-2 border-b border-gray-100">
            <div className="block md:flex">
              <div className="relative w-full md:w-6/12 text-ternary focus-within:text-secondary ">
                <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-3">
                  <SearchIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                  id="search-field"
                  className="block lg:w-8/12 border-gray-300 xs:w-full sm:w-full h-full pl-8 pr-3 py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                  placeholder={'Search'}
                  type="search"
                  name="search"
                  value={this.state.search}
                  onChange={this.handleSearch}
                />
              </div>
              <div className="ml-auto mt-4 md:mt-0">
                <span className="isolate inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    onClick={e => this._handleNameChangeAndindex(e, 'minus')}
                    className="relative inline-flex items-center bg-white rounded-l-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50  focus:outline-none ">
                    <img src="../../images/previous.png" />
                  </button>
                  <button
                    type="button"
                    className="relative -ml-px inline-flex items-center bg-white  border border-gray-300  px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
                    {this.showYearMonth()}
                  </button>
                  <button
                    type="button"
                    onClick={e => this._handleNameChangeAndindex(e, 'plus')}
                    className={classNames(
                      'relative -ml-px inline-flex items-center bg-white  rounded-r-md border border-gray-300  px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50',
                      this.state.dateIndex === 0 ? 'opacity-50' : 'opacity-100',
                    )}>
                    <img src="../../images/next.png" />
                  </button>
                </span>
              </div>
            </div>
          </header>
        </div>
        {this.state.loading ? (
          <SkeletonLoader small />
        ) : (
          <>
            {apiusagedataList &&
            apiusagedataList.data &&
            apiusagedataList.data.length > 0 ? (
              <>
                <div className="flex flex-col pr-1">
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 md:pl-0 md:pr-1">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table className="min-w-full table-fixed divide-y divide-gray-300">
                          <thead className="">
                            <tr>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-xs font-medium text-gray500">
                                {LocaleStrings.setting_usage__api_th1}
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-xs font-medium text-gray500">
                                {LocaleStrings.setting_usage__api_th2}
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-xs font-medium text-gray500 text-center">
                                {LocaleStrings.setting_usage__api_th3}
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-xs font-medium text-gray500 text-center">
                                {LocaleStrings.setting_usage__api_th4}
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-xs font-medium text-gray500 text-center">
                                {LocaleStrings.setting_usage__api_th5}
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-xs font-medium text-gray500 text-center">
                                {LocaleStrings.setting_usage__api_th6}
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-xs font-medium text-gray500 text-center">
                                {LocaleStrings.setting_usage__api_th7}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {this.renderapiusageList()}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="center-item my-24 lg:my-48 text-lg text-ternary">
                {LocaleStrings.no_data_found}
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  apiusagedataList: state.apiusagedataList,
});

export default connect(mapStateToProps, {
  fetchApiusage,
})(UsageApi);
