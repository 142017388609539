export default function IconManageLabel() {
  return (
    <div className="w-8 h-8 p-2.5 bg-white rounded-lg shadow border border-indigo-200 justify-center items-center gap-1 inline-flex">
      <div class="justify-center items-center flex">
        <svg
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_3532_7244)">
            <rect
              x="2"
              y="1"
              width="48"
              height="48"
              rx="10"
              fill="white"
              shape-rendering="crispEdges"
            />
            <rect
              x="2.5"
              y="1.5"
              width="47"
              height="47"
              rx="9.5"
              stroke="#EAECF0"
              shape-rendering="crispEdges"
            />
            <path
              d="M26 33.0002H35M30.5 16.5002C30.8978 16.1024 31.4374 15.8789 32 15.8789C32.2786 15.8789 32.5544 15.9338 32.8118 16.0404C33.0692 16.147 33.303 16.3032 33.5 16.5002C33.697 16.6972 33.8532 16.9311 33.9598 17.1884C34.0665 17.4458 34.1213 17.7217 34.1213 18.0002C34.1213 18.2788 34.0665 18.5547 33.9598 18.812C33.8532 19.0694 33.697 19.3032 33.5 19.5002L21 32.0002L17 33.0002L18 29.0002L30.5 16.5002Z"
              stroke="#344054"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_3532_7244"
              x="0"
              y="0"
              width="52"
              height="52"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_3532_7244"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_3532_7244"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  );
}
