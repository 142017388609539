import {useState, Fragment} from 'react';
import LocaleStrings from '@language';
import TaggedSearch from '@commonFolder/TaggedSearch';
import {Combobox} from '@headlessui/react';

import {industryCodes} from '../../../../constants';
// import ChevronUpDownIcon from './ChevronUpDownIcon';
import CheckIcon from './CheckIcon';
import {classNames} from '@commonFunction';
import {connect} from 'react-redux';
import {useEffect} from 'react';
import {saveFilter} from '../../../../actions';
import Chip from '../../../../../../../common/Chip';
import {ChevronDownIcon} from '@heroicons/react/solid';

function Industry(props) {
  const [selectedValues, setSelectedValues] = useState(
    props.savedFilters.industrycode,
  );
  const [results, setResults] = useState([]);
  const [code, setCode] = useState('');

  const handleSearch = val => {
    if (val !== '' && val.length > 1) {
      const filtered = props.industries.filter(
        item => item.title.toLowerCase().indexOf(val.toLowerCase()) > -1,
      );
      setResults(filtered);
    }
  };
  const [query, setQuery] = useState('');
  const [selectedCode, setSelectedCode] = useState(industryCodes[0]);

  const filteredCode =
    query === ''
      ? industryCodes
      : industryCodes.filter(code => {
          return code.toLowerCase().includes(query.toLowerCase());
        });

  const applyFilter = () => {
    let newArray = [...selectedValues];
    newArray.push({type: selectedCode, code});
    setSelectedValues(newArray);

    setCode('');
  };

  useEffect(() => {
    let filters = props.savedFilters;
    let searchObject = filters;
    searchObject['industrycode'] = [...selectedValues];
    filters = {...filters, ...searchObject};
    props.saveFilter(props.session, filters, () => {});
  }, [selectedValues]);

  useEffect(() => {
    let filters = props.savedFilters;
    let filterObject = filters;

    if (
      filterObject['industrycode'].length === 0 &&
      selectedValues.length !== 0
    ) {
      setSelectedValues([]);
    } else if (
      filterObject['industrycode'].length !== 0 &&
      selectedValues.length === 0
    ) {
      setSelectedValues(filterObject['industrycode']);
    }
  }, [props.savedFilters]);

  return (
    <div>
      <TaggedSearch
        placeholder={LocaleStrings.search.companysearch.industry}
        id="industry"
        name="industry"
        takesAnyValue={false}
        results={results}
        onSearch={val => {
          handleSearch(val);
        }}
        onToggled={toggled => {
          if (!toggled) {
            setResults([]);
          }
        }}
        showOptions
        {...props}
      />
      <fieldset>
        <div className="flex flex-row justify-between pt-2">
          <legend className="text-xs text-gray-900">
            {LocaleStrings.search.companysearch.industry_title}
          </legend>
          {selectedValues.length > 0 ? (
            <div
              className="flex-none text-xs text-primary text-right"
              onClick={() => {
                setSelectedValues([]);
                setCode('');
                setSelectedCode(industryCodes[0]);
              }}>{`Clear(${selectedValues.length})`}</div>
          ) : null}
        </div>
        <div className="flex flex-row gap-2 py-2">
          <div className="w-1/3">
            <Combobox as="div" value={selectedCode} onChange={setSelectedCode}>
              <div className="relative">
                <Combobox.Input
                  className="w-full rounded-md bg-white pr-4 text-gray-900 text-xs shadow-sm border border-gray-200"
                  onChange={event => setQuery(event.target.value)}
                  displayValue={code => code}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md">
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Combobox.Button>

                {filteredCode.length > 0 && (
                  <Combobox.Options className="absolute z-10 mt-1 max-h-20 w-full overflow-auto rounded-md bg-white py-1 text-xs scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent scrollbar-thumb-rounded-full">
                    {filteredCode.map(code => (
                      <Combobox.Option
                        key={code}
                        value={code}
                        className={({active}) =>
                          classNames(
                            'relative cursor-default select-none py-2 text-[9px] px-3',
                            active
                              ? 'bg-gray-50 text-primary cursor-pointer'
                              : 'text-gray-900',
                          )
                        }>
                        {({active, selected}) => (
                          <>
                            <span
                              className={classNames(
                                'block truncate text-xs',
                                selected && 'font-semibold',
                              )}>
                              {code}
                            </span>
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}
              </div>
            </Combobox>
          </div>
          <div className="w-1/2">
            <input
              id="search-field"
              className="cust-input-field w-full"
              placeholder={'Enter Code'}
              type="search"
              name="search"
              value={code}
              onChange={e => {
                setCode(e.target.value);
              }}
            />
          </div>
          <div className="items-center flex">
            <button
              className="text-primary ml-2 text-xs"
              onClick={() => {
                applyFilter();
              }}>
              {LocaleStrings.search.apply}
            </button>
          </div>
        </div>
        {selectedValues.length > 0 ? (
          <div className="justify-start pt-2 items-center gap-2 inline-flex">
            <div className="justify-start items-center gap-1.5 flex flex-wrap">
              {selectedValues.map((val, index) => (
                <Chip
                  key={`${index}`}
                  title={`${val.type} - ${val.code}`}
                  action="include"
                  onRemove={() => {
                    const filtered = selectedValues.filter(
                      ({type, code}) =>
                        !(val.type === type && val.code === code),
                    );

                    setSelectedValues(filtered);
                  }}
                />
              ))}
            </div>
          </div>
        ) : null}
      </fieldset>
    </div>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  return {
    session,
    user,
    savedFilters,
    industries: state.preloadedPeopleFilterSupportData.industries,
  };
}

export default connect(mapStateToProps, {saveFilter})(Industry);
