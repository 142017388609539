import {useEffect, useState} from 'react';
import {searchTypes} from '../../../../constants';
import LocaleStrings from '@language';
import {connect} from 'react-redux';
import {saveFilter} from '../../../../actions';

function SearchType(props) {
  const [selectedValues, setSelectedValues] = useState([]);

  const updateFilterReducer = vals => {
    let filters = props.savedFilters;
    let filterObject = filters;
    filterObject['searchtype'] = vals;
    filters = {...filters, ...filterObject};
    props.saveFilter(props.session, filters, () => {});
  };

  useEffect(() => {
    updateFilterReducer(selectedValues.map(val => ({title: val, value: val})));
  }, [selectedValues]);

  useEffect(() => {
    let filters = props.savedFilters;
    let filterObject = filters;
    if (
      filterObject['searchtype'].length === 0 &&
      selectedValues.length !== 0
    ) {
      setSelectedValues([]);
    }
  }, [props.savedFilters]);
  return (
    <fieldset>
      <legend className="text-xs leading-6 text-gray-900 pt-2">
        {LocaleStrings.search.peoplesearch.search_title}
      </legend>
      <div>
        {searchTypes.map(({title, type}, index) => (
          <div key={type} className="group relative flex items-start py-1">
            <div className="mr-2 flex h-6 items-center">
              <input
                id={`searchtype-${type}`}
                name={`searchtype-${type}`}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-primary focus:primary"
                onChange={e =>
                  setSelectedValues(
                    e.target.checked
                      ? [...selectedValues, type]
                      : selectedValues.filter(p => p !== type),
                  )
                }
                checked={selectedValues.includes(type)}
              />
            </div>
            <div className="min-w-0 flex-1 text-sm leading-6">
              <label
                htmlFor={`searchtype-${type}`}
                className="select-none text-xs text-gray-900 group-hover:text-primary">
                {title}
              </label>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  return {
    session,
    user,
    savedFilters,
  };
}

export default connect(mapStateToProps, {saveFilter})(SearchType);
