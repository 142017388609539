import React from 'react';
import {connect} from 'react-redux';
import {Field, formValues, reduxForm, reset, getFormValues} from 'redux-form';
import LocaleStrings from '@language';
import _ from 'lodash';
import toast, {Toaster} from 'react-hot-toast';
import {classNames} from '@commonFunction';
import {Fragment} from 'react';
import {Transition, Dialog} from '@headlessui/react';
import {BaseComponent, LABEL_POSITION_TOP} from '@baseComponent';
import IconApi from '../../api/components/icons/api';
import {
  webHookModal,
  addHookUrl,
  fetchCRMDetails,
} from '../../../../search/actions';

class Addhookurl extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isBtnDisable: false,
      btnLoader: false,
    };
    this.closeModal = this.closeModal.bind(this);

    this.formRef = React.createRef(null);
  }

  componentDidMount() {}

  closeModal() {
    this.props.webHookModal(false);
  }

  onFormSubmit(values) {}

  handleFormSubmit = values => {
    this.setState({isBtnDisable: true});
    this.setState({btnLoader: true});
    values.clientid = this.props.user.clientid;
    console.log('values', values);
    this.props.addHookUrl(this.props.session, values, callback => {
      console.log('callback', callback);
      if (callback.success && callback.success == 1) {
        this.props.fetchCRMDetails(this.props.session, response => {
          toast.success(LocaleStrings.settings_crm_webhookurl_success);
          this.setState({isBtnDisable: false});
          this.setState({btnLoader: false});
          this.closeModal();
        });
      }
    });
  };

  render() {
    let {handleSubmit} = this.props;
    return (
      <>
        <Transition.Root show={this.props.iswebHookModalOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.iswebHookModalOpen}
            onClose={this.closeModal}
            initialFocus={this.myRef}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block rounded-xl align-bottom  bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-2/5"
                  style={{padding: 10}}>
                  <div className="p-6 border-t-2 border-quaternary">
                    <div className="flex items-center gap-4">
                      <div className="w-10 h-10 p-2 bg-secondary rounded-full justify-center items-center">
                        <div class="justify-center items-center flex">
                          <IconApi />
                        </div>
                      </div>
                      <div>
                        <div className="text-sm mainGray">
                          {LocaleStrings.settings_crm_webhook_title}
                        </div>
                        <div className="text-xs text-gray500 font-normal">
                          {LocaleStrings.settings_crm_webhook_subtitle}
                        </div>
                      </div>
                    </div>

                    <div className="mt-10">
                      <form onSubmit={handleSubmit(this.handleFormSubmit)}>
                        <div className="pb-1">
                          <Field
                            name="hookurl"
                            label={
                              LocaleStrings.settings_crm_webhookurl_label_keyname
                            }
                            placeholder={
                              LocaleStrings.settings_crm_webhookurl_label_keyname
                            }
                            type="text"
                            component={this.renderFieldText}
                            mandatory="true"
                            labelposition={LABEL_POSITION_TOP}
                            classNameLabel="text-sm font-normal text-gray700"
                          />
                        </div>
                        {/* <div className="pb-6">
                          Go to{' '}
                          <a
                            href="https://zapier.com/"
                            target="_blank"
                            className="hover:bg-primaryHover text-primary">
                            {LocaleStrings.setting_crm_tab3}
                          </a>
                        </div> */}
                        <div className="flex mt-8">
                          <button
                            type="button"
                            onClick={this.closeModal}
                            className="w-full justify-center flex px-4 py-2 text-sm font-medium border border-gray-200 rounded-lg hover:bg-gray-100 text-black hover:cursor-pointer">
                            {LocaleStrings.cancel}
                          </button>
                          {/* Footer */}
                          <button
                            type="submit"
                            disabled={this.state.isBtnDisable}
                            className={classNames(
                              this.state.isBtnDisable
                                ? 'cursor-not-allowed'
                                : '',
                              'btn-primary ml-3',
                            )}>
                            <svg
                              className={classNames(
                                this.state.btnLoader ? '' : 'hidden',
                                'animate-spin-medium h-5 w-5 rounded-full mx-2',
                              )}
                              disabled={this.state.isBtnDisable}
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true">
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                            {LocaleStrings.save}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  var requiredFields = ['hookurl'];

  const urlPattern = new RegExp(
    '^(https?:\\/\\/)' + // protocol
      '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // domain name
      'localhost|' + // OR localhost
      '\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|' + // OR IPv4
      '\\[([a-fA-F\\d:]+)\\])' + // OR IPv6
      '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-zA-Z\\d_]*)?$', // fragment locator
    'i',
  );
  if (!values.hookurl || _.trim(values.hookurl) === '') {
    errors.hookurl = LocaleStrings.required;
  } else if (!urlPattern.test(values.hookurl)) {
    errors.hookurl = LocaleStrings.settings_crm_invalid_url;
  }
  return errors;
}

function mapStateToProps(state) {
  return {
    session: state.session,
    user: state.user,
    isLoggedIn: state.isLoggedIn,
    iswebHookModalOpen: state.iswebHookModalOpen,
    crmDetails: state.crmDetails,
  };
}

export default connect(mapStateToProps, {
  webHookModal,
  addHookUrl,
  fetchCRMDetails,
})(
  reduxForm({
    validate,
    form: 'hookurladd',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Addhookurl),
);
