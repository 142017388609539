import React, {useEffect, useState} from 'react';
import IconPlus from '@assets/images/searchmodule/filter/plus.svg';
import IconMinus from '@assets/images/searchmodule/filter/minus.svg';
import Chip from './Chip';
import {classNames} from '@commonFunction';

function Accordion(props) {
  const {
    title,
    icon,
    content,
    type,
    filtersCount,
    onClear,
    savedFilters,
    minimized,
    collapsed,
  } = props;
  const [expanded, setExpanded] = useState(!collapsed);

  const toggleExpanded = toggled => {
    if (toggled) {
      props.onOpenAccordian({title, type, icon, collapsed});
    } else {
      props.onCloseAccordian();
    }
  };
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (expanded === collapsed) {
      setExpanded(!collapsed);
    }
  }, [collapsed]);

  const parts = window.location.href.split('/');
  let answer = '';
  if (parts.length > 1) {
    answer = parts[parts.length - 1];
  }
  const toModifyOverflow =
    type === 'foundedyear' || type === 'revenue' || type === 'industry';
  return (
    <div
      className={classNames(
        'py-3 hover:cursor-pointer pl-2',
        expanded ? 'bg-[#F7F8FF]' : '',
      )}
      onClick={() => {
        if (!expanded) toggleExpanded(!expanded);
        // setOpen(true);
      }}>
      <div
        className={classNames(
          'px-2 text-left items-center h-6 select-none flex justify-between flex-row relative',
          expanded ? 'bg-[#F7F8FF]' : '',
        )}
        onClick={() => {
          toggleExpanded(false);
        }}>
        {minimized ? (
          <div className="text-left flex flex-row">
            <img className="absolute w-4 top-2" src={icon} />
          </div>
        ) : (
          <div className="text-left flex flex-row items-center mt-1">
            <img className="absolute w-4 left-2" src={icon} />
            <span className="absolute left-8 text-left text-xs text-gray-600 font-medium">
              {title}
            </span>
          </div>
        )}
        {filtersCount > 0 && minimized ? (
          <div
            className={classNames(
              'absolute bg-primary flex',
              answer === 'revealedcontacts' ? 'top-0 right-3' : 'top-0 right-0',
            )}
            style={{
              borderRadius: '50%',
              width: 12,
              height: 12,
            }}></div>
        ) : null}
        {!minimized ? (
          <div className="flex">
            {filtersCount > 0 ? (
              <div
                className="flex-none text-xs text-primary text-right mt-1"
                onClick={() => {
                  onClear(type);
                }}>{`Clear(${filtersCount})`}</div>
            ) : null}
            <div
              className="flex-none pl-2"
              onClick={() => {
                toggleExpanded(false);
                setTimeout(() => {
                  setOpen(false);
                }, 100);
              }}>
              {expanded ? (
                <img src={IconMinus} width={18} />
              ) : (
                <img src={IconPlus} width={18} />
              )}
            </div>
          </div>
        ) : null}
      </div>
      {!minimized ? (
        <div
          className={classNames(
            'px-2 pt-0 overflow-hidden',
            expanded ? 'max-h-[50rem] ease-in overflow-visible' : 'max-h-0',
            !open && toModifyOverflow ? 'overflow-hidden' : '',
          )}>
          <p className="text-left">{content}</p>
        </div>
      ) : null}
      {!expanded && !minimized && filtersCount > 0 ? (
        type === 'location' ? (
          <div className="flex flex-col">
            {savedFilters['contact'].length > 0 ? (
              <div className="justify-start px-4 pt-2 items-center gap-2 inline-flex">
                <div className="justify-start items-center gap-1.5 flex flex-wrap">
                  {savedFilters['contact'].map((val, index) => (
                    <Chip key={`${index}`} {...val} onRemove={() => {}} />
                  ))}
                </div>
              </div>
            ) : null}
            {savedFilters['contact'].length > 0 &&
            savedFilters['hq'].length > 0 ? (
              <hr className="mt-2 mx-4" />
            ) : null}
            {savedFilters['hq'].length > 0 ? (
              <div className="justify-start px-4 pt-2 items-center gap-2 inline-flex">
                <div className="justify-start items-center gap-1.5 flex flex-wrap">
                  {savedFilters['hq'].map((val, index) => (
                    <Chip key={`${index}`} {...val} onRemove={() => {}} />
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="justify-start px-4 pt-2 items-center gap-2 inline-flex">
            <div className="justify-start items-center gap-1.5 flex flex-wrap">
              {savedFilters.map((val, index) => (
                <Chip key={`${index}`} {...val} onRemove={() => {}} />
              ))}
            </div>
          </div>
        )
      ) : null}
    </div>
  );
}
export default Accordion;
