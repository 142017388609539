import React from 'react';
import {connect} from 'react-redux';
import {Field, formValues, reduxForm, reset, getFormValues} from 'redux-form';
import LocaleStrings from '@language';
import _ from 'lodash';
import toast, {Toaster} from 'react-hot-toast';
import {classNames} from '@commonFunction';
import {Fragment} from 'react';
import {Transition, Dialog} from '@headlessui/react';
import {BaseComponent, LABEL_POSITION_TOP} from '@baseComponent';
import {
  tryoutapiModal,
  fetchApikeys,
  createApi,
  callgetcontactApi,
} from '@settingsApiActions';
import {fetchAppuser} from '@sidebarActions';
import {TRIALPLANID} from '../../../../../../common/constants';
import IconApi from './icons/api';

class TryoutApi extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isBtnDisable: false,
      isBusinessBtnDisable: false,
      btnLoader: false,
      businessBtnLoader: false,
      isdirectdialBtnDisable: false,
      btndirectdialLoader: false,
      json: '',
      btncompanyLoader: false,
    };
    this.closeModal = this.closeModal.bind(this);

    this.formRef = React.createRef(null);
  }

  componentWillUnmount() {}

  closeModal() {
    this.setState({json: ''});
    this.props.reset();
    this.props.tryoutapiModal(false);
  }

  /***** Form Submit *****/
  /*onFormSubmit(values) {
    let {user, session} = this.props;
    if (values.apikey && values.linkedinurl) {
      this.setState({isBtnDisable: true});
      this.setState({btnLoader: true});
      values.type = 'emails';
      this.props.callgetcontactApi(session, values, callback => {
        if (callback.data && callback.data) {
          this.setState({isBtnDisable: false});
          this.setState({btnLoader: false});
          const json_string = JSON.stringify(callback.data, null, 2);
          const cleanedJsonString = json_string.replace(/\\/g, '');
          console.log(cleanedJsonString);
          this.setState({json: cleanedJsonString});
        }
      });
    } else {
      toast.error(LocaleStrings.common_error_fillallrequiredfields);
    }
  }*/

  onFetchPersonalEmails(e) {
    e.preventDefault();
    let {formValues} = this.props;
    if (
      formValues &&
      formValues.linkedinurl &&
      formValues.apikey &&
      formValues.linkedinurl != '' &&
      formValues.apikey != ''
    ) {
      this.submitForm(formValues, 'personalemails');
    } else {
      toast.error(LocaleStrings.common_error_fillallrequiredfields);
    }
  }

  onFetchBusinessEmails(e) {
    e.preventDefault();
    let {formValues} = this.props;
    if (
      formValues &&
      formValues.linkedinurl &&
      formValues.apikey &&
      formValues.linkedinurl != '' &&
      formValues.apikey != ''
    ) {
      this.submitForm(formValues, 'businessemails');
    } else {
      toast.error(LocaleStrings.common_error_fillallrequiredfields);
    }
  }

  submitForm(values, type) {
    let {user, session} = this.props;
    if (values.apikey && values.linkedinurl) {
      let linkedinurl = values.linkedinurl;

      const indexOfLinkedin = linkedinurl.indexOf('linkedin');

      if (indexOfLinkedin !== 0) {
        const newLinkedinurl = linkedinurl
          .substring(indexOfLinkedin, linkedinurl.length)
          .replace(/\/+$/, '');
        values.linkedinurl = newLinkedinurl;
      }

      if (type === 'personalemails') {
        this.setState({isBtnDisable: true, btnLoader: true});
      } else {
        this.setState({isBusinessBtnDisable: true, businessBtnLoader: true});
      }
      values.type = type;
      this.props.callgetcontactApi(session, values, callback => {
        if (callback.data && callback.data) {
          const json_string = JSON.stringify(callback.data, null, 2);
          const cleanedJsonString = json_string.replace(/\\/g, '');
          this.setState({json: cleanedJsonString});
        }
        if (type === 'personalemails') {
          this.setState({isBtnDisable: false, btnLoader: false});
        } else {
          this.setState({
            isBusinessBtnDisable: false,
            businessBtnLoader: false,
          });
        }
      });
    } else {
      toast.error(LocaleStrings.common_error_fillallrequiredfields);
    }
  }

  fetchDirectDials = e => {
    let {formValues, session} = this.props;
    if (
      formValues.linkedinurl &&
      formValues.apikey &&
      formValues.linkedinurl != '' &&
      formValues.apikey != ''
    ) {
      let linkedinurl = formValues.linkedinurl;
      const indexOfLinkedin = linkedinurl.indexOf('linkedin');

      if (indexOfLinkedin !== 0) {
        const newLinkedinurl = linkedinurl
          .substring(indexOfLinkedin, linkedinurl.length)
          .replace(/\/+$/, '');
        formValues.linkedinurl = newLinkedinurl;
      }
      this.setState({isdirectdialBtnDisable: true, btndirectdialLoader: true});
      formValues.type = 'directdials';
      this.props.callgetcontactApi(session, formValues, callback => {
        if (callback.data && callback.data) {
          const json_string = JSON.stringify(callback.data, null, 2);
          const cleanedJsonString = json_string.replace(/\\/g, '');
          console.log(cleanedJsonString);
          this.setState({json: cleanedJsonString});
        }

        this.setState({
          isdirectdialBtnDisable: false,
          btndirectdialLoader: false,
        });
      });
    } else {
      toast.error(LocaleStrings.common_error_fillallrequiredfields);
    }
  };

  fetchCompanydetails(e) {
    e.preventDefault();
    let {formValues, session, selectedTab} = this.props;
    if (selectedTab == 'people') {
      if (
        formValues &&
        formValues.apikey &&
        formValues.apikey != '' &&
        ((formValues.linkedinurl && formValues.linkedinurl != '') ||
          (formValues.name && formValues.name != '') ||
          (formValues.companyname && formValues.companyname != '') ||
          (formValues.domain && formValues.domain != ''))
      ) {
        let linkedinurl = formValues.linkedinurl;
        const indexOfLinkedin = linkedinurl
          ? linkedinurl.indexOf('linkedin')
          : -1;

        if (indexOfLinkedin > 0) {
          const newLinkedinurl = linkedinurl
            .substring(indexOfLinkedin, linkedinurl.length)
            .replace(/\/+$/, '');
          formValues.linkedinurl = newLinkedinurl;
        }
        this.setState({btncompanyLoader: true});
        formValues.tab = 'people';
        this.props.callgetcontactApi(session, formValues, callback => {
          if (callback.data && callback.data) {
            const json_string = JSON.stringify(callback.data, null, 2);
            const cleanedJsonString = json_string.replace(/\\/g, '');
            console.log(cleanedJsonString);
            this.setState({json: cleanedJsonString});
          }
          this.setState({
            btncompanyLoader: false,
          });
        });
      } else {
        toast.error(LocaleStrings.common_error_fillallrequiredfields);
      }
    } else {
      if (
        formValues &&
        formValues.apikey &&
        formValues.apikey != '' &&
        formValues.companyname &&
        formValues.companyname != ''
      ) {
        this.setState({btncompanyLoader: true});
        formValues.tab = 'company';
        this.props.callgetcontactApi(session, formValues, callback => {
          if (callback.data && callback.data) {
            const json_string = JSON.stringify(callback.data, null, 2);
            const cleanedJsonString = json_string.replace(/\\/g, '');
            console.log(cleanedJsonString);
            this.setState({json: cleanedJsonString});
          }
          this.setState({
            btncompanyLoader: false,
          });
        });
      } else {
        toast.error(LocaleStrings.common_error_fillallrequiredfields);
      }
    }
  }

  // updgrade plan page redirection
  _openUpgradPlan(e) {
    this.closeModal();
    this.props.router.navigate('/upgradeplan');
  }

  // addon page redirection
  _handleAddon(e) {
    this.closeModal();
    this.props.router.navigate('/creditaddons');
  }

  render() {
    let {handleSubmit, appuserData, selectedTab} = this.props;

    let contactcredits = 0;
    let business_emailcredits = 0;
    let personal_emailcredits = 0;

    let planid = '';
    let role = '';

    if (
      appuserData &&
      appuserData.data &&
      appuserData.data.length > 0 &&
      appuserData.data[0].client_by_clientid
    ) {
      role = appuserData.data[0].role;

      if (
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid
          .length > 0 &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid[0]
          ?.subscriptionplan_by_planid
      ) {
        let subscriptionplan_by_planid =
          appuserData.data[0]?.client_by_clientid
            ?.usersubscription_by_clientid[0]?.subscriptionplan_by_planid;
        planid = subscriptionplan_by_planid.planid;
      }

      if (
        appuserData &&
        appuserData.data &&
        appuserData.data.length > 0 &&
        appuserData.data[0].client_by_clientid
      ) {
        if (
          appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid &&
          appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid
            .length > 0
        ) {
          let usercredit_by_clientid =
            appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid[0];

          contactcredits = usercredit_by_clientid.contactcredit;
          business_emailcredits = usercredit_by_clientid.bus_email_credits;
          personal_emailcredits = usercredit_by_clientid.personal_email_credits;
        }
      }
    }

    return (
      <>
        <Transition.Root show={this.props.istryoutapimodalOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.istryoutapimodalOpen}
            onClose={this.closeModal}
            initialFocus={this.myRef}>
            <div
              className="flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0 content-center"
              style={{height: 'calc(100vh / var(--zoom))'}}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block rounded-xl align-bottom bg-custWhite px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-3/12 "
                  style={{padding: 0}}>
                  <form
                    // ref={this.formRef}
                    className=" "
                    // onSubmit={handleSubmit(this.onFormSubmit.bind(this))}
                  >
                    <div className="p-6 border-t-2 border-quaternary">
                      <div className="flex gap-4 pb-6">
                        <div className="w-10 h-10 p-2 bg-secondary rounded-full justify-center items-center">
                          <div class="justify-center items-center flex">
                            <IconApi />
                          </div>
                        </div>
                        <div>
                          <div className="text-base mainGray">
                            {LocaleStrings.settings_api_tryout}
                          </div>
                          <div className="text-xs text-gray500 font-normal">
                            {selectedTab == 'people'
                              ? LocaleStrings.settings_api_enterapikeyandlinkedinurl
                              : LocaleStrings.settings_api_enterapikeyandcompanyname}
                          </div>
                        </div>
                      </div>

                      <div className="pb-6">
                        <Field
                          name="apikey"
                          label={
                            LocaleStrings.settings_crtapi_modal_label_keyname
                          }
                          placeholder={
                            LocaleStrings.settings_crtapi_modal_placeholder_keyname
                          }
                          type="text"
                          component={this.renderFieldText}
                          mandatory="true"
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-sm font-normal text-gray700"
                        />
                      </div>

                      {selectedTab == 'people' ? (
                        <>
                          <div className="pb-6">
                            <Field
                              name="linkedinurl"
                              label={
                                LocaleStrings.settings_api_linkedinfield_label
                              }
                              placeholder={
                                LocaleStrings.settings_api_linkedinfield_label
                              }
                              type="text"
                              component={this.renderFieldText}
                              labelposition={LABEL_POSITION_TOP}
                              classNameLabel="text-sm font-normal text-gray700"
                            />
                          </div>
                          <div className="pb-6">
                            <Field
                              name="name"
                              label={LocaleStrings.settings_api_namefield_label}
                              placeholder={
                                LocaleStrings.settings_api_namefield_label
                              }
                              type="text"
                              component={this.renderFieldText}
                              labelposition={LABEL_POSITION_TOP}
                              classNameLabel="text-sm font-normal text-gray700"
                            />
                          </div>
                          <div className="pb-6">
                            <Field
                              name="companyname"
                              label={
                                LocaleStrings.settings_api_companyfield_label
                              }
                              placeholder={
                                LocaleStrings.settings_api_companyfield_label
                              }
                              type="text"
                              component={this.renderFieldText}
                              labelposition={LABEL_POSITION_TOP}
                              classNameLabel="text-sm font-normal text-gray700"
                            />
                          </div>
                          <div className="pb-6">
                            <Field
                              name="domain"
                              label={LocaleStrings.settings_api_domain_label}
                              placeholder={
                                LocaleStrings.settings_api_domain_label
                              }
                              type="text"
                              component={this.renderFieldText}
                              labelposition={LABEL_POSITION_TOP}
                              classNameLabel="text-sm font-normal text-gray700"
                            />
                          </div>
                        </>
                      ) : (
                        <div className="pb-6">
                          <Field
                            name="companyname"
                            label={
                              LocaleStrings.settings_api_companyfield_label
                            }
                            placeholder={
                              LocaleStrings.settings_api_companyfield_label
                            }
                            type="text"
                            component={this.renderFieldText}
                            labelposition={LABEL_POSITION_TOP}
                            classNameLabel="text-sm font-normal text-gray700"
                          />
                        </div>
                      )}
                      {this.state.json !== '' ? (
                        <div className="bg-black p-6 text-white border-rounded mb-4 h-60 overflow-auto">
                          <pre>{this.state.json}</pre>
                        </div>
                      ) : (
                        ''
                      )}

                      {/* <div className="flex gap-2">
                        {personal_emailcredits > 0 ? (
                          <button
                            onClick={e => this.onFetchPersonalEmails(e)}
                            disabled={this.state.isBtnDisable}
                            className={classNames(
                              this.state.isBtnDisable
                                ? 'cursor-not-allowed'
                                : '',
                              'btn-primary',
                            )}>
                            <svg
                              className={classNames(
                                this.state.btnLoader ? '' : 'hidden',
                                'animate-spin-medium h-5 w-5 rounded-full mx-2',
                              )}
                              disabled={this.state.isBtnDisable}
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true">
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                            {LocaleStrings.settings_api_fetchpersonalemailsbtn}
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn-primary xs:mt-4 md:mt-0 items-center"
                            onClick={e => this._openUpgradPlan(e)}>
                            {'Upgrade (Personal Emails)'}
                          </button>
                        )}
                        {business_emailcredits > 0 ? (
                          <button
                            onClick={e => this.onFetchBusinessEmails(e)}
                            disabled={this.state.isBusinessBtnDisable}
                            className={classNames(
                              this.state.isBusinessBtnDisable
                                ? 'cursor-not-allowed'
                                : '',
                              'btn-primary ml-2',
                            )}>
                            <svg
                              className={classNames(
                                this.state.businessBtnLoader ? '' : 'hidden',
                                'animate-spin-medium h-5 w-5 rounded-full mx-2',
                              )}
                              disabled={this.state.isBusinessBtnDisable}
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true">
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                            {LocaleStrings.settings_api_fetchbusinessemailsbtn}
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn-primary ml-2 xs:mt-4 md:mt-0  items-center"
                            onClick={e => this._openUpgradPlan(e)}>
                            {'Upgrade (Business Emails)'}
                          </button>
                        )}
                        {contactcredits > 0 ? (
                          <button
                            type="button"
                            disabled={this.state.isdirectdialBtnDisable}
                            onClick={e => this.fetchDirectDials(e)}
                            className={classNames(
                              this.state.isdirectdialBtnDisable
                                ? 'cursor-not-allowed opacity-20'
                                : '',
                              'btn-primary',
                            )}>
                            <svg
                              className={classNames(
                                this.state.btndirectdialLoader ? '' : 'hidden',
                                'animate-spin-medium h-5 w-5 rounded-full mx-2',
                              )}
                              disabled={this.state.isdirectdialBtnDisable}
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true">
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                            {LocaleStrings.settings_api_directdialsbtn}
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn-primary xs:mt-4 md:mt-0  items-center"
                            onClick={e => {
                              if (role === 'client' && planid != TRIALPLANID)
                                this._handleAddon(e);
                              else this._openUpgradPlan(e);
                            }}>
                            {role === 'client' && planid != TRIALPLANID
                              ? 'Buy Addon'
                              : 'Upgrade (Phone Numbers)'}
                          </button>
                        )}
                      </div> */}
                      <div className="mt-5 flex gap-4 justify-between">
                        <button
                          type="button"
                          onClick={this.closeModal}
                          className=" w-1/2 px-4 py-2 text-sm font-medium border border-gray-200 rounded-lg hover:bg-gray-100 text-black hover:cursor-pointer">
                          {LocaleStrings.cancel}
                        </button>
                        <button
                          disabled={this.state.btncompanyLoader}
                          onClick={e => this.fetchCompanydetails(e)}
                          className={classNames(
                            this.state.btncompanyLoader
                              ? 'cursor-not-allowed opacity-20'
                              : '',
                            'btn-primary',
                          )}
                          style={{width: '50%'}}>
                          <svg
                            className={classNames(
                              this.state.btncompanyLoader ? '' : 'hidden',
                              'animate-spin-medium h-5 w-5 rounded-full mx-2',
                            )}
                            disabled={this.state.btncompanyLoader}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true">
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                          {LocaleStrings.settings_api_fetch}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  var requiredFields = ['apikey'];
  requiredFields.forEach(field => {
    if (!values[field] || _.trim(values[field]) === '') {
      errors[field] = LocaleStrings.required;
    }
  });
  return errors;
}

function mapStateToProps(state, ownProps) {
  var {session, user} = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    istryoutapimodalOpen: state.istryoutapimodalOpen,
    formValues: getFormValues('TryoutApiForm')(state),
    appuserData: state.appuserData,
  };
}

export default connect(mapStateToProps, {
  tryoutapiModal,
  fetchApikeys,
  createApi,
  fetchAppuser,
  callgetcontactApi,
})(
  reduxForm({
    validate,
    form: 'TryoutApiForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(TryoutApi),
);
