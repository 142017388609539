import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {connect} from 'react-redux';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {classNames} from '@commonFunction';
import _ from 'lodash';
import {confirmAlert} from 'react-confirm-alert';
import {
  ZOHO_CLIENTID,
  ZOHO_URL,
  SALESFORCE_CLIENTID,
  SALESFORCE_URL,
} from '@constant';
import IconArrowLeft from '../../search/results/cells/icons/arrowleft';
import IconArrowRight from '../../search/results/cells/icons/arrowright';
import IconCardView from '../../search/results/cells/icons/cardview';
import IconListView from '../../search/results/cells/icons/listview';
import ListTable from './ListTable';
import CardTable from './CardTable';
import {FilterIcon} from '@heroicons/react/solid';
import {ChevronDownIcon} from '@heroicons/react/solid';
import PaginationBar from '../../search/results/cells/components/paginationbar';
import {
  filterRevealedContact,
  addCRMLead,
  saveSelectedDPeople,
  fetchCRMDetails,
  saveCRMType,
  postEnrich,
  zapierHook,
} from '../../search/actions';
import IconGroupFav from '../../search/results/cells/icons/groupfav';
import IconGroupAddToList from '../../search/results/cells/icons/groupaddtolist';
import AddToList from '../../search/results/cells/components/addtolist';
import IconGroupEnrich from '../../search/results/cells/icons/groupenrich';
import IconExport from '../../search/results/cells/icons/export';
import NoResult from '../../../../common/noresult';
import SkeletonLoader from '../../../../common/skeletonloader';
import toast, {Toaster} from 'react-hot-toast';
import LocaleStrings from '@language';
import ExportMenu from '../../../../common/exportmenu';
import {ExportMenuTypes} from '../../../../common/exportmenu/menudropdown';
import ProcessingIndicator from '../../../../common/processingindicator';

const maxRowPerPage = 25;

function ContactsTable(props) {
  const location = useLocation();

  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPersons, setSelectedPersons] = useState([]);

  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  const [cardView, setCardView] = useState(false);

  const [showList, setShowList] = useState(false);

  const [curPage, setCurPage] = useState(1);
  const [rowsPerPage] = useState(maxRowPerPage);

  const [isLoading, setIsLoading] = useState(false);
  const [isPaginating, setIsPaginating] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const [processingExport, setProcessingExport] = useState(false);

  const navigate = useNavigate();

  const applyFilter = resetPage => {
    const page = resetPage ? 1 : curPage;
    props.filterRevealedContact(
      props.session,
      page,
      rowsPerPage,
      sortBy,
      sortDirection,
      props.savedFilters,
      props.appuserData,
      resp => {
        if (resp.success === 1) {
        }
        if (resetPage) {
          setCurPage(1);
          setTimeout(() => {
            setIsLoading(false);
            setIsPaginating(false);
            setFirstLoad(false);
          }, 200);
        } else {
          setIsLoading(false);
          setIsPaginating(false);
          setFirstLoad(false);
        }
      },
    );
  };

  useEffect(() => {
    if (isPaginating) {
      applyFilter(false);
    }
  }, [curPage]);

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      setFirstLoad(true);
      applyFilter(true);
    }
  }, [props.savedFilters]);

  useEffect(() => {
    props.fetchCRMDetails(props.session, resp => {});
  }, []);

  useLayoutEffect(() => {
    if (props.filteredPeople && props.filteredPeople.length > 0) {
      const isIndeterminate =
        selectedPersons.length > 0 &&
        selectedPersons.length !== props.filteredPeople.length;
      setChecked(
        selectedPersons.length < maxRowPerPage
          ? selectedPersons.length === props.filteredPeople.length
          : selectedPersons.length === maxRowPerPage,
      );
      setIndeterminate(isIndeterminate);
      if (checkbox.current) checkbox.current.indeterminate = isIndeterminate;
    } else {
      setChecked(false);
    }
  }, [selectedPersons, props.filteredPeople]);

  function toggleAll() {
    // if (props.showTechnologyBlock) return;
    setSelectedPersons(checked || indeterminate ? [] : props.filteredPeople);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const renderResultText = () => {
    const startIndex = (curPage - 1) * maxRowPerPage;
    let endIndex = (curPage - 1) * maxRowPerPage + maxRowPerPage;

    if (props.totalcount < maxRowPerPage) {
      endIndex = props.totalcount;
    }

    if (endIndex > props.totalcount) {
      endIndex = props.totalcount;
    }

    // && !props.showTechnologyBlock
    if (props.totalcount > 0)
      return `${startIndex + 1} - ${endIndex} of ${props.totalcount} contacts`;

    return '0 contacts';
  };

  const connectToZoho = () => {
    setProcessingExport(true);
    let crmDetails = props.crmDetails;

    if (crmDetails.zoho) {
      let peopleIdArr = [];
      _.map(selectedPersons, user => {
        let obj = {};
        obj.peopleid = user.peopleid;
        peopleIdArr.push(obj);
      });
      props.addCRMLead(
        props.session,
        {
          peopleids: peopleIdArr,
          lead_source: 'Kipplo Reveal Contact',
        },
        'zoholead',
        resp => {
          console.log('resp', resp);
          setProcessingExport(false);
          if (resp.success === 1) {
            props.saveSelectedDPeople(props.session, []);

            toast.success(LocaleStrings.zoho_success);
          } else {
            toast.error(LocaleStrings.common_fail_message);
          }
        },
      );
    } else {
      // let {pathname} = location;
      // const redirectUri = window.location.origin + pathname;
      // //const scope = 'ZohoCRM.modules.leads.CREATE';
      // const scope = 'ZohoCRM.modules.leads.ALL ZohoSearch.securesearch.READ';
      // props.saveSelectedDPeople(props.session, selectedPersons);
      // props.saveCRMType(props.session, 'zoho');
      // const authUrl = `${ZOHO_URL}?scope=${scope}&client_id=${ZOHO_CLIENTID}&response_type=code&redirect_uri=${redirectUri}&access_type=offline`;
      // window.location = authUrl;

      navigate('/settings/crm');
    }
  };

  const connectToSalesForce = () => {
    setProcessingExport(true);
    let crmDetails = props.crmDetails;
    if (crmDetails.salesforce) {
      let peopleIdArr = [];
      _.map(selectedPersons, user => {
        let obj = {};
        obj.peopleid = user.peopleid;
        peopleIdArr.push(obj);
      });
      props.addCRMLead(
        props.session,
        {
          peopleids: peopleIdArr,
          lead_source: 'Kipplo Reveal Contact',
        },
        'salesforce',
        resp => {
          console.log('resp', resp);
          setProcessingExport(false);
          if (resp.success === 1) {
            props.saveSelectedDPeople(props.session, []);
            toast.success(LocaleStrings.salesforce_success);
          } else {
            toast.error(LocaleStrings.common_fail_message);
          }
        },
      );
    } else {
      navigate('/settings/crm');
      // let {pathname} = location;
      // const redirectUri = window.location.origin + pathname;
      // props.saveSelectedDPeople(props.session, selectedPersons);
      // props.saveCRMType(props.session, 'salesforce');
      // const authUrl = `${SALESFORCE_URL}?client_id=${SALESFORCE_CLIENTID}&grant_type=authorization_code&response_type=code&redirect_uri=${redirectUri}`;
      // //console.log('selectedPersons', selectedPersons);
      // window.location = authUrl;
    }
  };

  const exportToCSV = () => {
    if (selectedPersons.length > 0) {
      setProcessingExport(true);
      let JSONData = {};
      JSONData['revealedcontacts'] = selectedPersons;
      JSONToCSVConvertor(JSONData);
      setProcessingExport(false);
    } else {
      toast.error('Please Select Contacts to Export');
    }
  };
  // called from exportBills for downloading report
  const JSONToCSVConvertor = JSONData => {
    setProcessingExport(true);
    var XLSX = require('xlsx');
    let wb = XLSX.utils.book_new();

    let arrjsonn = [];
    _.forEach(JSONData.revealedcontacts, function (person) {
      const {
        contactname,
        jobtitle,
        company,
        contactdetails,
        linkedin,
        contacttag,
      } = person;

      // console.log("value", value);

      const personalEmails = contactdetails.filter(
        contact => contact.type === 'personalemail' && !contact.encrypted,
      );
      const businessEmails = contactdetails.filter(
        contact => contact.type === 'businessemail' && !contact.encrypted,
      );

      const directDials = contactdetails.filter(
        contact => contact.type === 'directdial' && !contact.encrypted,
      );

      let personalemailcomaseperated = personalEmails
        .map(contact => contact.values)
        .join(',');
      let businessemailcomaseperated = businessEmails
        .map(contact => contact.values)
        .join(',');

      let phonenumbersomaseperated = directDials
        .map(contact => contact.values)
        .join(',');

      var obj = {
        ContactName: contactname,
        JobTitle: jobtitle,
        Company: company.title,
        Linkedin_url: linkedin,
        Label: contacttag ? contacttag.title : 'Not assigned',
        Personal_Emails: personalemailcomaseperated,
        Business_Emails: businessemailcomaseperated,
        Cell_numbers: phonenumbersomaseperated,
      };

      arrjsonn.push(obj);
    });

    let JSONDataOBJ = {
      revealedcontacts: arrjsonn,
    };
    _.map(JSONDataOBJ, (item, index) => {
      /* Create a worksheet */
      var ws = XLSX.utils.json_to_sheet(item);
      // // var ws1 = XLSX.utils.json_to_sheet(JSONData['F2(149426)']);

      var sheetName = index.replace(/[\/\\:\[\]*?]/g, '_'); // Sheet name cannot contain ': \ / ? * [ ]' so replacing with '_' and also can not contain more than 31 chars
      if (sheetName.length > 30) {
        sheetName = sheetName.substr(0, 28) + '...';
      }

      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      // XLSX.utils.book_append_sheet(wb, ws1, "F2(149426)");
    });
    XLSX.writeFile(wb, 'revealedcontacts.csv');

    setProcessingExport(false);
  };

  const connectToZapier = () => {
    setProcessingExport(true);
    let crmDetails = props.crmDetails;
    if (crmDetails.zapier) {
      let peopleIdArr = [];
      _.map(selectedPersons, user => {
        let obj = {};
        obj.peopleid = user.peopleid;
        peopleIdArr.push(obj);
      });
      props.zapierHook(
        props.session,
        {
          peopleids: peopleIdArr,
          lead_source: 'Kipplo Reveal Contact',
        },
        resp => {
          console.log('resp', resp);
          setProcessingExport(false);
          if (resp.success === 1) {
            toast.success(LocaleStrings.zapier_success);
          } else {
            toast.error(LocaleStrings.common_fail_message);
          }
        },
      );
    } else {
      navigate('/settings/crm');
    }
  };

  const onEnrichClicked = () => {
    if (selectedPersons.length > 0) {
      let totalCredits = 0;
      let fieldArr = [];
      let paramsArr = [];
      _.map(selectedPersons, (item, index) => {
        let obj = {};
        obj.contactid = item.contactid;
        obj.peopleid = item.peopleid;
        paramsArr.push(obj);
        if (item.new_business_email_data_available == 1) {
          totalCredits++;
          if (!fieldArr.includes(LocaleStrings.enrich.work_email)) {
            fieldArr.push(LocaleStrings.enrich.work_email);
          }
        }
        if (item.new_personal_email_data_available == 1) {
          totalCredits++;
          if (!fieldArr.includes(LocaleStrings.enrich.secondary_email)) {
            fieldArr.push(LocaleStrings.enrich.secondary_email);
          }
        }
        if (item.new_phone_number_data_available == 1) {
          totalCredits++;
          if (!fieldArr.includes(LocaleStrings.enrich.phone_no)) {
            fieldArr.push(LocaleStrings.enrich.phone_no);
          }
        }
        if (
          item.new_company_data_available == 1 &&
          !fieldArr.includes(LocaleStrings.enrich.companyname)
        ) {
          fieldArr.push(LocaleStrings.enrich.companyname);
        }
        if (
          item.new_designation_data_available == 1 &&
          !fieldArr.includes(LocaleStrings.enrich.designation)
        ) {
          fieldArr.push(LocaleStrings.enrich.designation);
        }
      });

      // confirmAlert({
      //   title: LocaleStrings.enrich.title,
      //   message: `${LocaleStrings.enrich.subtitle} \n ${fieldArr.join(
      //     ',',
      //   )} \n ${LocaleStrings.enrich.subsubtitle}  ${totalCredits} ${
      //     LocaleStrings.enrich.credits
      //   }`,
      //   buttons: [
      //     {
      //       label: LocaleStrings.enrich.enrich,
      //       onClick: () => {},
      //     },
      //     {
      //       label: LocaleStrings.enrich.cancel,
      //       onClick: () => {},
      //     },
      //   ],
      // });

      // console.log('paramsArr', paramsArr);

      confirmAlert({
        customUI: ({onClose}) => {
          return (
            <div className="react-confirm-alert-body">
              <h1>{LocaleStrings.enrich.title}</h1>
              <p>{LocaleStrings.enrich.subtitle}</p>
              <ul>
                {_.map(fieldArr, (item, index) => {
                  return <li>{item}</li>;
                })}
              </ul>
              <p>{`${LocaleStrings.enrich.subsubtitle} ${totalCredits} ${LocaleStrings.enrich.credits}`}</p>
              <div class="react-confirm-alert-button-group">
                <button
                  onClick={() => {
                    props.postEnrich(props.session, paramsArr, response => {
                      onClose();
                      if (response.success === 1) {
                        applyFilter(true);
                        toast.success(LocaleStrings.enrich.success);
                      } else {
                        toast.error(LocaleStrings.common_fail_message);
                      }
                    });
                  }}>
                  {LocaleStrings.enrich.enrich}
                </button>
                <button onClick={onClose}>{LocaleStrings.enrich.cancel}</button>
              </div>
            </div>
          );
        },
      });
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {props.totalcount > 0 && !isLoading ? (
        <div className="mt-2 flow-root">
          <div className="flex flex-grow">
            <div className="flex -mx-4 -my-2 mb-6 relative items-center grow shrink basis-0 self-stretch">
              {!processingExport ? (
                <div>
                  <input
                    type="checkbox"
                    className="rounded border-gray-500 text-primary focus:ring-primary hover:cursor-pointer hover:border-primary hover:border-2 hover:bg-gray-100"
                    ref={checkbox}
                    checked={checked}
                    onChange={toggleAll}
                  />
                </div>
              ) : null}
              {!processingExport ? (
                <div className="ml-4 mt-0.5 text-xs font-semibold">
                  {renderResultText()}
                </div>
              ) : null}
              {!processingExport ? (
                <div
                  className={classNames(
                    'transition ease-in duration-300 absolute left-5 -top-3 flex h-12 items-center space-x-3 bg-white sm:left-5',
                    selectedPersons.length > 0 ? 'visible' : 'invisible',
                  )}>
                  <div className="ml-2 mt-1 text-sm font-semibold">
                    {`${selectedPersons.length} selected`}
                  </div>
                  <div className="z-10">
                    <span className="isolate inline-flex rounded-md shadow-sm">
                      <button
                        type="button"
                        className="relative -ml-px inline-flex rounded-md text-xs items-center bg-white px-2 py-2 text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                        onClick={() => onEnrichClicked()}>
                        <IconGroupEnrich />
                        &nbsp;Enrich&nbsp;
                        <ChevronDownIcon
                          className="h-4 w-4 text-gray-400"
                          aria-hidden="true"
                        />
                      </button>
                    </span>
                  </div>
                  <div className="flex inline gap-2">
                    <ExportMenu
                      {...props.crmDetails}
                      onSelectedMenu={menuType => {
                        if (menuType === ExportMenuTypes.CSV) {
                          exportToCSV();
                        } else if (menuType === ExportMenuTypes.ZOHO) {
                          connectToZoho();
                        } else if (menuType === ExportMenuTypes.SALESFORCE) {
                          connectToSalesForce();
                        } else if (menuType === ExportMenuTypes.ZAPIER) {
                          connectToZapier();
                        }
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className={classNames(
                    'transition ease-in duration-300 absolute left-5 -top-3 flex h-12 items-center space-x-3 bg-white sm:left-5',
                  )}>
                  <ProcessingIndicator message={'Processing Export'} />
                </div>
              )}
            </div>
            <div className="flex -mx-4 -my-5 mb-4 relative gap-6 items-center">
              <div
                className={classNames(
                  'w-22 h-8 rounded-lg shadow border border-gray-300 justify-start items-start inline-flex overflow-hidden',
                  'opacity-100',
                )}>
                <div
                  class="px-3 py-2 bg-white border-r border-gray-300 justify-center items-center gap-2 flex hover:bg-gray-100 hover:cursor-pointer"
                  onClick={() => {
                    if (curPage > 1) {
                      setCurPage(curPage - 1);
                      setIsPaginating(true);
                    }
                  }}>
                  <div class="w-5 h-4 relative rounded-lg">
                    <IconArrowLeft />
                  </div>
                </div>
                <div
                  class="px-3 py-2 bg-white justify-center items-center gap-2 flex hover:bg-gray-100 hover:cursor-pointer"
                  onClick={() => {
                    if (curPage < props.numPages) {
                      setCurPage(curPage + 1);
                      setIsPaginating(true);
                    }
                  }}>
                  <div class="w-5 h-4 relative rounded-lg">
                    <IconArrowRight />
                  </div>
                </div>
              </div>

              <div className="flex gap-2">
                <div
                  class={classNames(
                    'w-10 h-10 p-2.5 rounded-lg justify-center items-center gap-2 inline-flex hover:cursor-pointer',
                    cardView ? 'bg-slate-100' : 'bg-white',
                  )}
                  onClick={() => {
                    setCardView(true);
                  }}>
                  <div class="w-5 h-5 relative">
                    <IconCardView />
                  </div>
                </div>

                <div
                  class={classNames(
                    'w-10 h-10 p-2.5 rounded-lg justify-center items-center gap-2 inline-flex hover:cursor-pointer',
                    !cardView ? 'bg-slate-100' : 'bg-white',
                  )}
                  onClick={() => {
                    setCardView(false);
                  }}>
                  <div class="w-5 h-5 relative">
                    <IconListView />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {props.filteredPeople ? (
            <div
              className={classNames(
                '-my-2 overflow-x-auto -ml-16',
                cardView ? '-mr-16' : '-mr-20',
              )}>
              <div
                className="inline-block min-w-full py-2 align-middle pl-8 pr-4"
                style={{height: 'calc(100vh / var(--zoom) - 260px)'}}>
                {isPaginating ? (
                  <div className="px-5">
                    <SkeletonLoader pagination />
                  </div>
                ) : cardView ? (
                  <CardTable
                    calculatedHeight={props.calculatedHeight}
                    selectedPersons={selectedPersons}
                    onSelectPerson={(person, checked) => {
                      setSelectedPersons(
                        checked
                          ? [...selectedPersons, person]
                          : selectedPersons.filter(
                              p => p.peopleid !== person.peopleid,
                            ),
                      );
                    }}
                    applyFilter={applyFilter}
                  />
                ) : (
                  <ListTable
                    calculatedHeight={props.calculatedHeight}
                    selectedPersons={selectedPersons}
                    onSelectPerson={(person, checked) => {
                      setSelectedPersons(
                        checked
                          ? [...selectedPersons, person]
                          : selectedPersons.filter(
                              p => p.peopleid !== person.peopleid,
                            ),
                      );
                    }}
                    applyFilter={applyFilter}
                  />
                )}
              </div>
            </div>
          ) : null}
          {props.filteredPeople && (
            <div className="mt-4">
              <PaginationBar
                // disabled={props.showTechnologyBlock}
                curPage={curPage}
                numPages={props.numPages}
                onPageChange={curPage => {
                  if (!firstLoad) {
                    setIsPaginating(true);
                  }
                  setCurPage(curPage);
                }}
              />
            </div>
          )}
        </div>
      ) : isLoading && !isPaginating ? (
        <SkeletonLoader />
      ) : !isPaginating ? (
        <NoResult hasFilters />
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters, technologySearch, appuserData} = state;
  const {totalcount, pages, data} = state.filteredRevealedContacts;
  return {
    session,
    user,
    savedFilters,
    showTechnologyBlock: technologySearch.showTechnologyBlock,
    searchSelectedSubMenu: state.searchSelectedSubMenu,
    totalcount,
    numPages: pages,
    filteredPeople: data,
    appuserData,
    savedCRMData: state.savedCRMData,
    crmDetails: state.crmDetails,
    crmType: state.crmType,
  };
}

export default connect(mapStateToProps, {
  filterRevealedContact,
  addCRMLead,
  saveSelectedDPeople,
  fetchCRMDetails,
  saveCRMType,
  postEnrich,
  zapierHook,
})(ContactsTable);
