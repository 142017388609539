import {
  AVAILABLE_COMPANY_LISTS,
  AVAILABLE_FILTERS,
  AVAILABLE_LISTS,
  CLEAR_ALL_FILTERS,
  COMPANY_FAV_FILTER,
  COMPANY_FILTER,
  FILTERS,
  FILTER_TYPE_REVEALED_CONTACTS,
  PEOPLE_FAV_FILTER,
  PEOPLE_FILTER,
  PEOPLE_FILTER_SUPPORT_DATA,
  RECENT_SEARCHES,
  TECHNOLOGY_SEARCH,
  TOGGLE_FILTERS_SIDE_POPUP,
  TOGGLE_MINIMIZE_FILTERS,
  CRM_DETAILS,
  PEOPLE_DETAILS,
  COMPANY_DETAILS,
  MAP_FIELD_MODAL,
  ZOHO_FIELDS,
  SF_FIELDS,
  WEB_HOOK_MODAL,
} from '../actions';

export const defaultFilters = {
  contactname: [],
  companyname: [],
  location: {contact: [], hq: []},
  department: [],
  industry: [],
  industrycode: [],
  jobtitle: [],
  seniority: [],
  searchtype: [],
  headcount: [],
  revenue: [],
  technology: [],
  foundedyear: [],
  exclude: {
    revealedbusinessemails: false,
    revealedpersonalemails: false,
    revealedphonenumbers: false,
    revealedcontacts: false,
    revealedtech: false,
  },
};

export const filters = (state = defaultFilters, action) => {
  switch (action.type) {
    case FILTERS:
      return action.payload;
    case CLEAR_ALL_FILTERS:
      return action.payload;
    default:
      return state;
  }
};

const defaultRecentSearch = {
  contactname: [],
  companyname: [],
  location: {contact: [], hq: []},
  department: [],
  jobtitle: [],
  industry: [],
  technology: [],
  foundedyear: [],
  seniority: [],
  searchtype: [],
  headcount: [],
  revenue: [],
  // industrycode: {type: '', code: ''},
};

export const recentSearches = (state = defaultRecentSearch, action) => {
  switch (action.type) {
    case RECENT_SEARCHES:
      return action.payload;
    default:
      return state;
  }
};

export const filteredPeople = (state = [], action) => {
  switch (action.type) {
    case PEOPLE_FILTER:
      return action.payload;
    default:
      return state;
  }
};

export const filteredFavPeople = (state = [], action) => {
  switch (action.type) {
    case PEOPLE_FAV_FILTER:
      return action.payload;
    default:
      return state;
  }
};

export const filteredRevealedContacts = (state = [], action) => {
  switch (action.type) {
    case FILTER_TYPE_REVEALED_CONTACTS:
      return action.payload;
    default:
      return state;
  }
};

export const preloadedPeopleFilterSupportData = (state = {}, action) => {
  switch (action.type) {
    case PEOPLE_FILTER_SUPPORT_DATA:
      return action.payload;
    default:
      return state;
  }
};

export const availableLists = (state = [], action) => {
  switch (action.type) {
    case AVAILABLE_LISTS:
      return action.payload;
    default:
      return state;
  }
};

export const availableCompanyLists = (state = [], action) => {
  switch (action.type) {
    case AVAILABLE_COMPANY_LISTS:
      return action.payload;
    default:
      return state;
  }
};

export const availableFilters = (state = [], action) => {
  switch (action.type) {
    case AVAILABLE_FILTERS:
      return action.payload;
    default:
      return state;
  }
};

export const filtersSidePopup = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_FILTERS_SIDE_POPUP:
      return action.payload;
    default:
      return state;
  }
};

export const filteredCompany = (state = [], action) => {
  switch (action.type) {
    case COMPANY_FILTER:
      return action.payload;
    default:
      return state;
  }
};

export const filteredFavCompany = (state = [], action) => {
  switch (action.type) {
    case COMPANY_FAV_FILTER:
      return action.payload;
    default:
      return state;
  }
};

export const toggleMinimizeFilters = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_MINIMIZE_FILTERS:
      return action.payload;
    default:
      return state;
  }
};

export const technologySearch = (
  state = {showTechnologyBlock: false, totalcount: 0},
  action,
) => {
  switch (action.type) {
    case TECHNOLOGY_SEARCH:
      return action.payload;
    default:
      return state;
  }
};

export const crmDetails = (state = [], action) => {
  switch (action.type) {
    case CRM_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const companyDetails = (state = [], action) => {
  switch (action.type) {
    case COMPANY_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export const contactDetails = (state = [], action) => {
  switch (action.type) {
    case PEOPLE_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export var ismapFieldModalOpen = (state = false, action) => {
  if (action.type === MAP_FIELD_MODAL) {
    state = action.payload;
  }

  return state;
};

export const zohoFields = (state = [], action) => {
  switch (action.type) {
    case ZOHO_FIELDS:
      return action.payload;
    default:
      return state;
  }
};
export const salesforceFields = (state = [], action) => {
  switch (action.type) {
    case SF_FIELDS:
      return action.payload;
    default:
      return state;
  }
};

export var iswebHookModalOpen = (state = false, action) => {
  if (action.type === WEB_HOOK_MODAL) {
    state = action.payload;
  }

  return state;
};
