import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {arrayPush, FieldArray, reduxForm, getFormValues} from 'redux-form';
import DropdownPair from './DropdownPair';
import LocaleStrings from '@language';
import {classNames} from '@commonFunction';
import toast, {Toaster} from 'react-hot-toast';
import {updateMappedFields, fetchCRMDetails} from '../../../../search/actions';
import {useMappingSelectionContext} from './selectioncontext';
import {useClickAway} from 'react-use';

function addField(selectedValue) {
  return dispatch => {
    dispatch(arrayPush('mappingForm', 'mappings', selectedValue));
  };
}

function MappingForm(props) {
  const {
    defaultMappings,
    zohoOptions,
    kipploOptions,
    closeModal,
    handleSubmit,
    crmDetails,
    modalstate,
    salesforceOptions,
    initialValues,
  } = props;
  const [selectedKipplo, setSelectedKipplo] = useState([]);
  const [selectedZoho, setSelectedZoho] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [showCustomDropdown, setShowCustomDropdown] = useState(false);

  const context = useMappingSelectionContext();

  // useEffect(() => {
  //   setSelectedKipplo(defaultMappings.map(mapping => mapping.kipplo));
  //   setSelectedZoho(defaultMappings.map(mapping => mapping.zoho));
  // }, []);

  useEffect(() => {
    const initialKipplo = [];
    const initialZoho = [];
    let initialMappings = [];

    if (
      modalstate == 'zoho' &&
      crmDetails &&
      Object.keys(crmDetails.zoho_mapped_data).length > 0
    ) {
      const zoho_mapped_data = Object.entries(crmDetails.zoho_mapped_data).map(
        ([zoho, kipplo]) => ({
          kipplo,
          zoho,
        }),
      );
      console.log('zoho_mapped_data', zoho_mapped_data);
      initialMappings = zoho_mapped_data;
    } else {
      let finalArray = defaultMappings;
      finalArray = finalArray.slice(0, 1);
      initialMappings = finalArray;
    }

    if (
      modalstate == 'salesforce' &&
      crmDetails &&
      Object.keys(crmDetails.sales_mapped_data).length > 0
    ) {
      const sales_mapped_data = Object.entries(
        crmDetails.sales_mapped_data,
      ).map(([zoho, kipplo]) => ({
        kipplo,
        zoho,
      }));

      initialMappings = sales_mapped_data;
    } else {
      initialMappings = defaultMappings;
    }

    if (initialMappings.length > 0) {
      initialMappings.forEach(mapping => {
        initialKipplo.push(mapping.kipplo);
        initialZoho.push(mapping.zoho);
      });
    }

    setSelectedKipplo(initialKipplo);
    setSelectedZoho(initialZoho);
  }, [crmDetails, defaultMappings, modalstate]);

  const handleFormSubmit = values => {
    const {mappings} = values;
    let formValidate = true;
    if (props.edit == false) {
      let salesforceToAssign = ['LastName', 'Company'];
      let zohoToAssign = ['Last_Name'];

      const zohoValues = values.mappings.map(entry => entry.zoho);
      let counter = 0;
      let filterArr = modalstate == 'zoho' ? zohoToAssign : salesforceToAssign;
      filterArr.forEach(field => {
        if (!zohoValues.includes(field)) {
          counter++;
        }
      });
      if (counter > 0) {
        formValidate = false;
      }
    }

    if (formValidate) {
      setError(false);
      let leadOption = modalstate == 'zoho' ? zohoOptions : salesforceOptions;
      const jsonResult = mappings.reduce((acc, {kipplo, zoho}) => {
        if (kipplo && zoho) {
          acc[leadOption.find(option => option.value === zoho).value] =
            kipploOptions.find(option => option.value === kipplo).value;
        }
        return acc;
      }, {});

      let data = {};
      data.crmtype = modalstate == 'zoho' ? 'zoho' : 'salesforce';
      data.mapped_data = JSON.stringify(jsonResult);
      //console.log('data', data);
      setLoading(true);
      props.updateMappedFields(props.session, data, res => {
        //console.log('res', res);
        setLoading(false);
        if (res.success == 1) {
          props.fetchCRMDetails(props.session, resp => {
            //console.log('resp', resp);
            toast.success('Updated Successfully');
          });
        } else {
          toast.error(LocaleStrings.something_went_wrong);
        }
        closeModal();
      });
    } else {
      setError(true);
    }

    // console.log('Generated JSON:', jsonResult);
  };

  const handleAddFieldClick = () => {
    console.log('selectedKipplo.length', selectedKipplo.length);
    setShowCustomDropdown(!showCustomDropdown);
    //context.selectIndex(selectedKipplo.length);
  };

  const handleOptionSelect = (selectedValue, index) => {
    const value = {kipplo: selectedValue, zoho: ''};
    let counter = index;
    if (props.formValues.hasOwnProperty('mappings')) {
      counter = props.formValues['mappings'].length;
    }

    setSelectedKipplo(prev => {
      const newSelected = [...prev];
      newSelected[counter] = selectedValue;
      return newSelected;
    });

    props.addField(value);
    context.addField(value);
    setShowCustomDropdown(false);
  };

  const listRef = useRef(null);

  useClickAway(
    listRef,
    () => {
      setShowCustomDropdown(false);
    },
    ['mouseup'],
  );

  useEffect(() => {
    if (props.initialValues) {
      context.updateMappedFields(props.initialValues.mappings);
    }
  }, [props.initialValues]);

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="py-2">
        <div className="flex gap-6 my-4">
          <div className="w-2/5 text-left py-2.5 px-2  rounded-md shadow-sm text-sm font-medium  bg-primaryHover text-primary">
            {LocaleStrings.kipplo_fields}
          </div>
          <div className="w-2/5 text-left py-2.5 px-2   rounded-md shadow-sm text-sm font-medium  bg-primaryHover  text-primary">
            {modalstate == 'zoho'
              ? LocaleStrings.zoho_fields
              : LocaleStrings.salesforce_fields}
          </div>
        </div>
        {error ? (
          <div className="text-red-600">{LocaleStrings.mapping_error}</div>
        ) : (
          ''
        )}
        <div className="h-96 overflow-auto">
          <FieldArray
            name="mappings"
            component={DropdownPair}
            kipploOptions={kipploOptions}
            zohoOptions={modalstate == 'zoho' ? zohoOptions : salesforceOptions}
            selectedKipplo={selectedKipplo}
            setSelectedKipplo={setSelectedKipplo}
            selectedZoho={selectedZoho}
            setSelectedZoho={setSelectedZoho}
            modalstate={modalstate}
          />
        </div>
        <div className="mt-5 flex justify-between">
          <div className="inline-block relative">
            <span
              className="btn-light-primary cursor-pointer"
              style={{
                width: 100,
                position: 'relative',
                display: 'inline-block',
              }}
              onClick={() => {
                handleAddFieldClick();
              }}>
              + Add Fields
            </span>
            {showCustomDropdown && (
              <div
                ref={listRef}
                className="absolute  overflow-y-auto bg-white border border-gray-300 rounded-md shadow-lg z-10 left-0 -top-[300px] min-w-60 max-h-[300px]">
                <ul>
                  {kipploOptions
                    .filter(option => !selectedKipplo.includes(option.value))
                    .map(option => (
                      <li
                        key={option.value}
                        className="cursor-pointer hover:bg-gray-100 px-3 py-2 rounded text-base sm:text-sm"
                        onClick={() => {
                          handleOptionSelect(
                            option.value,
                            context.selectedIndex,
                          );
                        }}>
                        {option.label}
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>
          <button
            disabled={loading}
            //onClick={e => this.fetchCompanydetails(e)}
            className={classNames(
              loading ? 'cursor-not-allowed opacity-20' : '',
              'btn-primary',
            )}
            style={{width: 120}}>
            <svg
              className={classNames(
                loading ? '' : 'hidden',
                'animate-spin-medium h-5 w-5 rounded-full mx-2',
              )}
              disabled={loading}
              viewBox="0 0 1024 1024"
              focusable="false"
              data-icon="loading"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true">
              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
            </svg>
            {LocaleStrings.save}
          </button>
        </div>
      </div>
    </form>
  );
}

function validate(values, ownProps) {
  const salesforceToAssign = ['LastName', 'Company'];
  const zohoToAssign = ['Last_Name'];
  const errors = {};
  //console.log('values', values);
  // const zohoValues =
  //   values.mappings && values.mappings.map(entry => entry.zoho);

  // salesforceToAssign.forEach(field => {
  //   if (!zohoValues.includes(field)) {
  //     //errors.zohoField1 = `Mapping error: '${field}' is missing from  mapping.`;
  //   }
  // });

  return errors;
}

function mapStateToProps(state, ownProps) {
  var {session, user} = state;
  //console.log(ownProps);
  let finalArray = [];

  const salesforceToAssign = ['LastName', 'Company'];
  const zohoToAssign = ['Last_Name'];
  let edit = true;
  if (
    ownProps.modalstate == 'salesforce' &&
    state.crmDetails &&
    Object.keys(state.crmDetails.sales_mapped_data).length == 0
  ) {
    finalArray = ownProps.defaultMappings;
    for (let i = 0; i < salesforceToAssign.length; i++) {
      if (i < finalArray.length) {
        finalArray[i].zoho = salesforceToAssign[i];
      }
    }
    edit = false;
  } else if (
    ownProps.modalstate == 'zoho' &&
    state.crmDetails &&
    Object.keys(state.crmDetails.zoho_mapped_data).length == 0
  ) {
    finalArray = ownProps.defaultMappings;
    finalArray = finalArray.slice(0, 1);

    for (let i = 0; i < zohoToAssign.length; i++) {
      if (i < finalArray.length) {
        finalArray[i].zoho = zohoToAssign[i];
      }
    }
    edit = false;
  }

  if (
    ownProps.modalstate == 'zoho' &&
    state.crmDetails &&
    Object.keys(state.crmDetails.zoho_mapped_data).length > 0
  ) {
    const zohoEntries = Object.entries(state.crmDetails.zoho_mapped_data).map(
      ([key, value]) => ({kipplo: value, zoho: key}),
    );
    // finalArray = zohoEntries.concat(ownProps.defaultMappings).slice(0, 30);
    finalArray = zohoEntries;
  } else if (
    ownProps.modalstate == 'salesforce' &&
    state.crmDetails &&
    Object.keys(state.crmDetails.sales_mapped_data).length > 0
  ) {
    const salesEntries = Object.entries(state.crmDetails.sales_mapped_data).map(
      ([key, value]) => ({kipplo: value, zoho: key}),
    );
    // finalArray = salesEntries.concat(ownProps.defaultMappings).slice(0, 30);
    finalArray = salesEntries;
  }

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    appuserData: state.appuserData,
    initialValues: {
      mappings: finalArray,
    },
    crmDetails: state.crmDetails,
    edit: edit,
    formValues: getFormValues('mappingForm')(state),
  };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({updateMappedFields, fetchCRMDetails, addField}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    validate,
    form: 'mappingForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(MappingForm),
);
