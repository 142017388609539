import React, {useState, Fragment} from 'react';
import {connect} from 'react-redux';
import {classNames} from '@commonFunction';
import IconFav from '../../results/cells/icons/fav';

import IconUpdate from '../../results/cells/icons/update';
import SocialLinks from '../../results/cells/components/sociallinks';
import {
  FILTER_TYPE_COMPANY,
  FILTER_TYPE_FAV_COMPANY,
  addCompanyTolist,
  toogleCompanyFav,
  updateFavCompany,
  updateCompany,
} from '../../actions';
import IconFaved from '../../results/cells/icons/faved';
import {
  ENTERPRISE_MONTHLY,
  ENTERPRISE_YEARLY,
  toTitleCase,
} from '../../../../../common/constants';
import IconLock from '../../results/cells/icons/lock';
import {useNavigate} from 'react-router-dom';
import LocaleStrings from '../../../../languages';

import AddCompanyToListBlock from './components/addtolistblock';

import Details from '../details';
import IconChevronDown from './icons/chevrondown';
import IconDelete from '../../../mylist/icons/delete';

function CompanyCell(props) {
  const {selected, onCheckUnCheck, onOpenCreateList, filterType} = props;
  const {
    companyname,
    industry,
    location,
    company,
    hqnumbers,
    technologies,
    isfav,
    addedtolist,
    listid,
    new_contact_data_available,
    new_company_data_available,
  } = props.company;

  const {linkedin, twitter, facebook} = company;

  const navigate = useNavigate();

  const revealed = true;

  const {headcount, revenue} = company;

  const techsToShow = technologies.length > 0 ? technologies[0] : '';

  const [showList, setShowList] = useState(false);

  const [expand, setExpand] = useState(false);
  const [expanded, setExpanded] = useState(false);

  return (
    <Fragment>
      <tr
        key={companyname}
        className={classNames(
          expand ? 'bg-primaryHover' : '',
          selected ? 'bg-gray-50' : undefined,
        )}>
        {/* {filterType === FILTER_TYPE_FAV_COMPANY ? (
          <td className="group relative px-7 py-10">
            {selected && (
              <div className="absolute inset-y-0 left-0 w-0.5 bg-primary" />
            )}
            <input
              type="checkbox"
              className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-500 text-primary group-hover:cursor-pointer"
              value={companyname}
              checked={selected}
              onChange={e => onCheckUnCheck(e.target.checked)}
            />
          </td>
        ) : null} */}
        <td
          className={classNames(
            'group pr-3 hover:cursor-pointer relative px-7 py-2',
          )}
          onClick={e => {
            //Dont allow to consume click on icons
            if (e.target.tagName !== 'svg' || e.target.tagName !== 'path') {
              if (expand === expanded) {
                //Call click only both are same to avoid multiple clicks
                if (!expand) {
                  setExpand(true);
                  setTimeout(() => {
                    setExpanded(true);
                  }, 10);
                } else {
                  setExpanded(false);
                  setTimeout(() => {
                    setExpand(false);
                  }, 400);
                }
              }
            }
          }}>
          <div
            className={classNames(
              'relative flex items-center',
              !linkedin && !twitter && !facebook ? '' : 'mb-2',
            )}>
            {company.logo ? (
              <img
                className="mr-1 h-5"
                src={company.logo}
                onError={e => {
                  e.target.src = require('../../../../../assets/images/empty_company.png');
                }}
              />
            ) : null}
            <div className="text-sm text-primary hover:font-medium truncate">
              {company.title}
            </div>
            {/* {new_company_data_available ? (
              <div className="w-2 h-2 ml-2 mt-0.5 hover:cursor-pointer tooltip">
                <IconUpdate />
                <span className="tooltiptext tooltip-right">
                  {LocaleStrings.revealedContacts.updateavailable}
                </span>
              </div>
            ) : null} */}
            {/* <div className="w-2 h-2 ml-2 mt-2 z-50">
                  <div className="absolute block top-4 transition duration-500 ease-in group-hover/company:visible invisible z-50 inline">
                    <CompanyInfo {...company} />
                  </div>
                </div> */}
          </div>
          {!linkedin && !twitter && !facebook ? null : (
            <div>
              <SocialLinks
                linkedin={linkedin}
                twitter={twitter}
                facebook={facebook}
              />
            </div>
          )}
          {/* <CompanyBlock company={props.company.company} /> */}
        </td>
        <td
          className="group px-3 py-2 hover:cursor-pointer"
          onClick={() => {
            if (expand === expanded) {
              //Call click only both are same to avoid multiple clicks
              if (!expand) {
                setExpand(true);
                setTimeout(() => {
                  setExpanded(true);
                }, 10);
              } else {
                setExpanded(false);
                setTimeout(() => {
                  setExpand(false);
                }, 400);
              }
            }
          }}>
          <div className="text-xs text-gray-500 line-clamp-3">{location}</div>
          {/* <div
          className="absolute right-2 top-6 group-hover:opacity-100 opacity-0"
          onClick={() => {
            navigator.clipboard.writeText(location);
            props.onCopySuccess();
          }}>
          <IconCopy />
        </div> */}
        </td>
        <td
          className="group whitespace-wrap px-3 py-2 hover:cursor-pointer relative"
          onClick={() => {
            if (expand === expanded) {
              //Call click only both are same to avoid multiple clicks
              if (!expand) {
                setExpand(true);
                setTimeout(() => {
                  setExpanded(true);
                }, 10);
              } else {
                setExpanded(false);
                setTimeout(() => {
                  setExpand(false);
                }, 400);
              }
            }
          }}>
          <div className="text-xs text-gray-500">{toTitleCase(industry)}</div>
          {/* <div
          className="absolute right-2 top-7 group-hover:opacity-100 opacity-0"
          onClick={() => {
            navigator.clipboard.writeText(industry);
            props.onCopySuccess();
          }}>
          <IconCopy />
        </div> */}
        </td>

        <td
          className="w-1/3 group px-3 py-2 hover:cursor-pointer relative"
          onClick={() => {
            if (expand === expanded) {
              //Call click only both are same to avoid multiple clicks
              if (!expand) {
                setExpand(true);
                setTimeout(() => {
                  setExpanded(true);
                }, 10);
              } else {
                setExpanded(false);
                setTimeout(() => {
                  setExpand(false);
                }, 400);
              }
            }
          }}>
          <div className="text-xs mb-2 text-gray-500 hover:font-medium">
            {headcount === '-1'
              ? 'Self Employed'
              : headcount === '0'
                ? 'Unknown'
                : headcount}
          </div>
        </td>

        {/* <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500 justify-items-center">
        <div className="text-sm mb-2 hover:font-medium">
          {convertToInternationalCurrencySystem(revenue)}
        </div>
      </td> */}
        <td
          className="group whitespace-nowrap px-3 py-2 text-xs text-gray-500 justify-items-center relative hover:cursor-pointer"
          onClick={e => {
            if (e.currentTarget === e.target) {
              if (expand === expanded) {
                //Call click only both are same to avoid multiple clicks
                if (!expand) {
                  setExpand(true);
                  setTimeout(() => {
                    setExpanded(true);
                  }, 10);
                } else {
                  setExpanded(false);
                  setTimeout(() => {
                    setExpand(false);
                  }, 400);
                }
              }
            }
          }}>
          {techsToShow === '' ? null : (
            <div
              className={classNames(
                'text-xs mb-2',
                props.credits.role === 'client' && !props.onEnterprisePlan
                  ? 'blur-sm hover:font-medium'
                  : '',
              )}
              onClick={e => {
                if (e.currentTarget === e.target) {
                  if (expand === expanded) {
                    //Call click only both are same to avoid multiple clicks
                    if (!expand) {
                      setExpand(true);
                      setTimeout(() => {
                        setExpanded(true);
                      }, 10);
                    } else {
                      setExpanded(false);
                      setTimeout(() => {
                        setExpand(false);
                      }, 400);
                    }
                  }
                }
              }}>
              {techsToShow}
              {props.credits.role === 'client' && props.onEnterprisePlan ? (
                <div
                  className="text-primary hover:cursor-pointer text-xs"
                  onClick={e => {
                    console.log(e.currentTarget, '===', e.target);
                    if (e.currentTarget === e.target) {
                      props.onShowTechs(props.company);
                    }
                  }}>
                  View All
                </div>
              ) : null}
            </div>
          )}
          {props.credits.role === 'client' && !props.onEnterprisePlan ? (
            <div
              className="absolute top-2 left-0 flex bg-primaryHover justify-center items-center px-10 py-4 rounded-lg transition-opacity ease-in group-hover:opacity-60 opacity-0"
              onClick={() => {
                navigate('/upgradeplan');
              }}>
              <IconLock size={18} />{' '}
              <span className="text-primary ml-2">{LocaleStrings.upgrade}</span>
            </div>
          ) : null}
        </td>
        <td className="whitespace-nowrap py-2 pl-3 pr-4">
          <div className="flex gap-2 justify-end relative mr-4">
            <AddCompanyToListBlock
              revealed={revealed}
              filterType={filterType}
              addedtolist={addedtolist}
              listid={listid}
              company={company}
              availableLists={props.availableLists}
              onOpenCreateList={company => {
                onOpenCreateList(props.company);
              }}
              onChangeList={list => {
                props.addCompanyTolist(
                  props.session,
                  {
                    listid: list.id,
                    companyids: `${props.company.companyid}`,
                  },
                  _ => {},
                );

                const newcompany = {
                  ...props.company,
                  addedtolist: true,
                  listid: list.id,
                };
                const index = props.filteredCompany.data.findIndex(
                  t => t.companyid === props.company.companyid,
                );
                props.filteredCompany.data[index] = newcompany;
                if (filterType === FILTER_TYPE_COMPANY) {
                  props.updateCompany(props.filteredCompany);
                } else {
                  props.updateFavCompany(props.filteredCompany);
                }
              }}
            />

            <div
              onClick={() => {
                const newcompany = {...props.company, isfav: !isfav};
                if (filterType === FILTER_TYPE_COMPANY) {
                  const index = props.filteredCompany.data.findIndex(
                    t => t.companyid === props.company.companyid,
                  );
                  props.filteredCompany.data[index] = newcompany;
                  props.updateCompany(props.filteredCompany);

                  const params = {
                    action: isfav ? 'removecompanyfav' : 'addcompanyfav',
                    companyid: newcompany.companyid,
                  };
                  props.toogleCompanyFav(props.session, params, res => {
                    console.log('Fav => ', res);
                  });
                } else {
                  props.onRemoveFav(newcompany);
                }
              }}>
              {isfav ? (
                filterType === FILTER_TYPE_FAV_COMPANY ? (
                  <IconDelete />
                ) : (
                  <IconFaved />
                )
              ) : (
                <IconFav />
              )}
            </div>
            <div
              className="ml-4 flex items-center hover:cursor-pointer"
              onClick={() => {
                if (expand === expanded) {
                  //Call click only both are same to avoid multiple clicks
                  if (!expand) {
                    setExpand(true);
                    setTimeout(() => {
                      setExpanded(true);
                    }, 10);
                  } else {
                    setExpanded(false);
                    setTimeout(() => {
                      setExpand(false);
                    }, 400);
                  }
                }
              }}>
              <div
                className={classNames(
                  'transition-all transform',
                  expand ? 'rotate-180' : 'rotate-0',
                )}>
                <IconChevronDown />
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr
        className={classNames(
          expand ? 'bg-primaryHover' : '',
          !expand ? 'hidden' : '',
        )}>
        <td colSpan={6}>
          <div
            className={classNames(
              ' transition-all duration-500 h-0 overflow-hidden',
              expanded ? 'h-[380px]' : 'h-0',
            )}>
            <div className="px-7 py-4 flex">
              <Details
                {...props}
                company={{...company, ...props.company}}
                credits={props.credits}
              />
            </div>
          </div>
        </td>
      </tr>
    </Fragment>
  );
}

function mapStateToProps(state, ownProps) {
  var {session, user, savedFilters, appuserData} = state;
  let onEnterprisePlan = false;
  if (appuserData.credits) {
    onEnterprisePlan =
      appuserData.credits.planid === ENTERPRISE_YEARLY ||
      appuserData.credits.planid === ENTERPRISE_MONTHLY;
  }
  return {
    session,
    user,
    credits: !appuserData.credits ? {role: ''} : appuserData.credits,
    onEnterprisePlan,
    savedFilters,
    availableLists: state.availableCompanyLists,
    searchSelectedSubMenu: state.searchSelectedSubMenu,
    filteredCompany:
      ownProps.filterType === FILTER_TYPE_COMPANY
        ? state.filteredCompany
        : state.filteredFavCompany,
  };
}

export default connect(mapStateToProps, {
  addCompanyTolist,
  toogleCompanyFav,
  updateCompany,
  updateFavCompany,
})(CompanyCell);
