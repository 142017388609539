import React, {useState} from 'react';
import Portal from '../../../../../../common/Portal';
import TooltipPopover from '../../../../../../common/TooltipPopover';
import IconUpdate from '../icons/update';
import CompanyInfo from './companyinfo';
import SocialLinks from './sociallinks';
import {useNavigate} from 'react-router-dom';

export default function CompanyBlock(props) {
  const [showCompanyInfo, setShowCompanyInfo] = useState(false); // toggles dropdown visibility
  const [coords, setCoords] = useState({}); // takes current button coordinates

  const {linkedin, twitter, facebook} = props.company;

  const navigate = useNavigate();

  const updateTooltipCoords = button => {
    const rect = button.getBoundingClientRect();
    setCoords({
      left: rect.x, // add half the width of the button for centering
      top: rect.y, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
    });
  };

  const btnRef = React.createRef();

  return (
    <div
      ref={btnRef}
      // onMouseEnter={e => {
      //   if (!showCompanyInfo) {
      //     updateTooltipCoords(e.target);
      //     setShowCompanyInfo(true);
      //   }
      // }}
      // onMouseLeave={e => {
      //   if (showCompanyInfo) {
      //     updateTooltipCoords(e.target);
      //     setShowCompanyInfo(false);
      //   }
      // }}
      // onMouseDown={e => {
      //   navigate('/companydetails', {params: {companyid: props.companyid}});
      // }}
      // onClick={() => {
      //   navigate('/companydetails', {params: {companyid: props.companyid}});
      // }}
    >
      <div className="flex items-center mb-2 gap-2">
        {props.company.logo ? (
          <img
            className="h-4"
            src={props.company.logo}
            onError={e => {
              e.target.src = require('../../../../../../assets/images/empty_company.png');
            }}
          />
        ) : null}
        <div className="text-xs text-primary hover:font-medium truncate">
          {props.company.title}
        </div>
        {/* {props.new_company_data_available ? (
          <div className="hover:cursor-pointer tooltip">
            <IconUpdate />
            <span className="tooltiptext tooltip-right">Update available</span>
          </div>
        ) : null} */}
        {/* <div className="w-2 h-2 ml-2 mt-2 z-50">
                  <div className="absolute block top-4 transition duration-500 ease-in group-hover/company:visible invisible z-50 inline">
                    <CompanyInfo {...company} />
                  </div>
                </div> */}
      </div>
      {!linkedin && !twitter && !facebook ? null : (
        <div>
          <SocialLinks
            linkedin={linkedin}
            twitter={twitter}
            facebook={facebook}
          />
        </div>
      )}
      {/* <div
    className="absolute right-2 top-6 group-hover:opacity-100 opacity-0"
    onClick={() => {
      navigator.clipboard.writeText(company.title);
      props.onCopySuccess();
    }}>
    <IconCopy />
  </div> */}
      {/* {showCompanyInfo && (
        <Portal>
          <TooltipPopover
            direction={'right'}
            width={200}
            coords={coords}
            updateTooltipCoords={() =>
              updateTooltipCoords(btnRef.current.buttonNode)
            }>
            <CompanyInfo {...props.company} />
          </TooltipPopover>
        </Portal>
      )} */}
    </div>
  );
}
