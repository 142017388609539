import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import LocaleStrings from '@language';
import {convertDateIntoLocal} from '@commonFunction';
import {TRIALPLANID, PROFESSIONAL_YEARLY, EXTENSION_LINK} from '@constant';
import IconPlan from '../icons/iconplan';

class DashboardHead extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_currency,
    };

    // All Binded Functions
  }

  // updgrade plan page redirection
  _openUpgradPlan(e) {
    this.props.router.navigate('/upgradeplan');
  }
  // addon page redirection
  _handleAddon(e) {
    this.props.router.navigate('/creditaddons');
  }
  // download extension
  handleDownloadClick = e => {
    window.open(EXTENSION_LINK, '_blank');
  };

  render() {
    let {user, appuserData} = this.props;

    // User and plan details variable
    let name = '';
    let validity = '';
    let price = 0;
    let contactcredit = '';
    let details = '';
    let availableseats = '';
    let seatcount = 0;
    let usedseats = '';
    let expirydate = '';
    let percentage = 0;
    let planid = '';
    let sku = '';
    let role = '';
    let subId = '';
    let autorenew = null;
    let stripeplan = false;
    let expired = false;
    let business_emailcredits = 0;
    let personal_emailcredits = 0;
    let downgrade_startson = '';

    const otherPlan = ['custom_trial', 'custom_monthly', 'custom_yearly', null]; //checking by skus
    if (
      appuserData &&
      appuserData.data &&
      appuserData.data.length > 0 &&
      appuserData.data[0].client_by_clientid
    ) {
      role = appuserData.data[0].role;
      let client_by_clientid = appuserData.data[0]?.client_by_clientid;

      if (
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid
          .length > 0
      ) {
        let usersubscription_by_clientid =
          appuserData.data[0]?.client_by_clientid
            ?.usersubscription_by_clientid[0];
        expirydate = convertDateIntoLocal(
          usersubscription_by_clientid.expirydate,
        ).format('D MMM YYYY');

        downgrade_startson = usersubscription_by_clientid.downgrade_startson
          ? convertDateIntoLocal(
              usersubscription_by_clientid.downgrade_startson,
            ).format('D MMM YYYY')
          : '';

        let currentDate = moment();

        if (expirydate !== '') {
          if (currentDate.isAfter(moment(expirydate))) {
            expired = true;
          }
        }

        subId = usersubscription_by_clientid.transactionid;
        autorenew = usersubscription_by_clientid.autorenew;
      }
      if (
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid
          .length > 0 &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid[0]
          ?.subscriptionplan_by_planid
      ) {
        let subscriptionplan_by_planid =
          appuserData.data[0]?.client_by_clientid
            ?.usersubscription_by_clientid[0]?.subscriptionplan_by_planid;
        planid = subscriptionplan_by_planid.planid;
        sku = subscriptionplan_by_planid.sku;
        name = subscriptionplan_by_planid.name;
        validity = subscriptionplan_by_planid.validity;
        price = subscriptionplan_by_planid.price;
        details = subscriptionplan_by_planid.details;
        availableseats = subscriptionplan_by_planid.availableseats;
      }
      if (
        appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid.length >
          0
      ) {
        let usercredit_by_clientid =
          appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid[0];
        seatcount = usercredit_by_clientid.seatcount;
        contactcredit = usercredit_by_clientid.contactcredit;
        business_emailcredits = usercredit_by_clientid.bus_email_credits;
        personal_emailcredits = usercredit_by_clientid.personal_email_credits;
      }
    }
    if (sku != '') {
      if (!otherPlan.includes(sku)) {
        stripeplan = true;
      }
    }

    return (
      <>
        <div className="pb-4 font-medium">
          {LocaleStrings.dashboard_main_subscriptionplan}
        </div>
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="flex justify-between py-2">
            <div className="px-4 py-3 sm:px-6 block md:flex">
              {/* FOR SMALLER SCREEN */}
              <div className="flex md:hidden w-12">
                <IconPlan />
                {/* <img src="../../images/starter.png" /> */}
                {expired == true ? (
                  <h3 className="text-base pl-3 pt-2 text-gray-900">
                    {LocaleStrings.dashboard_main_subscription_expired}
                  </h3>
                ) : contactcredit == 0 ? (
                  <>
                    <h3 className="text-base pl-3 pt-2 text-gray-900">
                      {_.startCase(name)}
                    </h3>
                    {planid != TRIALPLANID ? (
                      <h3 className="text-base pl-3 pt-2 text-red-400">
                        ({LocaleStrings.dashboard_main_creaditsovers})
                      </h3>
                    ) : (
                      ''
                    )}
                  </>
                ) : contactcredit == '' ? (
                  <h3 className="text-base pl-3 text-gray-900">
                    {LocaleStrings.dashboard_main_noplansadded}
                  </h3>
                ) : (
                  <h3 className="text-base pl-3 text-gray-900">
                    {_.startCase(name)}
                  </h3>
                )}
              </div>
              {/* FOR SMALLER SCREEN END*/}
              {/* FOR BIGGER SCREEN */}
              <div className="flex flex-row items-center">
                <IconPlan />
                {expired == true ? (
                  <h3 className="text-base pl-3 text-gray-900 hidden md:block">
                    {LocaleStrings.dashboard_main_subscription_expired}
                  </h3>
                ) : contactcredit == 0 ? (
                  <>
                    <h3 className="text-base pl-3 text-gray-900 hidden md:block">
                      {_.startCase(name)}
                    </h3>
                    {planid != TRIALPLANID ? (
                      <h3 className="text-base pl-3 text-red-400 hidden md:block">
                        ({LocaleStrings.dashboard_main_creaditsovers})
                      </h3>
                    ) : (
                      ''
                    )}
                  </>
                ) : contactcredit == '' ? (
                  <h3 className="text-base pl-3 text-gray-900 hidden md:block">
                    {LocaleStrings.dashboard_main_noplansadded}
                  </h3>
                ) : (
                  <h3 className="text-base pl-3 text-gray-900 hidden md:block">
                    {_.startCase(name)}
                  </h3>
                )}
              </div>
              {/* FOR BIGGER SCREEN */}
              <div>
                {role == 'client' ? (
                  <button
                    type="button"
                    className="btn-primary ml-4 xs:mt-4 md:mt-0  items-center"
                    style={{width: '140px'}}
                    onClick={e => this._openUpgradPlan(e)}>
                    {planid == PROFESSIONAL_YEARLY ? 'View Plans' : 'Upgrade'}
                  </button>
                ) : (
                  ''
                )}
              </div>
              {/* commented as per new requirement */}
              {/* {contactcredit == 0 && planid != TRIALPLANID ? ( */}
              {/* {role == 'client' && planid != TRIALPLANID ? (
              <button
                type="button"
                className="btn-primary xs:mt-4 md:mt-0 ml-4 items-center"
                style={{width: '150px'}}
                onClick={e => this._handleAddon(e)}>
                {LocaleStrings.dashboard_main_head_btn}
              </button>
            ) : (
              ''
            )} */}
            </div>
            <div className="pr-4">
              <div className="font-medium text-center">
                {LocaleStrings.dashboard_main_unbox_text}
              </div>
              <div className="w-60">
                <button
                  className="btn-primary"
                  type="button"
                  onClick={e => this.handleDownloadClick(e)}>
                  {LocaleStrings.dashboard_main_download_ext_btn}
                </button>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200 px-4 py-3 sm:p-0">
            <div className="py-4 px-6 grid xs:grid-cols-1 sm:grid-cols-2 ">
              <div>
                {appuserData &&
                appuserData.data &&
                appuserData.data.length > 0 &&
                appuserData.data[0].client_by_clientid &&
                appuserData.data[0]?.client_by_clientid
                  ?.usersubscription_by_clientid &&
                appuserData.data[0]?.client_by_clientid
                  ?.usersubscription_by_clientid.length > 0 &&
                expired == false ? (
                  <>
                    <div className="flex">
                      <div className="text-3xl font-semibold">${price}</div>
                      {validity == '30' || validity == 30 ? (
                        <div className="mt-3 ml-1 text-ternary">
                          {LocaleStrings.dashboard_main_permonth}
                        </div>
                      ) : (
                        <div className="mt-3 ml-1 text-ternary">
                          {LocaleStrings.dashboard_main_peryear}
                        </div>
                      )}
                    </div>
                    <div className="flex mt-1">
                      {planid == TRIALPLANID ? (
                        <div className="text-sm font-medium text-ternary">
                          {LocaleStrings.dashboard_main_autorenew}
                        </div>
                      ) : (
                        <>
                          {stripeplan == true &&
                          subId != null &&
                          autorenew != null &&
                          (autorenew == 0 || autorenew == '0') ? (
                            <div className="text-red-400 text-sm font-medium">
                              {
                                LocaleStrings.dashboard_main_head_subscriptioncancelled
                              }{' '}
                              {expirydate}
                            </div>
                          ) : (
                            <>
                              {downgrade_startson !== '' ? (
                                <div className="text-sm font-medium text-ternary">
                                  {LocaleStrings.dashboard_main_downgrade}
                                </div>
                              ) : (
                                <div className="text-sm font-medium text-ternary">
                                  {LocaleStrings.dashboard_main_nextrenewal}
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {stripeplan == true &&
                      subId !== null &&
                      autorenew !== null &&
                      (autorenew == 0 || autorenew == '0') ? (
                        ''
                      ) : (
                        <div className="text-sm ml-1 text-ternary">
                          {downgrade_startson !== ''
                            ? downgrade_startson
                            : expirydate}
                        </div>
                      )}
                    </div>
                    <div className="flex mt-5">
                      <img
                        className="h-6 w-6 mr-2"
                        src="../../images/checkicon.png"
                      />
                      <div>
                        {planid != TRIALPLANID ? (
                          <div className="text-sm text-semiGray">
                            <strong>{contactcredit} </strong>
                            {LocaleStrings.dashboard_main_contactscredit}
                          </div>
                        ) : (
                          <div className="text-sm text-semiGray">
                            <strong>{contactcredit} </strong>
                            {LocaleStrings.dashboard_main_contactscredit_trials}
                          </div>
                        )}

                        {planid == TRIALPLANID ? (
                          <div>
                            <div className="text-sm text-semiGray">
                              <strong>{business_emailcredits} </strong>
                              {
                                LocaleStrings.dashboard_main_head_business_credits
                              }
                            </div>
                            <div className="text-sm text-semiGray">
                              <strong>{personal_emailcredits} </strong>
                              {
                                LocaleStrings.dashboard_main_head_personal_credits
                              }
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="text-sm text-semiGray">
                              <strong>{business_emailcredits} </strong>
                              {
                                LocaleStrings.dashboard_main_head_business_credits
                              }
                            </div>
                            <div className="text-sm text-semiGray">
                              <strong>{personal_emailcredits} </strong>
                              {
                                LocaleStrings.dashboard_main_head_personal_credits
                              }
                            </div>
                          </div>
                          // <div className="text-base text-semiGray">
                          //   {LocaleStrings.dashboard_main_head_unlimited_emails}
                          // </div>
                        )}
                        <div className="text-gray500 text-sm font-normal mt-1">
                          {/* {LocaleStrings.dashboard_main_collectsupto}{" "}
                        {contactcredit}{" "}
                        {LocaleStrings.dashboard_main_searchcontacts} */}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="flex w-40">
                    <button
                      type="button"
                      className="btn-primary items-center"
                      onClick={e => this._openUpgradPlan(e)}>
                      {LocaleStrings.dashboard_main_subscribenow_btn}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  appuserData: state.appuserData,
});

export default connect(mapStateToProps, {})(DashboardHead);
