export default function IconAddedToList() {
  return (
    <div className="p-1.5 px-2 bg-white rounded-md border border-gray-200 justify-center items-center gap-1 inline-flex hover:bg-gray-50 hover:cursor-pointer">
      <div class="justify-center items-center flex">
        <svg
          width="14"
          height="14"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.0007 2.5H13.5007C14.9008 2.5 15.6008 2.5 16.1356 2.77248C16.606 3.01217 16.9885 3.39462 17.2282 3.86502C17.5007 4.3998 17.5007 5.09987 17.5007 6.5V13.5C17.5007 14.9001 17.5007 15.6002 17.2282 16.135C16.9885 16.6054 16.606 16.9878 16.1356 17.2275C15.6008 17.5 14.9008 17.5 13.5007 17.5H6.50065C5.10052 17.5 4.40045 17.5 3.86567 17.2275C3.39527 16.9878 3.01282 16.6054 2.77313 16.135C2.50065 15.6002 2.50065 14.9001 2.50065 13.5V10M13.7507 8.33333H9.16732M6.25065 12.5H13.7507M2.08398 5L4.16732 6.66667L7.08398 2.77248"
            stroke="#12B76A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
}
