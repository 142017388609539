import SubAddToList from './subaddtolist';
import {classNames} from '@commonFunction';
export default function AddToList(props) {
  return (
    <div
      class={classNames(
        'h-auto bg-white rounded-lg drop-shadow-lg border border-gray-200 flex-col justify-center items-center inline-flex z-50',
      )}>
      <SubAddToList {...props} />
    </div>
  );
}
