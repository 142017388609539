import {useEffect, useState} from 'react';
import {
  ENTERPRISE_MONTHLY,
  ENTERPRISE_YEARLY,
  companyContactsSettings,
  peopleContactsSettings,
} from '../../../../../common/constants';
import LocaleStrings from '../../../../languages';
import IconPeople from '../icons/people';
import {Switch} from '@headlessui/react';
import {classNames} from '@commonFunction';
import IconPhone from '../icons/phone';
import IconMail from '../icons/mail';
import IconTech from '../icons/tech';
import {fetchCSVInfo, toggleCSVAnalysis} from '../../actions';
import {connect} from 'react-redux';
import SkeletonLoader from '../../../../../common/skeletonloader';
import IconCompany from '../icons/company';

function ContactOption(props) {
  return (
    <div className="flex px-2 py-2 gap-4 items-center rounded-xl border border-gray-200 justify-between">
      <div className="flex items-center gap-4">
        <div className="w-8 h-8 p-2 bg-white rounded-lg border border-gray-200">
          <div class="justify-center items-center flex">
            {props.dataKey === 'technology' ? (
              <IconTech />
            ) : props.dataKey === 'cellnumbers' ? (
              <IconPhone />
            ) : (
              <IconMail />
            )}
          </div>
        </div>
        <div className="text-sm">{props.title}</div>
      </div>
      <div>
        <Switch
          checked={props.selection}
          onChange={e => {
            props.onToggle(e);
          }}
          className={classNames(
            props.selection ? 'bg-primary' : 'bg-gray-200',
            'relative inline-flex h-6 w-12 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
          )}>
          <span
            aria-hidden="true"
            className={classNames(
              props.selection ? 'translate-x-[28px]' : 'translate-x-0',
              'pointer-events-none inline-block h-4 w-4 mt-0.5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            )}
          />
        </Switch>
      </div>
    </div>
  );
}

function EnrichmentOptions(props) {
  const [contactSettings, setContactSettings] = useState(
    props.section === 'people'
      ? peopleContactsSettings
      : companyContactsSettings,
  );
  const fieldKeys = Object.keys(
    props.section === 'people'
      ? peopleContactsSettings
      : companyContactsSettings,
  );
  const [fetching, setFetching] = useState(true);

  const getMappingData = () => {
    const data = {...props.selectMappingData};
    const keys = Object.keys(data);
    const newMappedData = {};
    const allFields =
      props.section === 'people'
        ? {
            ...props.standardFieldSettings,
            ...peopleContactsSettings,
          }
        : {
            ...props.standardFieldSettings,
            ...companyContactsSettings,
          };
    keys.map(key => {
      if (data[key] === '') {
        newMappedData[key] = {
          title: allFields[key].title,
        };
      } else {
        newMappedData[key] = {title: data[key]};
      }
    });
    return newMappedData;
  };

  useEffect(() => {
    if (fetching) {
      props.toggleCSVAnalysis(true);
      const params = {
        action: 'view',
        type: props.section === 'people' ? 'contact' : 'company',
        standard_fields: {
          ...props.standardFieldSettings,
          ...contactSettings,
        },
        column_matched_settings: getMappingData(),
        filename: props.csvUploadInfo.filename,
      };

      props.fetchCSVInfo(props.session, params, _ => {
        setFetching(false);
        props.toggleCSVAnalysis(false);
      });
    }
  }, [fetching]);

  const {
    work_email_count,
    personal_email_count,
    phone_number_count,
    credits_deduct,
    technology_count,
  } = props.csvEnrichInfo;

  const renderPeopleContactTitle = () => {
    let title = [];

    fieldKeys.map(key => {
      if (contactSettings[key].selection) {
        if (key === 'cellnumbers') {
          title.push(
            LocaleStrings.formatString(
              LocaleStrings.enrichment.people.infoPhoneNumber,
              phone_number_count ?? 0,
            ),
          );
        }
        if (key === 'businessemails') {
          title.push(
            LocaleStrings.formatString(
              LocaleStrings.enrichment.people.infoWorkEmail,
              work_email_count ?? 0,
            ),
          );
        }
        if (key === 'personalemails') {
          title.push(
            LocaleStrings.formatString(
              LocaleStrings.enrichment.people.infoSecondaryEmail,
              personal_email_count ?? 0,
            ),
          );
        }
      }
    });

    if (title.length === 0) return '';
    if (title.length === 1) return title[0];
    if (title.length === 2) return title.join(' and ');

    const firstTitles = title.splice(0, 2);
    const lastTitle = title[0];
    title = [];
    title.push(firstTitles.join(', '));
    title.push(lastTitle);

    return title.join(' and ');
  };

  const renderCompanyInfoTitle = () => {
    return LocaleStrings.formatString(
      LocaleStrings.enrichment.company.infoTitle,
      technology_count ?? 0,
    );
  };

  const contactCredits =
    props.appuserData.credits.contactcredits - phone_number_count;
  const secondaryCredits =
    props.appuserData.credits.personal_emailcredits - personal_email_count;
  const businessCredits =
    props.appuserData.credits.business_emailcredits - work_email_count;

  return (
    <div className="flex gap-4 flex-col content-center p-4">
      <div className="flex flex-row gap-2 items-center">
        <div className="w-10 h-10 p-2 bg-secondary rounded-full justify-center items-center flex">
          <div class="justify-center items-center flex">
            {props.section === 'people' ? <IconPeople /> : <IconCompany />}
          </div>
        </div>
        <div>
          <div className="text-lg font-semibold">
            {props.section === 'people'
              ? LocaleStrings.enrichment.people.title
              : LocaleStrings.enrichment.company.title}
          </div>
          <div className="text-gray-600 text-xs">
            {props.section === 'people'
              ? LocaleStrings.enrichment.people.subTitle
              : LocaleStrings.enrichment.company.subTitle}
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        {fetching ? (
          <div
            className="flex justify-center"
            style={{height: 'calc(100vh / var(--zoom) - 200px)'}}>
            <div className="flex flex-col justify-center items-center mt-[10px]">
              <div>
                <img src={require('../icons/processing.gif')} width={150} />
              </div>
              <div className="text-lg font-semibold">
                {LocaleStrings.enrichment.calculatingCredits}
              </div>
              <div className="px-40 text-sm text-center text-gray-600">
                {LocaleStrings.enrichment.calculatingSubtitle}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="flex flex-col gap-4 px-52 justify-center"
            style={{height: 'calc(100vh / var(--zoom) - 200px)'}}>
            <div className="flex flex-col justify-center items-center">
              <div className="text-base text-center mt-4">
                {props.section === 'people'
                  ? `${renderPeopleContactTitle()} ${
                      LocaleStrings.enrichment.people.infoTitle
                    }`
                  : renderCompanyInfoTitle()}
              </div>
              <div className="text-sm text-gray-500">
                {LocaleStrings.formatString(
                  LocaleStrings.enrichment.people.infoSubtitle,
                  credits_deduct ?? 0,
                )}
              </div>
            </div>
            {fieldKeys.map(key => (
              <ContactOption
                key={key}
                dataKey={key}
                {...contactSettings[key]}
                onToggle={enabled => {
                  const numSelected = fieldKeys
                    .map(key => contactSettings[key].selection)
                    .filter(val => val);
                  if (
                    (numSelected.length >= 1 && enabled) ||
                    (numSelected.length > 1 && !enabled)
                  ) {
                    const settings = {...contactSettings};
                    settings[key].selection = enabled;
                    setContactSettings(settings);

                    setFetching(true);
                  }
                }}
              />
            ))}

            {contactCredits < 0 ? (
              <div className="text-xs text-center text-red-600">
                {`Insufficient Contact credits. An additional ${
                  contactCredits * -1
                } credits is required to proceed.`}
              </div>
            ) : null}
            {secondaryCredits < 0 ? (
              <div className="text-xs text-center text-red-600">
                {`Insufficient Personal Email credits. An additional ${
                  secondaryCredits * -1
                } credits is required to proceed.`}
              </div>
            ) : null}
            {businessCredits < 0 ? (
              <div className="text-xs text-center text-red-600">
                {`Insufficient Business Email credits. An additional ${
                  businessCredits * -1
                } credits is required to proceed.`}
              </div>
            ) : null}

            {businessCredits < 0 ||
            secondaryCredits < 0 ||
            contactCredits < 0 ? (
              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  style={{width: 120}}
                  onClick={() => {
                    props.onOpenUpgrade();
                  }}
                  className="btn-primary ml-4 cursor-pointer">
                  {LocaleStrings.upgrade_now}
                </button>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  const appuserData = state.appuserData;
  const onEnterprisePlan =
    appuserData.credits.role === 'client' &&
    (appuserData.credits.planid === ENTERPRISE_YEARLY ||
      appuserData.credits.planid === ENTERPRISE_MONTHLY);
  return {
    notEnterprise: !onEnterprisePlan,
    session: state.session,
    selectMappingData:
      state.csvSelectionPopupData.section === 'people'
        ? state.selectMapping
        : state.selectCompanyMapping,
    standardFieldSettings:
      state.csvSelectionPopupData.section === 'people'
        ? state.peopleStandardFieldSettings
        : state.companyStandardFieldSettings,
    toggleCSVSelectionPopup: state.csvSelectionPopupData,
    section: state.csvSelectionPopupData.section,
    csvUploadInfo: state.csvUploadInfo,
    csvEnrichInfo: state.csvEnrichInfo,
    appuserData: state.appuserData,
  };
};

export default connect(mapStateToProps, {fetchCSVInfo, toggleCSVAnalysis})(
  EnrichmentOptions,
);
