import {connect} from 'react-redux';
import {useState} from 'react';
import CreateCompanyListPopup from './createlistpopup';
import LocaleStrings from '../../../languages';
import {FILTER_TYPE_COMPANY, FILTER_TYPE_FAV_COMPANY} from '../actions';
import {useNavigate} from 'react-router-dom';
import toast, {Toaster} from 'react-hot-toast';
import CompanyCell from './cells/CompanyCell';
import BlockTechnology, {
  SECTION_COMPANY,
} from '../results/cells/components/technologyblock';
import TechnologyListPopup from './technologypopup';

function ListTable(props) {
  const {selectedCompanies, onSelectCompany, filteredCompany, filterType} =
    props;
  const [openCreateListPopup, setOpenCreateListPopup] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedCompanyForTech, setSelectedCompanyForTech] = useState();
  const navigate = useNavigate();
  return (
    <div className="relative pr-4 overflow-scroll-y">
      <table className="w-full table-fixed divide-y divide-gray-300 sm:px-6 lg:px-8">
        <thead>
          <tr className="bg-gray-50">
            {/* {filterType === FILTER_TYPE_FAV_COMPANY ? (
              <th
                scope="col"
                className="sticky top-0 relative w-12 bg-gray-50 z-50"
              />
            ) : null} */}
            <th
              scope="col"
              className="sticky top-0 w-1/5 py-3.5 pr-3 text-left text-xs font-normal text-gray-700 relative px-7 bg-gray-50 z-50">
              {LocaleStrings.search.companysearch.tableHeaders.companyname}
            </th>
            <th
              scope="col"
              className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
              {LocaleStrings.search.companysearch.tableHeaders.hqlocation}
            </th>
            <th
              scope="col"
              className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
              {LocaleStrings.search.companysearch.tableHeaders.industry}
            </th>
            <th
              scope="col"
              className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
              {LocaleStrings.search.companysearch.tableHeaders.employeecount}
            </th>
            {/* <th
              scope="col"
              className="sticky top-0 z-10 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50">
              {LocaleStrings.search.companysearch.tableHeaders.revenue}
            </th> */}
            <th
              scope="col"
              className="sticky top-0 px-3 py-3.5 text-left text-xs font-normal text-gray-700 bg-gray-50 z-50">
              {LocaleStrings.search.companysearch.tableHeaders.technology}
            </th>
            <th className="sticky top-0 bg-gray-50 z-50" />
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {filteredCompany &&
            filteredCompany.map(company => (
              <CompanyCell
                filterType={filterType}
                onShowTechs={company => {
                  setSelectedCompanyForTech(company);
                }}
                company={company}
                selected={selectedCompanies.includes(company)}
                onCheckUnCheck={checked => {
                  onSelectCompany(company, checked);
                }}
                onOpenCreateList={company => {
                  setOpenCreateListPopup(!openCreateListPopup);
                  setSelectedCompany(company);
                }}
                onUpgradePlan={() => navigate('/upgradeplan')}
                onBuyAddon={() => navigate('/creditaddons')}
                onCopySuccess={() => {
                  toast.success('Copied successfully');
                }}
                onRemoveFav={props.onRemoveFav}
              />
            ))}
          {props.showTechnologyBlock ? (
            <BlockTechnology
              colSpan={6}
              totalcount={props.totalcount}
              section={SECTION_COMPANY}
            />
          ) : null}
        </tbody>
      </table>
      <CreateCompanyListPopup
        open={openCreateListPopup}
        filterType={filterType}
        selectedCompany={selectedCompany}
        toggleOpen={open => {
          setOpenCreateListPopup(open);
        }}
      />
      {selectedCompanyForTech ? (
        <TechnologyListPopup
          open={true}
          company={selectedCompanyForTech}
          onClose={() => {
            setSelectedCompanyForTech(undefined);
          }}
        />
      ) : null}
      <Toaster />
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  const {session, user, savedFilters, technologySearch} = state;

  const {totalcount, showTechnologyBlock} = technologySearch;

  let filteredCompany =
    ownProps.filterType === FILTER_TYPE_COMPANY
      ? state.filteredCompany.data
      : state.filteredFavCompany.data;

  if (showTechnologyBlock) {
    filteredCompany = filteredCompany.slice(0, 5);
  }
  return {
    session,
    user,
    savedFilters,
    showTechnologyBlock,
    searchSelectedSubMenu: state.searchSelectedSubMenu,
    filteredCompany,
    totalcount: totalcount > 1 ? totalcount - 1 : 0, //For technology block
  };
}

export default connect(mapStateToProps, null)(ListTable);
