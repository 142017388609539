import {useEffect, useState} from 'react';
import LocaleStrings from '../../../../languages';
import IconPeople from '../icons/people';
import {connect} from 'react-redux';
import {fetchCSVInfo, toggleCSVSelectionPopup} from '../../actions';
import {
  companyContactsSettings,
  peopleContactsSettings,
} from '../../../../../common/constants';
import IconCompany from '../icons/company';

function Processing(props) {
  const [contactSettings] = useState(
    props.section === 'people'
      ? peopleContactsSettings
      : companyContactsSettings,
  );
  const [fetching, setFetching] = useState(true);

  const getMappingData = () => {
    const data = {...props.selectMappingData};
    const keys = Object.keys(data);
    const newMappedData = {};
    const allFields =
      props.section === 'people'
        ? {
            ...props.standardFieldSettings,
            ...peopleContactsSettings,
          }
        : {
            ...props.standardFieldSettings,
            ...companyContactsSettings,
          };
    keys.map(key => {
      if (data[key] === '') {
        newMappedData[key] = {
          title: allFields[key].title,
        };
      } else {
        newMappedData[key] = {title: data[key]};
      }
    });
    return newMappedData;
  };

  const closeModal = () => {
    props.toggleCSVSelectionPopup({
      section: props.section,
      open: false,
      file: null,
      originalFile: null,
    });
  };

  useEffect(() => {
    if (fetching) {
      const params = {
        action: 'post',
        type: props.section === 'people' ? 'contact' : 'company',
        standard_fields: {
          ...props.standardFieldSettings,
          ...contactSettings,
        },
        column_matched_settings: getMappingData(),
        filename: props.csvUploadInfo.filename,
        name: props.csvUploadInfo.name,
      };
      props.fetchCSVInfo(props.session, params, _ => {
        setFetching(false);
        setTimeout(() => {
          props.onClose();
        }, 500);
      });
    }
  }, [fetching]);

  return (
    <div className="flex gap-4 flex-col content-center p-4">
      <div className="flex flex-col gap-2">
        <div className="w-8 h-8 p-2 bg-secondary rounded-full mb-2">
          <div class="justify-center items-center flex">
            {props.section === 'people' ? <IconPeople /> : <IconCompany />}
          </div>
        </div>
        <div className="text-xl font-semibold">
          {props.section === 'people'
            ? LocaleStrings.enrichment.people.title
            : LocaleStrings.enrichment.company.title}
        </div>
        <div className="text-gray-600 text-sm">
          {props.section === 'people'
            ? LocaleStrings.enrichment.people.subTitle
            : LocaleStrings.enrichment.company.subTitle}
        </div>
      </div>
      <div className="flex flex-col">
        <div
          className="flex flex-col gap-4 px-2 overflow-y-auto scrollbar-hide justify-center items-center"
          style={{height: 'calc(100vh / var(--zoom) - 260px)'}}>
          <div className="flex flex-col justify-center items-center mt-[10px]">
            <div>
              <img src={require('../icons/processing.gif')} width={150} />
            </div>
            <div className="text-lg font-semibold">
              {LocaleStrings.enrichment.csvProcessing}
            </div>
            <div className="px-40 text-sm text-center text-gray-600">
              {LocaleStrings.enrichment.processingSubtitle}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  session: state.session,
  section: state.csvSelectionPopupData.section,
  selectMappingData:
    state.csvSelectionPopupData.section === 'people'
      ? state.selectMapping
      : state.selectCompanyMapping,
  standardFieldSettings:
    state.csvSelectionPopupData.section === 'people'
      ? state.peopleStandardFieldSettings
      : state.companyStandardFieldSettings,
  section: state.csvSelectionPopupData.section,
  csvUploadInfo: state.csvUploadInfo,
});

export default connect(mapStateToProps, {
  fetchCSVInfo,
  toggleCSVSelectionPopup,
})(Processing);
