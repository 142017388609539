import React, {useState} from 'react';
import {connect} from 'react-redux';
import {classNames} from '@commonFunction';
import IconFav from '../../../search/results/cells/icons/fav';
import SocialLinks from '../../../search/results/cells//components/sociallinks';
import IconFaved from '../../../search/results/cells/icons/faved';

import IconUpdate from '../../../search/results/cells/icons/update';
import {
  addCRMLead,
  addPersonTolist,
  FILTER_TYPE_REVEALED_PEOPLE,
  tooglePeopleFav,
  updateRevealedPeople,
  zapierHook,
} from '../../../search/actions';

import {labelNotAssigned} from '../../../mylist/constants';
import CompanyBlock from '../../../search/results/cells/components/companyblock';
import LabelDropdownBlock from '../../../mylist/components/labeldropdownblock';
import LocaleStrings from '../../../../languages';

import ContactDetails from '../../../search/results/cells/components/contactdetails';

function ContactCell(props) {
  const {
    person,
    selected,
    onCheckUnCheck,
    onOpenCreateList,
    onUpdateAvailableClick,
  } = props;
  const {
    contactname,
    jobtitle,
    company,
    contactdetails,
    linkedin,
    facebook,
    twitter,
    isfav,
    revealed,
    addedtolist,
    listid,
    new_contact_data_available,
    new_company_data_available,
    new_designation_data_available,
    new_personal_email_data_available,
    new_business_email_data_available,
    new_phone_number_data_available,
    contacttag,
  } = person;

  const [showList, setShowList] = useState(false);
  const [showLabelList, setShowLabelList] = useState(false);
  return (
    <tr
      key={contactname}
      className={classNames(
        'hover:bg-gray-50 hover:cursor-pointer',
        selected ? 'bg-gray-50' : undefined,
      )}>
      <td className="group relative px-7 py-2">
        {selected && (
          <div className="absolute inset-y-0 left-0 w-0.5 bg-primary" />
        )}
        <input
          type="checkbox"
          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-500 text-primary group-hover:cursor-pointer"
          value={contactname}
          checked={selected}
          onChange={e => onCheckUnCheck(e.target.checked)}
        />
      </td>
      <td
        className={classNames('group pr-3 relative  px-7 py-2')}
        onClick={() => {
          onCheckUnCheck(!selected);
        }}>
        <div className="relative flex">
          <div
            className={classNames(
              'whitespace-nowrap text-xs font-medium mb-2 w-fit truncate',
              selected ? 'text-primary' : 'text-gray-900',
            )}>
            {contactname}
          </div>
        </div>
        {!linkedin && !twitter && !facebook ? null : (
          <div className="flex gap-2">
            <SocialLinks
              linkedin={linkedin}
              twitter={twitter}
              facebook={facebook}
            />
            {new_company_data_available ||
            new_designation_data_available ||
            new_personal_email_data_available ||
            new_business_email_data_available ||
            new_phone_number_data_available ? (
              <div
                className="hover:cursor-pointer tooltip my-0.2"
                onClick={e => onUpdateAvailableClick(person)}>
                <IconUpdate />
                <div className="tooltiptext tooltip-right">
                  <div className="text-xs">
                    {LocaleStrings.revealedContacts.updateavailable}
                  </div>
                  {new_company_data_available ? (
                    <div className="text-xs">
                      - {LocaleStrings.revealedContacts.company}
                    </div>
                  ) : (
                    ''
                  )}
                  {new_designation_data_available ? (
                    <div className="text-xs">
                      - {LocaleStrings.revealedContacts.designation}
                    </div>
                  ) : (
                    ''
                  )}
                  {new_personal_email_data_available ? (
                    <div className="text-xs">
                      - {LocaleStrings.revealedContacts.personalemail}
                    </div>
                  ) : (
                    ''
                  )}
                  {new_business_email_data_available ? (
                    <div className="text-xs">
                      - {LocaleStrings.revealedContacts.businessemail}
                    </div>
                  ) : (
                    ''
                  )}
                  {new_phone_number_data_available ? (
                    <div className="text-xs">
                      - {LocaleStrings.revealedContacts.cell}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : null}
          </div>
        )}

        {/* <div
          className="absolute right-2 top-6 group-hover:opacity-100 opacity-0"
          onClick={() => {
            navigator.clipboard.writeText(contactname);
            props.onCopySuccess();
          }}>
          <IconCopy />
        </div> */}
      </td>
      <td className="group whitespace-wrap px-3 py-2 hover:cursor-pointer relative">
        <div className="relative flex">
          <div className="text-xs text-gray-500">{jobtitle}</div>
        </div>
        {/* <div
          className="absolute right-2 top-7 group-hover:opacity-100 opacity-0"
          onClick={() => {
            navigator.clipboard.writeText(jobtitle);
            props.onCopySuccess();
          }}>
          <IconCopy />
        </div> */}
      </td>

      {/* <td className="px-3 py-4 text-sm text-gray-500 justify-items-center">
        <div className="justify-start items-start gap-1 inline-flex relative">
          <div
            className="px-3 py-2 rounded-2xl justify-start items-center gap-1 flex hover:cursor-pointer hover:scale-105"
            style={{
              backgroundColor: contacttag
                ? `${contacttag['color']}20`
                : `${labelNotAssigned['color']}20`,
            }}
            onClick={() => {
              setShowLabelList(!showLabelList);
            }}>
            <div
              className="text-center text-xs font-medium font-['Inter'] leading-none"
              style={{
                color: contacttag
                  ? `${contacttag['color']}`
                  : `${labelNotAssigned['color']}`,
              }}>
              {contacttag ? contacttag.title : labelNotAssigned.title}
            </div>
            <div className="w-5 h-5 px-0.5 justify-center items-center flex">
              <ChevronDownIcon
                className="h-4 w-4"
                aria-hidden="true"
                style={{
                  color: contacttag
                    ? `${contacttag['color']}`
                    : `${labelNotAssigned['color']}`,
                }}
              />
            </div>
          </div>
          <div
            className={classNames(
              'absolute left-0 top-10 transition duration-300 ease-in z-50',
              showLabelList ? 'visible' : 'invisible',
            )}>
            <LabelsDropdown
              onLabelSelection={label => {
                setShowLabelList(false);
                props.onLabelSelection(label);
              }}
            />
          </div>
        </div>
      </td> */}
      <td className="px-3">
        <ContactDetails {...props} filterType={FILTER_TYPE_REVEALED_PEOPLE} />
        {/* <div className="flex gap-2 relative items-center flex">
          {contactdetails.filter(contact => contact.type === 'directdial')
            .length > 0 ? (
            <div className="group relative">
              <ContactBlock
                contacts={contactdetails.filter(
                  contact => contact.type === 'directdial',
                )}
                contactname={contactname}
                contacttype="phone"
                onUpgradePlan={() => props.onUpgradePlan()}
                onBuyAddon={() => props.onBuyAddon()}
                person={person}
                onClickFlag={contact => {
                  props.onClickFlag(contact);
                }}
                onRevealCompletion={updatedPerson => {
                  const contactIndex = props.filteredPeople.data.findIndex(
                    person => person.peopleid === updatedPerson.peopleid,
                  );
                  props.filteredPeople.data[contactIndex] = updatedPerson;
                  props.updateRevealedPeople(props.filteredPeople);
                }}
              />
            </div>
          ) : null}
          <div className="group relative items-center flex justify-center">
            <ContactBlock
              contacts={contactdetails.filter(
                contact =>
                  contact.type === 'personalemail' ||
                  contact.type === 'businessemail',
              )}
              contactname={contactname}
              onUpgradePlan={() => props.onUpgradePlan()}
              onBuyAddon={() => props.onBuyAddon()}
              contacttype="email"
              person={person}
              onClickFlag={contact => {
                props.onClickFlag(contact);
              }}
              onRevealCompletion={updatedPerson => {
                const contactIndex = props.filteredPeople.data.findIndex(
                  person => person.peopleid === updatedPerson.peopleid,
                );
                props.filteredPeople.data[contactIndex] = updatedPerson;
                props.updateRevealedPeople(props.filteredPeople);
              }}
            />
          </div>
        </div> */}
      </td>
      <td className="group px-3 py-2 hover:cursor-pointer relative">
        <CompanyBlock
          company={company}
          new_company_data_available={new_company_data_available}
        />
      </td>
      <td className="px-3 py-2 text-xs text-gray-500 justify-items-center">
        <div className="justify-start items-start gap-1 inline-flex relative">
          <LabelDropdownBlock
            tag={contacttag}
            labelNotAssigned={labelNotAssigned}
            onLabelSelection={label => {
              props.onLabelSelection(label);
            }}
          />
        </div>
      </td>
      <td className="whitespace-nowrap py-2 pl-3 pr-8">
        <div className="group flex gap-2 justify-end relative">
          <div
            onClick={() => {
              const newpeople = {...props.person, isfav: !isfav};
              const index = props.filteredPeople.data.findIndex(
                t => t.peopleid === props.person.peopleid,
              );
              props.filteredPeople.data[index] = newpeople;
              props.updateRevealedPeople(props.filteredPeople);

              const params = {
                action: isfav ? 'removefav' : 'addfav',
                peopleid: newpeople.peopleid,
              };
              props.tooglePeopleFav(props.session, params, res => {
                console.log('Fav => ', res);
              });
            }}>
            {isfav ? <IconFaved /> : <IconFav />}
          </div>
        </div>
      </td>
    </tr>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters} = state;
  return {
    session,
    user,
    savedFilters,
    availableLists: state.availableLists,
    searchSelectedSubMenu: state.searchSelectedSubMenu,
    filteredPeople: state.filteredRevealedContacts,
    availableLabels: state.availableLabels,
    crmDetails: state.crmDetails,
  };
}

export default connect(mapStateToProps, {
  updateRevealedPeople,
  tooglePeopleFav,
  addPersonTolist,
  zapierHook,
  addCRMLead,
})(ContactCell);
