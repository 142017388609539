import {useEffect, useState} from 'react';
import LocaleStrings from '../components/languages';
import {classNames} from '@commonFunction';
import {connect} from 'react-redux';
import {
  saveFilter,
  saveRecentSearch,
} from '../components/dashboard/search/actions';
import Chip from './Chip';
import {XIcon} from '@heroicons/react/outline';

function ListItem(props) {
  const {title, subtitle, showOptions, index} = props;
  return (
    <div
      className={classNames(
        'group ml-1 px-2 bg-white justify-between items-center gap-0.5 flex hover:bg-slate-50 hover:rounded-md',
        subtitle !== '' ? 'py-2' : showOptions ? 'py-1' : 'py-3',
        index === 0 ? 'mt-1' : '',
      )}
      onClick={() => {
        // if (showOptions) {
        props.onInclude({...props, action: 'include'});
        // }
      }}>
      <div className="justify-start items-center gap-1">
        <div className="text-[11px] text-left leading-none semiGray group-hover:text-primary">
          {title}
        </div>
        <div className="text-gray-400 text-[10px] text-left group-hover:text-primary">
          {subtitle}
        </div>
      </div>
      {showOptions ? (
        <div className="py-2 rounded-sm flex-col justify-end items-center inline-flex">
          <div className="justify-center items-center inline-flex gap-1 group-hover:opacity-100 opacity-0">
            <div
              className="text-[10px] text-primary"
              onClick={() => {
                props.onInclude({...props, action: 'include'});
              }}>
              {LocaleStrings.search.include}
            </div>
            <div className="text-[10px] text-gray-300">|</div>
            <div
              className="text-[10px] text-red-600"
              onClick={() => {
                props.onExclude({...props, action: 'exclude'});
              }}>
              {LocaleStrings.search.exclude}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

function TaggedSearch(props) {
  const {
    id,
    name,
    results,
    onSearch,
    placeholder,
    takesAnyValue,
    showOptions,
    type,
    subtype,
  } = props;
  const [searchText, setSearchText] = useState('');
  const [toggled, setToggled] = useState(false);
  let [options, setOptions] = useState([]);

  const [allOptions, setAllOptions] = useState([]);
  const [fieldRecentSearch, setFieldRecentSearch] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    if (type === 'location') {
      setSelectedValues(props.savedFilters[type][subtype]);
    } else {
      setSelectedValues(props.savedFilters[type]);
    }
  }, [props.savedFilters]);

  useEffect(() => {
    if (type === 'location') {
      setSelectedValues(props.savedFilters[type][subtype]);
    } else {
      setSelectedValues(props.savedFilters[type]);
    }
  }, [type, subtype]);

  useEffect(() => {
    if (results && results.length > 0) {
      setOptions(results);
    }
  }, [results]);

  useEffect(() => {
    let recentSearch = [];

    if (type === 'location') {
      recentSearch = props.recentSearches[type][subtype];
    } /*else if (type === 'industrycode') {
      recentSearches = props.recentSearches[type];
    }*/ else {
      recentSearch = props.recentSearches[type];
    }

    const uniqueArr = recentSearch.filter((obj, index, self) => {
      return index === self.findIndex(o => o.title === obj.title);
    });

    recentSearch = [...uniqueArr];

    setFieldRecentSearch(recentSearch);

    // const searches = type === 'jobtitle' ? [] : recentSearch;

    const searches = []; //recentSearch;

    if (recentSearch.length > 0) {
      //Filter out same option for listing
      options = options.filter(item => !recentSearch.includes(item.title));
    }

    let allOptions = [];

    if (searches.length > 0 && options.length > 0) {
      allOptions = options.concat(searches);
    } else if (searches.length > 0 && options.length === 0) {
      allOptions = searches;
    } else {
      allOptions = options;
    }

    const uniqueOptions = allOptions.filter((obj, index, self) => {
      return index === self.findIndex(o => o.title === obj.title);
    });

    setAllOptions(uniqueOptions);
  }, [props.recentSearches, options]);

  const handleSearch = text => {
    setSearchText(text);
    if (takesAnyValue) {
      if (options.length > 0) {
        const newOption = options[0];
        newOption.title = `${text}`;
        options.splice(0, 1, newOption);
        setOptions(options);
      } else {
        setOptions([
          {
            title: `${text}`,
            subtitle: '',
            value: `${text}`,
          },
        ]);
      }
    } else if (props.onSearch) {
      props.onSearch(text);
    }
  };

  const updateFilterReducer = vals => {
    let filters = props.savedFilters;
    let searchObject = filters;
    if (type === 'location') {
      searchObject[type][subtype] = vals;
    } else {
      searchObject[type] = vals;
    }
    filters = {...filters, ...searchObject};
    props.saveFilter(props.session, filters, () => {});
  };

  const updateSearchReducer = val => {
    let searches = props.recentSearches;

    let searchObject = searches;
    let arrField = [];
    if (type === 'location') {
      arrField = searchObject[type][subtype];
    } else {
      arrField = searchObject[type];
    }
    const found = arrField.filter(obj => obj.title === val.title).length > 0;

    if (!found) {
      if (arrField.length === 3) {
        arrField.pop();
        arrField.splice(0, 0, val);
      } else {
        arrField.splice(0, 0, val);
      }

      if (type === 'location') {
        searchObject[type][subtype] = arrField;
      } else {
        searchObject[type] = arrField;
      }

      searches = {...searches, ...searchObject};
      props.saveRecentSearch(props.session, searches, () => {});
    }
  };

  const applySearch = item => {
    if (item.title !== '') {
      const found =
        selectedValues.filter(val => val.title === item.title).length > 0;
      if (!found) {
        const vals = selectedValues;
        vals.push(item);
        updateFilterReducer(vals);
      }
      updateSearchReducer(item);
    }

    setSearchText('');
    setToggled(false);
    props.onToggled(false);
  };

  useEffect(() => {
    if (allOptions.length > 0 && !toggled && searchText !== '') {
      setToggled(true);
    }
  }, [allOptions.length, searchText]);

  return (
    <div className="py-2 h-auto bg-[#F7F8FF]">
      <div className="w-full h-30 px-3 bg-white rounded-lg shadow border border-gray-300 flex-col justify-center items-start gap-2 inline-flex">
        {selectedValues && selectedValues.length > 0 ? (
          <div className="justify-start pt-2 items-center gap-2 inline-flex">
            <div className="justify-start items-center gap-1.5 flex flex-wrap">
              {selectedValues.map((val, index) => (
                <Chip
                  key={`${index}`}
                  {...val}
                  onRemove={item => {
                    const filtered = selectedValues.filter(
                      theVal => theVal.title !== item.title,
                    );
                    updateFilterReducer(filtered);
                  }}
                />
              ))}
            </div>
          </div>
        ) : null}
        <div className="justify-start items-center w-full">
          <div className="text-gray-500 text-xs font-normal leading-normal relative">
            <input
              type="search"
              autocomplete="off"
              name={name}
              id={id}
              className="cust-transparent-input-field bg-transparent w-full no-clear"
              style={{fontSize: 12}}
              placeholder={placeholder}
              value={searchText}
              onClick={() => {
                if (searchText === '') {
                  setToggled(!toggled);
                  props.onToggled(!toggled);
                }
              }}
              onChange={e => {
                handleSearch(e.target.value);
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  if (allOptions.length > 0) {
                    const firstOption = allOptions[0];
                    applySearch({...firstOption, action: 'include'});
                  }
                }
              }}
            />
            {searchText !== '' && (
              <div
                className="absolute top-2 -right-1 z-99 content-center p-1 bg-primaryHover cursor-pointer"
                style={{borderRadius: 40, height: 20, width: 20}}
                onClick={() => {
                  setSearchText('');
                  setToggled(false);
                  props.onToggled(!toggled);
                }}>
                <XIcon className="h-3 w-3 text-primary" aria-hidden="true" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={classNames(
          toggled
            ? 'transition ease-in duration-300 h-auto min-h-min max-h-60'
            : 'max-h-0 opacity-0',
          'overflow-hidden hover:overflow-y-auto w-full bg-white rounded-lg shadow border border-gray-300 flex-col justify-center items-start gap-2 scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent scrollbar-thumb-rounded-full',
          toggled && allOptions.length > 0 ? 'opacity-100' : 'opacity-0',
        )}>
        {allOptions &&
          allOptions.map((result, index) => (
            <ListItem
              name={name}
              index={index}
              {...result}
              key={`key-${index}`}
              onExclude={item => {
                applySearch(item);
              }}
              onInclude={item => {
                applySearch(item);
              }}
              showOptions={showOptions}
            />
          ))}
      </div>
      {fieldRecentSearch.length > 0 ? (
        <div className="pt-2">
          <div className="text-[10px] uppercase text-primary">
            {LocaleStrings.search.recent_search}
          </div>
          <div className="flex flex-wrap gap-2 mt-1">
            {fieldRecentSearch.map(searchItem => (
              <div className="flex items-center gap-2">
                <div
                  className="px-2 py-1 bg-secondary text-[10px] flex rounded-lg text-gray-800"
                  onClick={() => {
                    if (!showOptions) {
                      applySearch(searchItem);
                    } else {
                      applySearch({...searchItem, action: 'include'});
                    }
                  }}>
                  {searchItem.title}
                </div>
                {showOptions ? (
                  <div className="flex gap-1">
                    <div
                      className="text-[9px] text-green-600 cursor-pointer"
                      onClick={() => {
                        applySearch({...searchItem, action: 'include'});
                      }}>
                      {LocaleStrings.search.include}
                    </div>
                    <div className="text-xs text-gray-300">|</div>
                    <div
                      className="text-[9px] text-red-600 cursor-pointer"
                      onClick={() => {
                        applySearch({...searchItem, action: 'exclude'});
                      }}>
                      {LocaleStrings.search.exclude}
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  var {session, user, savedFilters, recentSearches} = state;
  return {
    session,
    user,
    savedFilters,
    recentSearches,
  };
}

export default connect(mapStateToProps, {saveFilter, saveRecentSearch})(
  TaggedSearch,
);
